/* eslint-disable no-case-declarations */
import {
  SHOW_FORUM_EDITOR,
  HIDE_FORUM_EDITOR,
  SAVE_TOPIC_DETAIL,
  SAVE_CATEGORY_DETAIL,
  SAVE_HEADER_DETAIL,
  SAVE_RECENTLY_VISITED_THREADS,
  SAVE_TOPICS,
  SAVE_TOPIC_COUNT_SUMMARY,
  SAVE_USER_DETAILS,
  SAVE_SEARCH_RESULTS,
  SAVE_CATEGORY_TOPICS,
  SAVE_CATEGORY_PINNED_TOPICS,
  SAVE_CATEGORY_MEMBERS,
  SAVE_CATEGORIES,
  SAVE_PARENT_PATH
} from './action';

const initState = {
  show: false,
  initialValues: null,
  topic: null,
  category: null,
  header: null, 
  threads: [],
  recentThreads: [],
  summary: null,
  userDetails: [],
  searchResults: [],
  categoryTopics: [],
  categoryPinnedTopics: [],
  categoryMembers: [],
  categories: [],
  parentPath: null
};

export default (state = initState, { type, data, ...rest }) => {
  switch (type) {
    case SHOW_FORUM_EDITOR:
      return {
        ...state,
        show: true,
        initialValues: data
      };
    case HIDE_FORUM_EDITOR:
      return {
        ...state,
        show: false,
        initialValues: null
      };
    case SAVE_TOPIC_DETAIL:
      return {
        ...state,
        topic: data
      };
    case SAVE_CATEGORY_DETAIL:
      return {
        ...state,
        category: data
      };
    case SAVE_HEADER_DETAIL:
      return {
        ...state,
        header: data
      };
    case SAVE_RECENTLY_VISITED_THREADS:
      return {
        ...state,
        recentThreads: data
      };
    case SAVE_TOPICS:
      return {
        ...state,
        threads: data
      };
    case SAVE_TOPIC_COUNT_SUMMARY:
      return {
        ...state,
        summary: data
      };
    case SAVE_USER_DETAILS:
      return {
        ...state,
        userDetails: [...state.userDetails, data]
      };
    case SAVE_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: data
      };
    case SAVE_CATEGORY_TOPICS:
      const list = state?.categoryTopics;
      const index = list?.findIndex(item => item?.category?.toString() === data?.category?.toString());
      if (index > -1) {
        list[index] = data;
      } else {
        list.push(data);
      }
      return {
        ...state,
        categoryTopics: [...list]
      };
    case SAVE_CATEGORY_PINNED_TOPICS:
      const list1 = state?.categoryPinnedTopics;
      const index1 = list1?.findIndex(item => item?.category?.toString() === data?.category?.toString());
      if (index1 > -1) {
        list1[index1] = data;
      } else {
        list1.push(data);
      }
      return {
        ...state,
        categoryPinnedTopics: [...list1]
      };
    case SAVE_CATEGORY_MEMBERS:
      const list2 = state?.categoryMembers;
      const index2 = list2?.findIndex(item => item?.category?.toString() === data?.category?.toString());
      if (index2 > -1) {
        list2[index2] = data;
      } else {
        list2.push(data);
      }
      return {
        ...state,
        categoryMembers: [...list2]
      };
    case SAVE_CATEGORIES:
      return {
        ...state,
        categories: [...data]
      }
    case SAVE_PARENT_PATH:
      return {
        ...state,
        parentPath: data
      }
    default:
      return state;
  }
};
