const TAG = 'SCREENS/ME/YOUR_INVEST/LIST_TERMS';
export const GET_TERM_DEPOSITS = `${TAG}/GET_TERM_DEPOSITS`;
export const CREATE_TERM_DEPOSIT = `${TAG}/CREATE_TERM_DEPOSIT`;
export const STOP_TERM_DEPOSIT = `${TAG}/STOP_TERM_DEPOSIT`;
export const PAY = `${TAG}/PAY`;
export const GET_USER_BONUS_INTEREST_RATE = `${TAG}/GET_USER_BONUS_INTEREST_RATE`;
export const APPLY_REFERRAL = `${TAG}/APPLY_REFERRAL`;
export const APPLY_SHARE = `${TAG}/APPLY_SHARE`;
export const REFERRAL_INVITATION = `${TAG}/REFERRAL_INVITATION`;
export const TERM_DEPOSIT_SUMMARY = `${TAG}/TERM_DEPOSIT_SUMMARY`;
export const VOID_TERM_DEPOSIT_SUMMARY = `${TAG}/VOID_TERM_DEPOSIT_SUMMARY`;
export const REFRESH_SECONDARY_MARKET = `${TAG}/REFRESH_SECONDARY_MARKET`;
export const GET_HOLDING_BALANCE_DETAIL = `${TAG}/GET_HOLDING_BALANCE_DETAIL`;
export const SAVE_PRV_INTEREST = `${TAG}/SAVE_PRV_INTEREST`;
