import { makeRequest, SERVICES } from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';
import { camelizeKeys } from 'src/utils/object';

export const SAVE_REFERRAL_REWARD_ACTION = 'SAVE_REFERRAL_REWARD_ACTION';
export const GET_REFERRAL_USERS_ACTION = 'GET_REFERRAL_USERS_ACTION';
export const GET_REFERRAL_HISTORY_ACTION = 'GET_REFERRAL_HISTORY_ACTION';
export const GET_REFERRAL_PROMOTION_ACTION = 'GET_REFERRAL_PROMOTION_ACTION';
export const SEND_INVITATION_ACTION = 'SEND_INVITATION_ACTION';
export const REFERRAL_INVITATION_REMIND = 'REFERRAL_INVITATION_REMIND';
export const GET_REFERRAL_PROMOTION_REWARDS = 'GET_REFERRAL_PROMOTION_REWARDS';
export const GET_REFERRAL_PROMOTION_HISTORY_ACTION = 'GET_REFERRAL_PROMOTION_HISTORY_ACTION';
export const GET_REFERRAL_SUMMARY = 'GET_REFERRAL_SUMMARY';
export const MCT_REFERRAL_REWARDS = 'MCT_REFERRAL_REWARDS';
export const MCT_REFERRAL_REWARD_SUMMARY_BY_USER = 'MCT_REFERRAL_REWARD_SUMMARY_BY_USER';
export const CRYPTO_REFERRAL_REWARDS = 'CRYPTO_REFERRAL_REWARDS';
export const CRYPTO_REFERRAL_REWARD_SUMMARY_BY_USER = 'CRYPTO_REFERRAL_REWARD_SUMMARY_BY_USER';
export const CRYPTO_REFERRAL_REWARD_SUMMARY_TOTAL_BY_USER = 'CRYPTO_REFERRAL_REWARD_SUMMARY_TOTAL_BY_USER';

export const saveReferralRewardAction = (data) => ({
  type: SAVE_REFERRAL_REWARD_ACTION,
  data,
});

export const getReferralUsers = (page, pageSize) => makeRequest({
  type: GET_REFERRAL_USERS_ACTION,
  url: `${API_URL.REFERRAL.REFERRAL_USERS}?page=${page}&page_size=${pageSize}`,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res),
});

export const getReferralHistory = (page, pageSize) => makeRequest({
  type: GET_REFERRAL_HISTORY_ACTION,
  url: `${API_URL.REFERRAL.REFERRAL_HISTORY}?page=${page}&page_size=${pageSize}`,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res),
});

export const getReferralPromotion = () => makeRequest({
  type: GET_REFERRAL_PROMOTION_ACTION,
  url: API_URL.REFERRAL.REFERRAL_PROMOTION,
  service: SERVICES.ROOT,
});

export const sendInvitations = (data, captcha) => dispatch => {
  const req = makeRequest({
    url: `${API_URL.SAVING.REFERRAL_INVITATION}`,
    type: SEND_INVITATION_ACTION,
    service: SERVICES.SAVING,
    method: 'POST',
    data,
    captcha,
  }, dispatch);
  return req().then(res => {
    return res;
  }).catch(e => {
    return e.data;
  });
};

export const referralInvitationRemind = (data) => makeRequest({
  url: API_URL.REFERRAL.REFERRAL_INVITATION_REMIND,
  type: REFERRAL_INVITATION_REMIND,
  service: SERVICES.ROOT,
  method: 'POST',
  data,
});

export const reloadReferralUsers = value => dispatch => {
  dispatch({ type: 'RELOAD_REFERRAL_USERS', value });
};

export const getReferralPromotionRewards = (params) => makeRequest({
  type: GET_REFERRAL_PROMOTION_REWARDS,
  url: `${API_URL.REFERRAL.PROMOTION_REFERRAL_REWARDS}${params}`,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res),
});

export const getReferralPromotionHistory = (page, pageSize) => makeRequest({
  type: GET_REFERRAL_PROMOTION_HISTORY_ACTION,
  url: `${API_URL.REFERRAL.GET_REFERRAL_PROMOTION_HISTORY_ACTION}?page=${page}&page_size=${pageSize}`,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res),
});

export const getReferralSummary = () => makeRequest({
  type: GET_REFERRAL_SUMMARY,
  url: `${API_URL.REFERRAL.REFERRAL_SUMMARY}`,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res),
});

export const getMCTRefferalRewards = (page, pageSize) => makeRequest({
  type: MCT_REFERRAL_REWARDS,
  url: `${API_URL.REFERRAL.MCT_REFERRAL_REWARDS}?page=${page}&page_size=${pageSize}`,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res),
});

export const getMCTRefferalRewardSummaryByUser = (params) => makeRequest({
  type: MCT_REFERRAL_REWARD_SUMMARY_BY_USER,
  url: `${API_URL.REFERRAL.MCT_REFERRAL_REWARD_SUMMARY_BY_USER}`,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res),
  params,
});

export const claimAirdropRewards = (id, recaptcha) => makeRequest({
  type: 'CLAIM_AIRDROP_REWARDS',
  url: `${API_URL.REFERRAL.CLAIM_AIRDROP_REWARDS.replace('{id}', id)}`,
  service: SERVICES.ROOT,
  method: 'POST',
  captcha: recaptcha,
});

export const getCryptoRefferalRewards = (page, pageSize) => makeRequest({
  type: CRYPTO_REFERRAL_REWARDS,
  url: `${API_URL.REFERRAL.CRYPTO_REFERRAL_REWARDS}?page=${page}&page_size=${pageSize}`,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res),
});

export const getCryptoRefferalRewardSummaryByUser = (params) => makeRequest({
  type: CRYPTO_REFERRAL_REWARD_SUMMARY_BY_USER,
  url: `${API_URL.REFERRAL.CRYPTO_REFERRAL_REWARD_SUMMARY_BY_USER}`,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res),
  params,
});

export const getCryptoRefferalRewardSummaryTotalByUser = (params) => makeRequest({
  type: CRYPTO_REFERRAL_REWARD_SUMMARY_TOTAL_BY_USER,
  url: `${API_URL.REFERRAL.CRYPTO_REFERRAL_REWARD_SUMMARY_TOTAL_BY_USER}`,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res),
  params,
});