export const BADGE_ITEM_KEYS = {
  SIGN_UP: 'getting_started_sign_up',
  KYC: 'getting_started_kyc_us',
  TWO_FA: 'getting_started_on_2fa',
  FIRST_DEPOSIT: 'getting_started_first_deposit',
  LINK_BANK: 'getting_started_link_bank',
  FIRST_FRIEND: 'warming_up_first_friend',
  FIRST_INVEST: 'warming_up_invest_first_time',
  INVEST_MONTHLY_QUARTER: 'warming_up_invest_monthly_quarter',
  INVEST_MONTHLY_YEAR: 'warming_up_invest_monthly_year',
  FIRST_BORROW: 'warming_up_borrow_first_time',
  BORROW_MONTHLY_QUARTER: 'warming_up_borrow_monthly_quarter',
  BORROW_MONTHLY_YEAR: 'warming_up_borrow_monthly_year',
  SELL_SECONDARY_MARKET: 'warming_up_sell_secondary_market',
  BUY_SECONDARY_MARKET: 'warming_up_buy_secondary_market',
  REVIEW_TRUSTPILOT: 'warming_up_review_trustpilot',
  REVIEW_APP: 'warming_up_review_app',
  REFER_10_FRIENDS: 'referring_friend_10_referees',
  REFER_50_FRIENDS: 'referring_friend_50_referees',
  REFER_100_FRIENDS: 'referring_friend_100_referees',
  REFERRING_INVEST_100K: 'referring_invest_100k',
  REFERRING_INVEST_200K: 'referring_invest_200k',
  REFERRING_INVEST_500K: 'referring_invest_500k',
  ARTICLE_FIRST: 'referring_article_first',
  ARTICLE_MONTHLY_QUARTER: 'referring_article_monthly_quarter',
  ARTICLE_MONTHLY_YEAR: 'referring_article_monthly_year',
  CREATE_VIDEO: 'referring_create_a_video',
  WINNING_INVEST: 'winning_invest',
  WINNING_BORROW: 'winning_borrow',
  LOYALTY_6_MONTHS: 'winning_loyalty_6months',
  LOYALTY_12_MONTHS: 'winning_loyalty_12months',
  LOYALTY_24_MONTHS: 'winning_loyalty_24months',
  CHAMPION: 'winning_champion',
  MEMBERSHIP_GOLD: 'membership_gold',
  MEMBERSHIP_PLATINUM: 'membership_platinum',
  MEMBERSHIP_DIAMOND: 'membership_diamond',
  DEPOSIT_3_MONTH: 'activity_deposit_3_months_consecutive',
  PASS_MINI_QUIZ: 'activity_take_mini_course',
  SECONDARY_MISS: 'activity_secondary_miss',
  INSTALL_APP: 'activity_install_app',
  REFEREE_PASS_KYC: 'referring_referee_passes_kyc',
  DEBIT_CARD_FIRST_CREATED: 'debit_card_created_first_time',
  DEBIT_CARD_FIRST_ADD_FUND: 'debit_card_added_fund_first_time',
  DEBIT_CARD_FIRST_TRANSACTION: 'debit_card_spending_transaction_first_time',
  MCTTOKEN_FIRST_HOLDER: 'mcttoken_first_holder',
  MCTTOKEN_FIRST_SWAP: 'mcttoken_first_swap',
};

export const BADGE_GROUP_KEYS = {
  GETTING_STARTED: 'getting_started',
  WARMING_UP: 'warming_up',
  REFERRING: 'referring',
  // MEMBERSHIP: 'membership',
  WINNING: 'winning',
  SPENDING: 'spending',
};

export const BADGE_LEVELS = {
  INVEST: [
    BADGE_ITEM_KEYS.FIRST_INVEST,
    BADGE_ITEM_KEYS.INVEST_MONTHLY_QUARTER,
    BADGE_ITEM_KEYS.INVEST_MONTHLY_YEAR,
  ],
  BORROW: [
    BADGE_ITEM_KEYS.FIRST_BORROW,
    BADGE_ITEM_KEYS.BORROW_MONTHLY_QUARTER,
    BADGE_ITEM_KEYS.BORROW_MONTHLY_YEAR,
  ],
  ARTICLE: [
    BADGE_ITEM_KEYS.ARTICLE_FIRST,
    BADGE_ITEM_KEYS.ARTICLE_MONTHLY_QUARTER,
    BADGE_ITEM_KEYS.ARTICLE_MONTHLY_YEAR,
  ],
  REFER: [
    BADGE_ITEM_KEYS.REFER_10_FRIENDS,
    BADGE_ITEM_KEYS.REFER_50_FRIENDS,
    BADGE_ITEM_KEYS.REFER_100_FRIENDS,
  ],
  REFER_INVEST: [
    BADGE_ITEM_KEYS.REFERRING_INVEST_100K,
    BADGE_ITEM_KEYS.REFERRING_INVEST_200K,
    BADGE_ITEM_KEYS.REFERRING_INVEST_500K,
  ],
  LOYALTY: [
    BADGE_ITEM_KEYS.LOYALTY_6_MONTHS,
    BADGE_ITEM_KEYS.LOYALTY_12_MONTHS,
    BADGE_ITEM_KEYS.LOYALTY_24_MONTHS,
  ],
};

export const BADGE_GROUPS = {
  GETTING_STARTED: [
    BADGE_ITEM_KEYS.SIGN_UP,
    BADGE_ITEM_KEYS.KYC,
    BADGE_ITEM_KEYS.TWO_FA,
    BADGE_ITEM_KEYS.FIRST_DEPOSIT,
    BADGE_ITEM_KEYS.LINK_BANK,
  ],
  WARMING_UP: [
    BADGE_ITEM_KEYS.REVIEW_TRUSTPILOT,
    BADGE_ITEM_KEYS.REVIEW_APP,
    BADGE_ITEM_KEYS.FIRST_FRIEND,
    BADGE_LEVELS.INVEST,
    BADGE_LEVELS.BORROW,
    BADGE_ITEM_KEYS.SELL_SECONDARY_MARKET,
    BADGE_ITEM_KEYS.BUY_SECONDARY_MARKET,
    BADGE_ITEM_KEYS.INSTALL_APP,
    BADGE_ITEM_KEYS.PASS_MINI_QUIZ,
    BADGE_ITEM_KEYS.DEPOSIT_3_MONTH,
    BADGE_ITEM_KEYS.SECONDARY_MISS,
    BADGE_ITEM_KEYS.MCTTOKEN_FIRST_HOLDER,
  ],
  REFERRING: [
    BADGE_LEVELS.ARTICLE,
    BADGE_LEVELS.REFER,
    BADGE_LEVELS.REFER_INVEST,
    BADGE_ITEM_KEYS.CREATE_VIDEO,
    BADGE_ITEM_KEYS.REFEREE_PASS_KYC,
  ],
  // MEMBERSHIP: [
  //   BADGE_ITEM_KEYS.MEMBERSHIP_GOLD,
  //   BADGE_ITEM_KEYS.MEMBERSHIP_PLATINUM,
  //   BADGE_ITEM_KEYS.MEMBERSHIP_DIAMOND,
  // ],
  SPENDING: [
    BADGE_ITEM_KEYS.DEBIT_CARD_FIRST_CREATED,
    BADGE_ITEM_KEYS.DEBIT_CARD_FIRST_ADD_FUND,
    BADGE_ITEM_KEYS.DEBIT_CARD_FIRST_TRANSACTION,
    BADGE_ITEM_KEYS.MCTTOKEN_FIRST_SWAP,
  ],
  WINNING: [
    BADGE_ITEM_KEYS.WINNING_BORROW,
    BADGE_LEVELS.LOYALTY,
    BADGE_ITEM_KEYS.CHAMPION,
  ]
};

export const BADGE_STATUS = {
  PROCESSING: 0,
  DONE: 1,
  REWARD: 2,
  REJECT: 3,
};

export const REWARD_TYPE = {
  USD: 1,
  POINT: 2,
  PERCENT: 3
};

export const BADGE_QUANTITY_TYPE = {
  QUANTITY: 1,
  CENT: 2,
};

export const BADGE_ACTION_GROUP_KEY = {
  INVEST: 'invest',
  BORROW: 'borrow',
  REFERRAL: 'referral',
  MEMBERSHIP: 'membership',
  AFFILIATE: 'affiliate',
};

export const BADGE_REWARD_AMOUNT = {
  [BADGE_ITEM_KEYS.MEMBERSHIP_GOLD]: 5000,
  [BADGE_ITEM_KEYS.MEMBERSHIP_PLATINUM]: 5000,
  [BADGE_ITEM_KEYS.MEMBERSHIP_DIAMOND]: 5000,
};

export const BADGE_TOTAL_PROGRESS = {
  [BADGE_ITEM_KEYS.MEMBERSHIP_GOLD]: 25000,
  [BADGE_ITEM_KEYS.MEMBERSHIP_PLATINUM]: 50000,
  [BADGE_ITEM_KEYS.MEMBERSHIP_DIAMOND]: 100000,
};

const REFERRAL_BADGES = [
  BADGE_ITEM_KEYS.FIRST_DEPOSIT,
  BADGE_ITEM_KEYS.FIRST_FRIEND,
  BADGE_ITEM_KEYS.ARTICLE_FIRST,
  BADGE_ITEM_KEYS.REFER_10_FRIENDS,
  BADGE_ITEM_KEYS.ARTICLE_MONTHLY_QUARTER,
  BADGE_ITEM_KEYS.REFER_50_FRIENDS,
  BADGE_ITEM_KEYS.REFERRING_INVEST_100K,
  BADGE_ITEM_KEYS.ARTICLE_MONTHLY_YEAR,
  BADGE_ITEM_KEYS.REFER_100_FRIENDS,
  BADGE_ITEM_KEYS.REFERRING_INVEST_200K,
  BADGE_ITEM_KEYS.REFERRING_INVEST_500K,
];

export const BADGE_ACTION_GROUPS = {
  invest: [
    [
      BADGE_ITEM_KEYS.KYC,
      ...BADGE_LEVELS.INVEST,
      ...BADGE_LEVELS.LOYALTY,
      BADGE_ITEM_KEYS.SELL_SECONDARY_MARKET,
      BADGE_ITEM_KEYS.BUY_SECONDARY_MARKET,
    ],
    [
      BADGE_ITEM_KEYS.TWO_FA,
      BADGE_ITEM_KEYS.REVIEW_TRUSTPILOT,
    ],
    REFERRAL_BADGES,
  ],
  borrow: [
    [...BADGE_LEVELS.BORROW],
    [
      BADGE_ITEM_KEYS.TWO_FA,
      BADGE_ITEM_KEYS.FIRST_FRIEND,
      BADGE_ITEM_KEYS.WINNING_BORROW,
      ...BADGE_LEVELS.LOYALTY,
      BADGE_ITEM_KEYS.SELL_SECONDARY_MARKET,
      BADGE_ITEM_KEYS.BUY_SECONDARY_MARKET,
    ],
    REFERRAL_BADGES,
  ],
  referral: [
    [
      ...BADGE_LEVELS.ARTICLE,
      ...BADGE_LEVELS.REFER_INVEST,
    ],
    [
      BADGE_ITEM_KEYS.FIRST_FRIEND,
      ...BADGE_LEVELS.REFER,
      ...BADGE_LEVELS.LOYALTY,
      BADGE_ITEM_KEYS.SELL_SECONDARY_MARKET,
    ],
    [
      BADGE_ITEM_KEYS.FIRST_DEPOSIT,
      ...BADGE_LEVELS.INVEST,
    ],
  ],
  affiliate: [
    [
      ...BADGE_LEVELS.ARTICLE,
      ...BADGE_LEVELS.REFER_INVEST,
    ],
    [
      BADGE_ITEM_KEYS.FIRST_FRIEND,
      ...BADGE_LEVELS.REFER,
      ...BADGE_LEVELS.LOYALTY,
      BADGE_ITEM_KEYS.SELL_SECONDARY_MARKET,
    ],
    [
      BADGE_ITEM_KEYS.FIRST_DEPOSIT,
      ...BADGE_LEVELS.INVEST,
    ],
  ]
};
