import {AUTHORIZED_INPUT2FA_DIALOG, HIDE_INPUT2FA_DIALOG, SHOW_INPUT2FA_DIALOG} from './action';

const initialState = {
  show: false,
  callback: f=> f,
  message: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_INPUT2FA_DIALOG: {
      return {
        show: true
      };
    }
    case HIDE_INPUT2FA_DIALOG: {
      return {
        show: false
      };
    }
    case AUTHORIZED_INPUT2FA_DIALOG: {
      return {
        show: true,
        ...payload
      };
    }
    default: return state;
  }
};
