import { GET_SIGN_UP_PAGE_INFO } from './action';

const initState = {
  affiliateInfo: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case `${GET_SIGN_UP_PAGE_INFO}_SUCCESS`:
      console.log("🚀 ~ file: reducer.js ~ line 8 ~ action", action)
      return {
        ...state,
        affiliateInfo: action?.data,
      };
    default:
      return state;
  }
};
