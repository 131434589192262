import smartlookClient from 'smartlook-client';
import makeRequest, {SERVICES} from 'src/redux/action';
import UserModel from 'src/models/user';
import {URL, API_URL} from 'src/resources/constants/url';
import { camelizeKeys } from 'src/utils/object';
import configureStore from 'src/redux/store';
import { SHOW_BADGE_ACHIEVE_MODAL } from 'src/screens/badges/redux/type';
import { BADGE_ITEM_KEYS, BADGE_REWARD_AMOUNT } from 'src/screens/badges/constant';
import { checkInternalUser } from 'src/components/exchangeMethods/exchange/action';
import { AMOUNT_RATE } from 'src/components/exchangeMethods/constant';

import { sendEmailConfirm, sendEmailVerifyCode, updateProfile as updateProfileApi, confirmCollateralWithdraw } from './api';
import {
  CHECK_TRIAL_USER_AVAIABLE,
  GET_PROFILE_INTEREST,
  GET_SYSTEM_INTEREST,
  LOGOUT,
  SECONDARY_MARKET_NOTIFICATION_SETTINGS,
  WITHDRAW_EMAIL_SETTING,
  SET_REQUIRE_OTP,
  SET_RETRY_OTP,
  SET_TOKEN,
  UPDATE_PROFILE_INFO,
  UPDATE_REFERRAL_CODE,
  GET_USER_ATTRIBUTES,
  GET_USER_LOYALTY_BENEFITS, GET_MCT_LOYALTY_PROGRAM_INFO, GET_BALANCE, ADD_NEW_PLAID_BANK, UPDATE_PLAID_BANK,
} from './type';

export const userLogout = (isRedirect = true, redirectTo = URL.HOME) => {
  return {
    type: LOGOUT,
    data: {isRedirect, redirectTo}
  };
};

export const setToken = token => ({
  type: SET_TOKEN,
  data: token
});

export const setRequireOTP = (isRequireOTP, loginCallBack) => ({
  type: SET_REQUIRE_OTP,
  data: {isRequireOTP, loginCallBack}
});

export const retryInputOTP = (isRetry = false) => ({
  type: SET_RETRY_OTP,
  data: isRetry
});

export const updateProfileAction = (data) => dispatch => dispatch({ type: `${UPDATE_PROFILE_INFO}_SUCCESS`, data });

export const logSmartlookClient = (dispatch, id, email, name) => {
  checkInternalUser()(dispatch).then(res => {
    if (res.internal_user) {
      smartlookClient.disable();
    } else {
      smartlookClient.identify(id, {
        name: name,
        email: email,
        // other custom properties
      });
    }
  });
};

export const getProfileAction = () => dispatch => {
  const req = makeRequest({
    type: UPDATE_PROFILE_INFO,
    url: API_URL.ME.PROFILE,
  }, dispatch);
  const store = configureStore();
  const previousLevel = store.getState().auth.profile.Membership;
  dispatch(getProfileInterestAction());

  return req().then(res => {
    const user = UserModel.userProfile(res);
    window.gtag('set', {'user_id': user.id});
    if (user.Membership > previousLevel) {
      let type = BADGE_ITEM_KEYS.MEMBERSHIP_GOLD;
      switch (user.Membership) {
        case 2:
          type = BADGE_ITEM_KEYS.MEMBERSHIP_PLATINUM;
          break;
        case 3:
          type = BADGE_ITEM_KEYS.MEMBERSHIP_DIAMOND;
          break;
      }
      dispatch({
        type: SHOW_BADGE_ACHIEVE_MODAL,
        badge: {
          type,
          rewardAmount: BADGE_REWARD_AMOUNT[type],
        }
      });
    }

    logSmartlookClient(dispatch, user.id, user.email, `${user.firstName || ''} ${user.lastName || ''}`);
    return user;
  });
};

export const getProfileInterestAction = () => dispatch => {
  const req = makeRequest({
    type: GET_PROFILE_INTEREST,
    url: API_URL.ME.PROFILE_INTEREST,
  }, dispatch);

  return req().then(res => res);
};

export const sendEmailVerifyCodeAction = (data) => (dispatch) => new Promise((resolve, reject) => {
  sendEmailVerifyCode(data).then(payload => {
    if (!payload) resolve(null);

    //debugger;
    //dispatch({ type: 'UPDATE_PROFILE_INFO', payload });
    resolve(payload);
  }).catch(err=>reject(err));
});

export const sendEmailConfirmAction = (data) => (dispatch) => new Promise((resolve, reject) => {
  sendEmailConfirm(data).then(payload => {
    if (!payload) resolve(null);

    resolve(payload);
  }).catch(err=>reject(err));
});

export const confirmCollateralWithdrawAction = (data) => () => new Promise((resolve, reject) => {
  confirmCollateralWithdraw(data).then(payload => {
    if (!payload) resolve(null);
    resolve(payload);
  }).catch(err => reject(err));
});

export const updateProfile = data => dispatch =>
  new Promise((resolve, reject) => {
    // dispatch({ type: UPDATE_PROFILE_INFO, payload: { phone_number }});
    updateProfileApi(data)
      .then(payload => {
        if (!payload) return;
        dispatch({ type: UPDATE_PROFILE_INFO, payload });
        // sendToGetPhoneCode().then(r => r).catch(err=>err);
        resolve(true);
      })
      .catch(err => reject(err));
  });

export const checkUserTrialAvaiable = () => makeRequest({
  service: SERVICES.SAVING,
  type: CHECK_TRIAL_USER_AVAIABLE,
  url: API_URL.USER.CHECK_TRIAL_USER_AVAIABLE,
  normalize: res => ({ trialAvailable: !!res?.trial_available, trialLeft: res?.trial_left || 0, joinedTrial: !!res?.joined_trial })
});

export const getSystemInterest = () => makeRequest({
  url: API_URL.SYSTEM.SYSTEM_INTEREST,
  type: GET_SYSTEM_INTEREST,
});

export const secondaryMarketNotificationSettingsActions = (method = 'GET', data = {}) => makeRequest({
  service: SERVICES.SAVING,
  url: API_URL.SYSTEM.SECONDARY_MARKET_NOTIFICATION_SETTINGS,
  method: method,
  data: data,
  type: SECONDARY_MARKET_NOTIFICATION_SETTINGS,
  normalize: res => camelizeKeys(res)
});

export const updateSecondaryMarketNotificationSettingsActions = (method = 'PUT', data = {}) => makeRequest({
  service: SERVICES.SAVING,
  url: `${API_URL.SYSTEM.SECONDARY_MARKET_NOTIFICATION_SETTINGS}${data.id}/`,
  method: method,
  data: data,
  type: SECONDARY_MARKET_NOTIFICATION_SETTINGS,
  normalize: res => camelizeKeys(res)
});

export const updateEmailWithdrawSetting = (enable, otp) => makeRequest({
  url: `${API_URL.SYSTEM.WITHDRAW_EMAIL_SETTING}?enable=${enable}`,
  method: 'POST',
  otp,
  type: WITHDRAW_EMAIL_SETTING,
  more: { enable },
  normalize: res => camelizeKeys(res)
});

export const updateReferralCode = referralCode => makeRequest({
  type: UPDATE_REFERRAL_CODE,
  url: API_URL.REFERRAL.UPDATE_REFERRAL_CODE,
  method: 'POST',
  data: {
    referralCode,
  },
  more: { referralCode },
});

// export const getFirstDepositInfo = () => makeRequest({
//   url: API_URL.EXCHANGE.FIRST_DEPOSIT_GA_EVENT,
//   type: 'GET_FIRST_DEPOSIT_INFO',
//   service: SERVICES.EXCHANGE,
// });
//
// export const saveFirstDepositInfo = () => makeRequest({
//   url: API_URL.EXCHANGE.FIRST_DEPOSIT_GA_EVENT,
//   type: 'GET_FIRST_DEPOSIT_INFO',
//   service: SERVICES.EXCHANGE,
//   method: 'POST',
// });

export const getUserAttributes = () => makeRequest({
  type: GET_USER_ATTRIBUTES,
  url: `${API_URL.USER.GET_USER_ATTRIBUTES}`,
});

export const getUserLoyaltyBenefits = () => makeRequest({
  type: GET_USER_LOYALTY_BENEFITS,
  url: `${API_URL.CONSTANT_TOKEN.GET_LOYALTY_BENEFIT}`,
});

export const getMCTLoyaltyProgramInfo = () => makeRequest({
  type: GET_MCT_LOYALTY_PROGRAM_INFO,
  url: `${API_URL.CONSTANT_TOKEN.GET_MCT_LOYALTY_PROGRAM_INFO}`,
});

export const getBalance = () => dispatch => {
  const req = makeRequest({
    type: GET_BALANCE,
    url: `${API_URL.USER.GET_BALANCE}`,
  }, dispatch);
  return req().then(res => res/AMOUNT_RATE);
};

export const setIsAddNewPlaidBank = token => ({
  type: ADD_NEW_PLAID_BANK,
  data: token
});

export const setIsUpdatePlaidBank = token => ({
  type: UPDATE_PLAID_BANK,
  data: token
});
