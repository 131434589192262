import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import currentUser from 'src/utils/authentication';
import LocalStore from 'src/services/localStore';
import { APP } from 'src/resources/constants/app';
import { getLinkedBanksDeposit } from 'src/screens/me/pages/BankInfo/action';
import Loading from 'src/components/loading';
import ga, { events } from 'src/services/ga';
import VerificationHeader from 'src/components/verificationHeader';
import BrowserDetect from 'src/services/browser-detect';
import { URL } from 'src/resources/constants/url';
import { checkIsUSIp } from 'src/utils/common';
import { clickBanner, getViewingBanner, hideTopBanner, showTopBanner, skipBanner } from 'src/components/topBanner/action';

import LockTop from './images/lock_top.svg';
import LockBottom from './images/lock_bottom.svg';
import GiftBox from './images/gift_box.gif';
import Trophy from './images/trophy.gif';
import Dollar from './images/dollar.gif';
import Star from './images/star.gif';
import Piggy from './images/piggy.gif';
import Token from './images/img_token.gif';

import styles from './styles.scss';
import BannerAllTerm from './bannerAllTerm';
import BannerSuspendMembership from './bannerSuspendMembership';
import BannerAirdropMCTReady from './bannerAirdropMCTReady';
import BannerReferralLottery from './bannerReferralLottery';
import { useHistory } from 'react-router';

export const TOP_BANNER_HEIGHT = 66;
export const TOP_BANNER_TYPE = {
  VERIFY: 'VERIFY',
  SUGGEST: 'SUGGEST',
  PROMOTION: 'PROMOTION',
};

export const BLACK_LIST_URL = [
  URL.ACCOUNT_HISTORY, URL.USER_SIGN_IN, URL.USER_SIGN_UP, URL.LIVE_CHAT,
  URL.INVEST_CONFIRMATION, URL.WITHDRAW_CRYPTO_DETAIL, URL.FORUM_CHAT,
  URL.CRYPTO_LEND_TO_EARN, URL.DEPOSIT_FIAT_PLAID_CONFIRM
];

const HIDE_BANNER_SUSPEND_MEMBERSHIP = 'hide_banner_suspend_membership';
const HIDE_BANNER_MCT_AIRDROP_READY = 'hide_banner_mct_airdrop_ready';
const HIDE_BANNER_REFERRAL = 'hide_banner_referral';
const HIDE_BANNER_ALL_TERM = 'hide_banner_all_term';

const TopBanner = props => {
  const {
    getViewingBanner, getLinkedBanksDeposit, skipBanner, clickBanner,
    showTopBanner, hideTopBanner, location
  } = props;

  const history = useHistory();

  const verifiedLevel = useSelector(state => state.auth?.profile?.verifiedLevel);
  const email = useSelector(state => state.auth?.profile?.email);
  const isAuth = useSelector(state => !!state.auth?.token);
  const isUSIp = useSelector(state => checkIsUSIp(state));
  const topBannerType = useSelector(state => state.topBanner.type);
  const depositOrderInfo = useSelector(state => state.deposit?.orderInfo);

  const [viewData, setViewData] = useState(null);
  const [isDisplay, setIsDisplay] = useState(true);
  const [isBannerAllTerm, setIsBannerAllTerm] = useState(false);
  const [isBannerSuspendMembership, setIsBannerSuspendMembership] = useState(false);
  const [isBannerAirdropMCTReady, setIsBannerAirdropMCTReady] = useState(false);
  const [isBannerReferral, setIsBannerReferral] = useState(false);

  useEffect(() => {
    checkShowBanner(location);
    const unListen = history.listen((location) => {
      checkShowBanner(location);
    });
    return () => {
      unListen();
    };
  }, []);

  useEffect(() => {
    checkShowBanner(location);
  }, [location, verifiedLevel]);

  useEffect(() => {
    const isShow = !BLACK_LIST_URL.some(url => location.pathname === url);
    if (isShow) {
      updateBanner();
    } else {
      hideTopBanner();
    }
    setIsDisplay(isShow);
  }, [location, verifiedLevel, isBannerAllTerm, isBannerSuspendMembership, isBannerAirdropMCTReady, isBannerReferral]);

  const checkShowBanner = (location) => {
    setIsBannerAllTerm((location?.pathname?.toLowerCase() !== URL.QANDA?.toLowerCase()) && !LocalStore.get(HIDE_BANNER_ALL_TERM));
    setIsBannerSuspendMembership(location?.pathname === URL.MEMBERSHIP && !LocalStore.get(HIDE_BANNER_SUSPEND_MEMBERSHIP));
    setIsBannerAirdropMCTReady((location?.pathname === URL.DEPOSIT_FIAT || location?.pathname === URL.INVEST || location?.pathname === URL.FLEX_CRYPTO
      || location?.pathname === URL.LENDING_LOTTERY || location?.pathname === URL.MCT_STAKING || location?.pathname === URL.BUY_MCT
      || location?.pathname === URL.DEPOSIT_CRYPTO || location?.pathname === URL.HOME) && !LocalStore.get(HIDE_BANNER_MCT_AIRDROP_READY));
    setIsBannerReferral((location?.pathname === URL.ACCOUNTS || location?.pathname === URL.ACCOUNTS_INVESTMENT) && !LocalStore.get(HIDE_BANNER_REFERRAL));
  };

  useEffect(() => {
    if (location.pathname.includes(URL.DEPOSIT_FIAT) || location.pathname.includes(URL.INVEST)) {
      setIsDisplay(!depositOrderInfo);
    } else {
      setIsDisplay(!BLACK_LIST_URL.some(url => location.pathname === url));
    }
  }, [depositOrderInfo]);

  useEffect(() => {
    if (isBannerAllTerm || isBannerSuspendMembership || isBannerAirdropMCTReady || isBannerReferral) return;
    if (topBannerType === TOP_BANNER_TYPE.SUGGEST) {
      getViewingBanner().then(res => {
        if (res) {
          setViewData(res);
        } else {
          hideTopBanner();
        }
      });
    }
  }, [topBannerType]);

  const updateBanner = () => {
    if (isBannerAllTerm || isBannerSuspendMembership || isBannerAirdropMCTReady || isBannerReferral) {
      return showTopBanner(TOP_BANNER_TYPE.PROMOTION);
    }
    if (!isAuth) {
      return hideTopBanner();
    }
    if (typeof(verifiedLevel) !== 'number') return;
    if (!currentUser.isVerified(verifiedLevel)) {
      return showTopBanner(TOP_BANNER_TYPE.VERIFY);
    }
    if (currentUser.isPendingVerify(verifiedLevel) || currentUser.isRejected(verifiedLevel)) {
      return showTopBanner(TOP_BANNER_TYPE.SUGGEST);
    }
    if (isUSIp) {
      getLinkedBanksDeposit().then(res => {
        showTopBanner(res.length > 0 ? TOP_BANNER_TYPE.SUGGEST : TOP_BANNER_TYPE.VERIFY);
      });
    } else {
      showTopBanner();
    }
  };

  const getIcon = icon => {
    switch (icon) {
      case 'Lock':
        return (
          <div className={styles.animatedLock}>
            <img alt="" src={LockTop} />
            <img alt="" src={LockBottom} />
          </div>
        );
      case 'Dollar':
        return <img alt="" src={Dollar} />;
      case 'Gift':
        return <img alt="" src={GiftBox} />;
      case 'Trophy':
        return <img alt="" src={Trophy} />;
      case 'Star':
        return <img alt="" src={Star} />;
      case 'Piggy':
        return <img alt="" src={Piggy} />;
      case 'Token':
        return <img alt="" src={Token} />;
      default:
        return null;
    }
  };

  const getGaAction = () => {
    let action = '';
    switch (viewData?.type) {
      case 0:
        action = events.action.upperBannerClickFirstDeposit;
        break;
      case 1:
        action = events.action.upperBannerClick2FA;
        break;
      case 2:
        action = events.action.upperBannerClickFirstInvest;
        break;
      case 3:
        action = events.action.upperBannerClickOnReinvest;
        break;
      case 4:
        action = events.action.upperBannerClickReinvest;
        break;
      case 5:
        action = events.action.upperBannerClickFirstFriend;
        break;
      case 6:
        action = events.action.upperBannerClickMembershipPromote;
        break;
      case 7:
        action = events.action.upperBannerClickMembershipDemote;
        break;
      case 8:
        action = events.action.upperBannerClickBigWithdraw;
        break;
      case 9:
        action = events.action.upperBannerClickWithdrawAll;
        break;
      case 10:
        action = events.action.upperBannerNoInvestDepositRecently;
        break;
      case 11:
        action = events.action.upperBannerClickOn2ndMarketNotify;
        break;
      case 12:
        action = events.action.upperBannerClickUpgradeMembership;
        break;
      case 13:
        action = events.action.upperBannerClickBadge;
        break;
      case 14:
        action = events.action.upperBannerClickReferral;
        break;
      case 19:
        action = events.action.upperBannerClickReferralPromotion;
        break;
    }
    return action;
  };

  const handleClickLink = (bannerData) => {
    clickBanner(bannerData?.id);
    const action = getGaAction();
    ga(events.category.upperBanner, action, email);
    if (bannerData?.link.includes('https://')) {
      window.open(bannerData?.link, '_blank');
    }
  };

  const handleClose = () => {
    viewData && skipBanner(viewData.id);
    const action = getGaAction();
    ga(events.category.upperBanner, `${action} - Close`, email);
    LocalStore.save(APP.HIDE_TOP_BANNER, true);
    hideTopBanner();
  };

  const renderBannerSuggest = () => (
    <div className={styles.headerContainer}>
      {viewData
        ? (
          <>
            <div className={styles.content}>
              <div className={styles.label}>
                {getIcon(viewData.icon)}
                <div dangerouslySetInnerHTML={{ __html: viewData.content }} />
              </div>
              {viewData.link.includes('https://')
                ? (
                  <a href={viewData.link} target="_blank">
                    {BrowserDetect.isMobile ? 'View' : <>{viewData.label} <i className="far fa-long-arrow-right" /></>}
                  </a>
                ) : (
                  <Link className={styles.link} onClick={() => handleClickLink(viewData)} to={viewData.link}>
                    {BrowserDetect.isMobile ? 'Enable' : <>{viewData.label} <i className="far fa-long-arrow-right" /></>}
                  </Link>
                )}
            </div>
            <button className={styles.closeButton} type="button" onClick={handleClose}>{!BrowserDetect.isMobile && 'Close'} <i className="fal fa-times" /></button>
          </>
        ) : <Loading />}
    </div>
  );

  if (isBannerAllTerm) return <BannerAllTerm onClose={handleClose} />;

  return null;

  if (!isDisplay) return null;

  if (isBannerReferral) return <BannerReferralLottery onClose={() => {
    LocalStore.save(HIDE_BANNER_REFERRAL, true);
    checkShowBanner(location);
  }} />;
  if (isBannerAirdropMCTReady) return <BannerAirdropMCTReady onClose={() => {
    LocalStore.save(HIDE_BANNER_MCT_AIRDROP_READY, true);
    checkShowBanner(location);
  }} />;
  if (isBannerSuspendMembership) return <BannerSuspendMembership onClose={() => {
    LocalStore.save(HIDE_BANNER_SUSPEND_MEMBERSHIP, true);
    checkShowBanner(location);
  }} />;

  if (!isAuth) return null;

  return topBannerType === TOP_BANNER_TYPE.SUGGEST
    ? renderBannerSuggest()
    : topBannerType === TOP_BANNER_TYPE.VERIFY && <VerificationHeader />;
};

const mapDispatch = {
  getViewingBanner,
  clickBanner,
  skipBanner,
  showTopBanner,
  hideTopBanner,
  getLinkedBanksDeposit,
};

export default compose(
  connect(null, mapDispatch),
  withRouter,
)(TopBanner);
