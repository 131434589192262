import axios from 'axios';

import { makeRequest, SERVICES } from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';
import { USER } from 'src/resources/constants/user';
import { camelizeKeys } from 'src/utils/object';
import { SHOW_TOP_BANNER } from 'src/components/topBanner/type';
import UserModel from '../../models/user';
import authentication from '../../utils/authentication';
import { getProfileAction } from '../auth/redux/action';
import { CHECK_REFERRAL_CODE, REGISTER } from './type';

export const GET_SIGN_UP_PAGE_INFO = 'GET_SIGN_UP_PAGE_INFO';

export const register = (user, captcha) => dispatch => {
  const makeRegister = makeRequest(
    {
      type: REGISTER,
      url: API_URL.USER.USER_SIGN_UP,
      method: 'POST',
      data: user,
      captcha
    },
    dispatch,
  );

  return makeRegister().then(
    res => {
      const userAuthData = UserModel.userAuth(res);
      authentication.setAccessToken(userAuthData.token);
      dispatch({ type: SHOW_TOP_BANNER });
      return getProfileAction()(dispatch).then(profile => {
        const user = {
          name: `${profile.firstName || ''} ${profile.lastName || ''}`,
          email: profile.email
        };
        authentication.setCurrentUser(user);
        return USER.REGISTER_SUCCESS;
      });
    }).catch(err => {
    throw err;
  });
};

export const checkReferralCode = code => dispatch => {
  const req = makeRequest({
    type: CHECK_REFERRAL_CODE,
    url: `${API_URL.USER.CHECK_REFERRAL_CODE}?referralCode=${code}`,
  }, dispatch);
  return req().then(res => camelizeKeys(res));
};

export const checkEmailValid = async (email) => {
  try {
    const res = await axios
      .get(`https://email-checker.p.rapidapi.com/verify/v1?email=${email}`,
        {
          headers: {
            'x-rapidapi-host': 'email-checker.p.rapidapi.com',
            'x-rapidapi-key': APP_ENV.EMAIL_VERIFICATION_KEY,
          }
        });
    if(res?.data?.status === 'invalid') {
      return false;
    }
    return true;
  } catch (e) {
    console.error(e);
    return true;
  }
};

export const getAffiliateCustomInfo = (refCode) => makeRequest({
  url: `${API_URL.SAVING.GET_AFFILIATE_CUSTOM_INFO}?ref=${refCode}`,
  type: GET_SIGN_UP_PAGE_INFO,
  service: SERVICES.ROOT,
});