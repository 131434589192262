import React from 'react';
import { Switch, Route } from 'react-router-dom';
import loadable from '@loadable/component';

const ScreenContainer = loadable(() => import('src/components/screenContainer'));
const NotFoundPage = loadable(() => import('src/screens/notFound'));
const RouteWrapper = loadable(() => import('./routeWraper'));
const PrivateRoute = loadable(() => import('./privateRoute'));

export default ({ routes = [], staticContext }) => {
  return (
    <Switch>
      {routes && routes.map((route, index) => {
        const Component = route.auth ? PrivateRoute : RouteWrapper;
        return (
          <Component
            key={`${route.path}-${index}`}
            exact={route.exact}
            path={route.path}
            component={route.component}
            componentProps={route.componentProps}
            {...route}
          />
        );
      })}
      <Route render={() => {
        return (
          <ScreenContainer><NotFoundPage staticContext={staticContext} /></ScreenContainer>
        );
      }}
      />
    </Switch>
  );
};
