import { makeRequest, SERVICES } from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';
import UserModel from 'src/models/user';
import { camelizeKeys } from 'src/utils/object';
import {
  GET_BUY_AMOUNT,
  GET_CURRENCY_LIST,
  GET_USER_BANK,
  CHECK_INTERNAL_USER,
  CHECK_VALID_WITHDRAW,
  GET_CRYPTO_ADDRESS,
} from './type';

export const getCurrencyList = (params) =>
  makeRequest({
    service: SERVICES.EXCHANGE,
    type: GET_CURRENCY_LIST,
    url: `${API_URL.LOCAL.GET_CURRENCY_LIST}`,
    params: params,
    normalize: res => camelizeKeys(res)
  });

export const getBuyAmount = ({ country, fiatCurrency, fiatAmount }) =>
  makeRequest({
    service: SERVICES.EXCHANGE,
    type: GET_BUY_AMOUNT,
    url: `${API_URL.LOCAL.GET_BUY_AMOUNT}`,
    params: { country, fiat_currency: fiatCurrency, fiat_amount: fiatAmount },
  });

export const getSellAmount = ({ country, fiatCurrency, amount, payment_type = 'email' }) =>
  makeRequest({
    service: SERVICES.EXCHANGE,
    type: GET_BUY_AMOUNT,
    url: `${API_URL.LOCAL.GET_SELL_AMOUNT}`,
    params: { fiat_currency: fiatCurrency, amount, country, payment_type },
    normalize: res => ({ fiatAmount: res?.fiat_amount, fee: res?.fee, limit: res?.limit }),
  });

export const getUserBank = () => dispatch => {
  const req = makeRequest(
    {
      type: GET_USER_BANK,
      url: API_URL.USER.USER_BANKS,
    },
    dispatch,
  );
  return req().then(res => res.map(r => UserModel.userBank(r)));
};


export const checkInternalUser = () =>
  makeRequest({
    type: CHECK_INTERNAL_USER,
    method: 'GET',
    service: SERVICES.EXCHANGE,
    url: `${API_URL.EXCHANGE.CHECK_INTERNAL_USER}`,
  });


export const checkValidWithdraw = (amount, isWithdrawFiat = false, plaidId = 0) => makeRequest({
  type: CHECK_VALID_WITHDRAW,
  method: 'POST',
  url: `${API_URL.LOCAL.CHECK_VALID_WITHDRAW}`,
  data: { Amount: amount, IsWithdrawFiat: isWithdrawFiat, PlaidID: plaidId }
});

export const getCryptoAddress = (params) => makeRequest({
  service: SERVICES.EXCHANGE,
  type: GET_CRYPTO_ADDRESS,
  url: `${API_URL.EXCHANGE.GET_CRYPTO_ADDRESS}`,
  params: params,
});

export const getPublicDepositMethod = (country) => makeRequest({
  service: SERVICES.EXCHANGE,
  type: 'GET_PUBLIC_DEPOSIT_METHOD',
  url: `${API_URL.EXCHANGE.PUBLIC_BUY_METHODS}/?country=${country}`,
  normalize: res => camelizeKeys(res),
});