import { USER } from 'src/resources/constants/user';
import { VERIFIED_LEVEL } from 'src/resources/constants/userVerification';
import LocalStore from 'src/services/localStore';

const currentUser = {
  isLogin() {
    if (__CLIENT__) {
      const token = currentUser.getToken();
      return !!token;
    }
    return false;
  },
  getToken() {
    if (__CLIENT__) {
      const tokenCookie = (document.cookie.split(';').find(e => /transfertoken=/.test(e)) || '').split('=')[1];
      return tokenCookie || LocalStore.get(USER.ACCESS_TOKEN) || null;
    }
    return null;
  },

  setAccessToken(token) {
    if (__CLIENT__) {
      LocalStore.save(USER.ACCESS_TOKEN, token);
    }
  },

  setCurrentUser(user) {
    if (__CLIENT__) {
      LocalStore.save(USER.CURRENT_PROFILE, JSON.stringify(user));
    }
  },

  removeAccessToken() {
    if (__CLIENT__) {
      LocalStore.remove(USER.ACCESS_TOKEN);
      LocalStore.remove(USER.REFRESH_TOKEN);
      LocalStore.remove(USER.CURRENT_PROFILE);
    }
  },

  isVerified(verifiedLevel) {
    return verifiedLevel === VERIFIED_LEVEL.UserVerifiedLevelPrimetrust || verifiedLevel === VERIFIED_LEVEL.UserVerifiedLevelApprovedByConstant;
  },

  isPendingVerify(verifiedLevel) {
    return verifiedLevel === VERIFIED_LEVEL.UserVerifiedLevelSubmitted
      || verifiedLevel === VERIFIED_LEVEL.UserVerifiedLevelVerifying;
  },

  isRejected(verifiedLevel) {
    return verifiedLevel === VERIFIED_LEVEL.UserVerifiedLevelRejected
      || verifiedLevel === VERIFIED_LEVEL.UserVerifiedLevelPrimetrustRejected;
  },
};
export default currentUser;
