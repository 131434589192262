import { makeRequest } from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';
import UserModel from 'src/models/user';
import { camelizeKeys } from 'src/utils/object';
import { SUBMIT_BANK, GET_LINKED_BANKS, CREATE_LINK_BANK, UPDATE_DEFAULT_LINKED_BANK, REMOVE_LINKED_BANK, GET_BANK, SEND_LINK_ERROR } from './type';

export const getLinkedBanks = () => dispatch => {
  const req = makeRequest({
    type: GET_LINKED_BANKS,
    url: `${API_URL.USER.USER_LINKED_BANKS}`,
  }, dispatch);
  return req().then(res => camelizeKeys(res));
};

export const getOldLinkedBanks = () => dispatch => {
  const req = makeRequest({
    type: 'GET_OLD_LINKED_BANKS',
    url: `${API_URL.USER.USER_LINKED_BANKS}old-banks`,
  }, dispatch);
  return req().then(res => camelizeKeys(res));
};

export const getLinkedBanksDeposit = () => makeRequest({
  type: GET_LINKED_BANKS,
  url: `${API_URL.USER.USER_LINKED_BANKS}banks`,
  normalize: res => camelizeKeys(res),
});

export const getLinkedBanksAll = () => makeRequest({
  type: GET_LINKED_BANKS,
  url: `${API_URL.USER.USER_LINKED_BANKS}all-banks`,
  normalize: res => camelizeKeys(res),
});

export const createLinkBank = data => dispatch => {
  const req = makeRequest({
    type: CREATE_LINK_BANK,
    url: `${API_URL.USER.USER_LINKED_BANKS}`,
    method: 'POST',
    data,
  }, dispatch);
  return req().then(res => camelizeKeys(res));
};

export const updateLinkedBank = (id, data) => dispatch => {
  const req = makeRequest({
    type: UPDATE_DEFAULT_LINKED_BANK,
    url: `${API_URL.USER.USER_LINKED_BANKS}`,
    method: 'PUT',
    data: { ID: id, ...data },
  }, dispatch);
  return req().then(res => camelizeKeys(res));
};

export const removeLinkedBank = (id, otp) => dispatch => {
  const req = makeRequest({
    type: REMOVE_LINKED_BANK,
    url: `${API_URL.USER.USER_LINKED_BANKS}?ID=${id}`,
    method: 'DELETE',
    otp,
  }, dispatch);
  return req().then(res => camelizeKeys(res));
};

export const submitBank = (data, otp) => dispatch => {
  const req = makeRequest({
    type: SUBMIT_BANK,
    method: 'POST',
    url: `${API_URL.USER.USER_BANK}`,
    data,
    otp
  }, dispatch);
  return req().then(res => res);
};

export const getPublicToken = bankId => makeRequest({
  url: `${API_URL.USER.PLAID_PUBLIC_TOKEN.replace('{id}', bankId)}`,
  normalize: res => camelizeKeys(res),
});

export const getLinkToken = bankId => makeRequest({
  url: `${API_URL.USER.PLAID_LINK_TOKEN}?ID=${bankId}`,
  normalize: res => camelizeKeys(res),
});

export const getLinkTokenOAuth = params => makeRequest({
  url: `${API_URL.USER.PLAID_LINK_TOKEN_OAUTH}`,
  normalize: res => camelizeKeys(res),
  params,
});

export const deleteBank = (data, otp) => dispatch => {
  const req = makeRequest({
    type: SUBMIT_BANK,
    method: 'DELETE',
    url: `${API_URL.USER.USER_BANK}`,
    data,
    otp
  }, dispatch);
  return req().then(res => res);
};

export const getBanks = () => dispatch => {
  const req = makeRequest({
    type: GET_BANK,
    url: API_URL.USER.USER_BANKS,
  }, dispatch);
  return req().then(res => res.map(r => UserModel.userBank(r)));
};

export const sendLinkBankError = data => dispatch => {
  const req = makeRequest({
    type: SEND_LINK_ERROR,
    url: API_URL.USER.PLAID_LINK_ERROR,
    method: 'POST',
    data,
  }, dispatch);
  return req().then(res => camelizeKeys(res));
};