export const events = {
  category: {
    signUp: 'Sign up',
    invest: 'Invest',
    borrow: 'Borrow',
    deposit: 'Deposit',
    trial: 'Trial',
    referral: 'Referral',
    referralClick: 'Refer Click',
    profile: 'Profile',
    cryptoShort: 'Crypto Short',
    cryptoFuture: 'Crypto Future',
    upperBanner: 'Upper Banner Click',
    menuAbout: 'Menu About',
    menuInvest: 'Menu Invest',
    menuBorrow: 'Menu Borrow',
    menuSpending: 'Menu Spending',
    menuCryptoShort: 'Menu Crypto Short',
    menuCryptoFuture: 'Menu Crypto Future',
    menuShortBasic: 'Menu Short Basic',
    menuCryptoSwap: 'Menu Crypto Swap',
    menuPro: 'Menu Pro',
    giftCard: 'Gift card',
    diamond_upgrade: 'Diamond Upgrade Click',
    affiliate: 'Affiliate',
    downloadApp: 'Download App',
    lendingLottery: 'Lending Lottery',
    badgeReview: 'Badge Review',
    tracking: 'Tracking',
    nftShare: 'Share NFT',
    activityMenu: 'Activity Menu',
    errorApi: 'ErrorApi',
    errorApiNoPopup: 'ErrorApiNoPopup',
    menuCommunity: 'Menu Community',
    menuMembership: 'Menu Membership',
    airdropPrizewheel: 'Airdrop Prizewheel',
    redeemMCT: 'redeemMCT',
    submitKyc: 'submitKYC',
    trackingSubmitKYC: 'trackingSubmitKYC',
    trackingKYCStep: 'trackingKYCStep',
   
  },
  action: {
    signUp: 'sign_up',
    signUpInvest: 'invest',
    signUpBorrow: 'borrow',
    signUpFacebook: 'sign_up_facebook',
    signUpFacebookUpdateEmail: 'sign_up_facebook_update_email',
    signUpTwitter: 'sign_up_twitter',
    signUpSocialFacebook: 'sign_up_social_facebook',
    signUpSocialGoogle: 'sign_up_social_google',

    invest: 'initiate_invest',
    proInvest: 'pro_invest',
    borrow: 'initiate_borrow',
    proBorrow: 'pro_borrow',
    trial: 'join_trial',
    giftCardClickRetailer: 'gift_card_click_retailer',

    deposit: 'initiate_deposit',
    depositCryptoLend: 'initiate_crypto_lend',
    depositFiat: 'initiate_deposit_fiat',
    depositCrypto: 'initiate_deposit_crypto',
    depositFirst: 'first_deposit',

    kycUpdateProfileInfo: 'kyc_update_profile_info',
    kycUpdateProfileInfoAddress: 'kyc_update_profile_info_address',
    kycUpdateProfileInfoBasic: 'kyc_update_profile_info_basic',
    kycAddBank: 'kyc_add_bank',
    kycAddLinkedBank: 'kyc_add_linked_bank',

    referralInviteTemplate: 'invite_email_template',
    referralInvite: 'invite_email',
    referralClickMiniBanner: 'Click Mini Banner',
    referralClickMenuAccount: 'Click Menu Account',
    referralClickMenuAbout: 'Click Menu About',
    referralClickFooterDesktop: 'Click Footer Desktop',
    referralClickFooterMobile: 'Click Footer Mobile',
    referralClickAccountDesktop: 'Click Account Desktop',
    referralClickAccountMobile: 'Click Account Mobile',
    referralClickCTAtransfer: 'Click CTAtransfer',
    referralClickCTAtransfercrypto: 'Click CTAtransfercrypto',
    referralClickCTAwithdraw: 'Click CTAwithdraw',
    referral2PercentBannerPromotionPro: 'extra2%promo_propagebanner',
    referral2PercentBannerPromotionAccount: 'extra2%promo_accountpagebanner',
    referralTopCta: 'ref_topCTA',
    referralBottomCta: 'ref_bottomCTA',
    referralTandC: 'ref_T&C',
    referralPromoterBadge: 'ref_promoterbadge',
    referralBorrowerBadge: 'ref_borrowerbadge',
    referralEditCode: 'ref_editcode',
    referralCopyCode: 'ref_copycode',
    referralShareButton: 'ref_sharebutton',
    referralTypeemailIntemplate: 'ref_typeemail_intemplate',
    referralEditSubjectEmail: 'ref_edit_subjectemail',
    referralChooseTemplate1: 'ref_choosetemplate1',
    referralChooseTemplate2: 'ref_choosetemplate2',
    referralChooseTemplate3: 'ref_choosetemplate3',
    referralChooseTemplate4: 'ref_choosetemplate4',
    referralEditMailContent: 'ref_edit_mailcontent',
    referralEmailtemplateShare: 'ref_emailtemplate_share',
    referralFacebookShare: 'ref_facebook_share',
    referralMessengerShare: 'ref_messenger_share',
    referralTwitterShare: 'ref_twitter_share',
    referralWhatsappShare: 'ref_whatsapp_share',
    referralTelegramShare: 'ref_telegram_share',
    referralLinkedinShare: 'ref_linkedin_share',
    referralLineShare: 'ref_line_share',
    referralSmsShare: 'ref_sms_share',
    referralMaterialLogo: 'ref_material_logo',
    referralMaterialBanner: 'ref_material_banner',
    referralMaterialGuideline: 'ref_material_guideline',
    referralMenuBar: 'ref_menubar',
    referralRemindallButton: 'ref_remindall_button',
    referralRemindallSignupStatus: 'ref_remindall_signupstatus',
    referralRemindallEmailStatus: 'ref_remindall_emailstatus',
    referralRemindallKycStatus: 'ref_remindall_kycstatus',
    referralRemindallDepositStatus: 'ref_remindall_depositstatus',
    referralRemindButton: 'ref_remind_button',
    referralRemindSignupButton: 'ref_remindsignup_button',
    referralRemindEmailButton: 'ref_remindemail_button',
    referralRemindKycButton: 'ref_remindkyc_button',
    referralRemindDepositButton: 'ref_reminddeposit_button',
    referralCtaAccountPage: 'ref_CTA_accountpage',
    referralLearnMoreReferralReward: 'ref_learnmore_referralreward',

    affiliateInvite: 'affiliate_invite_email',
    affiliateSubmitEmail: 'aff_submit_email',
    affiliateApplyformButton: 'aff_applyform_button',
    affiliateTandC: 'aff_T&C',
    affiliateBottomCTA: 'aff_bottomCTA',
    affiliateBrandAssetHyperlink: 'aff_brandasset_hyperlink',
    affiliateBlogTemplateHyperlink: 'aff_blogtemplate_hyperlink',
    affiliateHowitwork1: 'aff_howitworks_step1',
    affiliateHowitwork2: 'aff_howitworks_step2',
    affiliateHowitwork3: 'aff_howitworks_step3',
    affiliateReadFullArticles: 'aff_readfullarticles',
    affiliateFaq: 'aff_FAQ',
    affiliateEditcode: 'aff_editcode',
    affiliateCopycode: 'aff_copycode',
    affiliateShareButton: 'aff_sharebutton',
    affiliateTypeemailIntemplate: 'aff_typeemail_intemplate',
    affiliateEditSubjectEmail: 'aff_edit_subjectemail',
    affiliateChooseTemplate1: 'aff_choosetemplate1',
    affiliateChooseTemplate2: 'aff_choosetemplate2',
    affiliateChooseTemplate3: 'aff_choosetemplate3',
    affiliateChooseTemplate4: 'aff_choosetemplate4',
    affiliateEditMailContent: 'aff_edit_mailcontent',
    affiliateEmailtemplateShare: 'aff_emailtemplate_share',
    affiliateFacebookShare: 'aff_facebook_share',
    affiliateMessengerShare: 'aff_messenger_share',
    affiliateTwitterShare: 'aff_twitter_share',
    affiliateWhatsappShare: 'aff_whatsapp_share',
    affiliateLinkedinShare: 'aff_linkedin_share',
    affiliateLineShare: 'aff_line_share',
    affiliateTelegramShare: 'aff_telegram_share',
    affiliateSmsShare: 'aff_sms_share',
    affiliateMaterialLogo: 'aff_material_logo',
    affiliateMaterialBanner: 'aff_material_banner',
    affiliateMaterialGuideline: 'aff_material_guideline',
    affiliateMenuBar: 'aff_menubar',
    affiliateRemindallButton: 'aff_remindall_button',
    affiliateRemindallSignupStatus: 'aff_remindall_signupstatus',
    affiliateRemindallEmailStatus: 'aff_remindall_emailstatus',
    affiliateRemindallKycStatus: 'aff_remindall_kycstatus',
    affiliateRemindallDepositStatus: 'aff_remindall_depositstatus',
    affiliateRemindButton: 'aff_remind_button',
    affiliateRemindSignupButton: 'aff_remindsignup_button',
    affiliateRemindEmailButton: 'aff_remindemail_button',
    affiliateRemindKycButton: 'aff_remindkyc_button',
    affiliateRemindDepositButton: 'aff_reminddeposit_button',
    affiliateCtaAccountPage: 'aff_CTA_accountpage',
    affiliateUserMilestone: 'aff_usermilestone',
    affiliateVolumeMilestone: 'aff_volumemilestone',
    affiliateFAQWhatProhibited: 'aff_FAQ_whatprohibited',
    affiliateFAQWhoBecome: 'aff_FAQ_whobecome',
    affiliateFAQCookie: 'aff_FAQ_cookie',
    affiliateFAQBrandSupport: 'aff_FAQ_brandsupport',
    affiliateFAQReport: 'aff_FAQ_report',
    affiliateFAQGetPaid: 'aff_FAQ_getpaid',
    affiliateLearnMoreAffiliateReward: 'aff_learnmorereward_button',

    upperBannerClickFirstDeposit:'Click First Deposit Banner',
    upperBannerClick2FA:'Click 2FA Banner',
    upperBannerClickFirstInvest:'Click First Invest Banner',
    upperBannerClickOnReinvest:'Click OnReinvest Banner',
    upperBannerClickReinvest:'Click Reinvest Banner',
    upperBannerClickFirstFriend:'Click First Friend Banner',
    upperBannerClickMembershipPromote:'Click Membership Promote Banner',
    upperBannerClickMembershipDemote:'Click Membership Demote Banner',
    upperBannerClickDowngrade:'Click Downgrade Banner',
    upperBannerClickUpgrade:'Click Upgrade Banner',
    upperBannerClickBigWithdraw:'Click Big Withdraw Banner',
    upperBannerClickWithdrawAll:'Click Withdraw All Banner',
    upperBannerNoInvestDepositRecently: 'Click No Invest/Deposit Recently',
    upperBannerClickOn2ndMarketNotify:'Click Turn on Second Market Notify Banner',
    upperBannerClickUpgradeMembership:'Click Upgrade Membership Banner',
    upperBannerClickBadge:'Click Badge Banner',
    upperBannerClickReferral:'Click Referral Banner',
    upperBannerClickReferralPromotion: 'Click Referral Promotion Banner',
    upperBannerClick100MCTReferral14: '100mctreferral14%',

    menuAboutOurStory: 'menu_about_our_story',
    menuAboutWhyPepoleLoveUs: 'menu_about_why_people_love_us',
    menuAboutBadges: 'menu_about_badges',
    menuAboutBlog: 'menu_about_blog',
    menuAboutPricing: 'menu_about_pricing',
    menuAboutSecurityAndRisk: 'menu_about_security_and_risk',
    menuAboutSupportCenter: 'menu_about_support_center',
    menuAboutStatistics: 'menu_about_statistic',

    menuInvestDepositFiat: 'menu_invest_deposit_fiat',
    menuInvestFlex: 'menu_invest_flex',
    menuInvestCryptoBacked: 'menu_invest_crypto_backed',
    menuInvestLoanOriginator: 'menu_invest_loan_originator',
    menuInvestCryptoLend: 'menu_invest_crypto_lend',
    menuInvestDepositCrypto: 'menu_invest_deposit_crypto',
    menuInvestLottery: 'menu_invest_lottery',
    menuInvestSecondaryMarket: 'menu_invest_secondary_market',
    menuInvestStakingMCT: 'menu_invest_staking_mct',
    menuInvestBuyMCT: 'menu_invest_buy_mct',

    menuBorrowCryptoBacked: 'menu_borrow_crypto_backed',
    menuCryptoShort: 'menu_crypto_short',
    menuCryptoFuture: 'menu_crypto_future',
    menuShortBasic: 'menu_short_basic',
    menuBorrowInstitutionalLoans: 'menu_borrow_institutional_loans',
    menuBorrowPoFinancingLoans: 'menu_borrow_po_financing_loans',
    menuCryptoSwap: 'menu_crypto_swap',

    menuSpendingGiftCard: 'menu_spending_gift_card',
    menuSpendingDebitCard: 'menu_spending_debit_card',

    menuActivityMCTToken: 'menu_activity_mct_token',
    menuActivityMCTAirdrop: 'menu_activity_mct_airdrop',
    menuActivityBadges: 'menu_activity_badges',
    menuActivityMembership: 'menu_activity_membership',
    menuActivityReferral: 'menu_activity_referral',
    menuActivityAffiliate: 'menu_activity_affiliate',
    menuActivityPersonalBudget: 'menu_activity_personal_budget',
    menuActivityPrizeWheel: 'menu_activity_prize_wheel',

    menuProPro: 'menu_pro_pro',

    diamond_upgrade_prolending: 'pro-lending page',
    diamond_upgrade_account: 'account page',

    open_confirm_short: 'open_confirm_short',
    cancel_confirm_short: 'cancel_confirm_short',
    submit_confirm_short: 'submit_confirm_short',

    open_confirm_future: 'open_confirm_future',
    cancel_confirm_future: 'cancel_confirm_future',
    submit_confirm_future: 'submit_confirm_future',

    downloadAppIos: 'download_app_ios',
    downloadAppAndroid: 'download_app_android',
    downloadAppQrCode: 'download_app_qr_code',
    downloadAppFooterIos: 'footer_ios',
    downloadAppFooterAndroid: 'footer_android',
    downloadAppHeaderIos: 'header_link_get_app_ios',
    downloadAppHeaderAndroid: 'header_link_get_app_android',
    downloadAppAccountDetailIos: 'account_detail_ios',
    downloadAppAccountDetailAndroid: 'account_detail_android',
    downloadAppAccountDetailQrCode: 'account_detail_qr_code',
    downloadAppBudgetIos: 'budget_ios',
    downloadAppBudgetAndroid: 'budget_android',
    downloadAppBudgetQrCode: 'budget_qr_code',
    downloadAppLoginQrCodeIos: 'login_qr_code_ios',
    downloadAppLoginQrCodeAndroid: 'login_qr_code_android',
    downloadAppLiveChatIos: 'live_chat_ios',
    downloadAppLiveChatAndroid: 'live_chat_android',
    downloadAppLiveChatQrCode: 'live_chat_qr_code',
    downloadAppLendingLotteryIos: 'lending_lottery_ios',
    downloadAppLendingLotteryAndroid: 'lending_lottery_android',
    downloadAppLendingLotteryQrCode: 'lending_lottery_qr_code',
    downloadAppLendingLotteryBottomIos: 'lending_lottery_bottom_ios',
    downloadAppLendingLotteryBottomAndroid: 'lending_lottery_bottom_android',
    downloadAppLendingLotteryBottomQrCode: 'lending_lottery_bottom_qr_code',
    downloadAppHeaderMenuIos: 'header_menu_ios',
    downloadAppHeaderMenuAndroid: 'header_menu_android',

    ll_seeresult: 'll_seeresult',
    ll_howitworkshyperlink: 'll_howitworkshyperlink',
    ll_whycreate: 'll_whycreate',

    ll_typeamount: 'll_typeamount',
    ll_loginhyperlink: 'll_loginhyperlink',
    ll_registerhyperlink: 'll_registerhyperlink',
    ll_appstore: 'll_appstore',
    ll_googleplay: 'll_googleplay',
    ll_qrcode: 'll_qrcode',
    ll_scrollprize: 'll_scrollprize',

    ll_viewtrustpilot: 'll_viewtrustpilot',

    ll_howitworks_step1: 'll_howitworks_step1',
    ll_howitworks_step2: 'll_howitworks_step2',
    ll_howitworks_step3: 'll_howitworks_step3',
    ll_howitworks_step4: 'll_howitworks_step4',
    ll_howitworks_step5: 'll_howitworks_step5',

    ll_grandprize_click: 'll_grandprize_click',
    ll_1constantball_click: 'll_1constantball_click',
    ll_1number1constantball_click: 'll_1number1constantball_click',
    ll_2number1constantball_click: 'll_2number1constantball_click',
    ll_3number1constantball_click: 'll_3number1constantball_click',
    ll_4number1constantball_click: 'll_4number1constantball_click',
    ll_5number1constantball_click: 'll_5number1constantball_click',
    ll_2number_click: 'll_2number_click',
    ll_3number_click: 'll_3number_click',
    ll_4number_click: 'll_4number_click',
    ll_5number_click: 'll_5number_click',
    ll_6number_click: 'll_6number_click',

    ll_footerbanner: 'll_footerbanner',
    ll_investCTAbutton: 'll_investCTAbutton',
    ll_confirmtermbutton: 'll_confirmtermbutton',
    ll_myticket_atseeresult: 'll_myticket_atseeresult',

    ll_reminderon_myticket: 'll_reminderon_myticket',
    ll_reminderoff_myticket: 'll_reminderoff_myticket',
    ll_reminderoff_createticket: 'll_reminderoff_createticket',
    ll_reminderon_createticket: 'll_reminderon_createticket',

    ll_pickyournumber: 'll_pickyournumber',
    ll_quickpick: 'll_quickpick',
    ll_quickpick_refresh: 'll_quickpick_refresh',
    ll_quickpick_refreshall: 'll_quickpick_refreshall',
    ll_pickyournumber_refresh: 'll_pickyournumber_refresh',

    ll_FAQ_whatislendinglottery: 'll_FAQ_whatislendinglottery',
    ll_FAQ_howitworks: 'll_FAQ_howitworks',
    ll_FAQ_chanceofwinning: 'll_FAQ_chanceofwinning',
    ll_FAQ_howmanyticket: 'll_FAQ_howmanyticket',
    ll_FAQ_pickownnumber: 'll_FAQ_pickownnumber',
    ll_FAQ_howlongticketlast: 'll_FAQ_howlongticketlast',
    ll_FAQ_howfundused: 'll_FAQ_howfundused',
    ll_FAQ_risk: 'll_FAQ_risk',
    ll_FAQ_endtermearly: 'll_FAQ_endtermearly',
    ll_FAQ_howprizepaid: 'll_FAQ_howprizepaid',
    ll_FAQ_whenprizedraw: 'll_FAQ_whenprizedraw',
    ll_FAQ_winningnumber: 'll_FAQ_winningnumber',
    ll_FAQ_forgettochecknumber: 'll_FAQ_forgettochecknumber',

    ll_overview: 'll_overview',
    ll_overview_totalinvest: 'll_overview_totalinvest',
    ll_overview_totalearning: 'll_overview_totalearning',
    ll_overview_pendingticket: 'll_overview_pendingticket',
    ll_overview_activeticket: 'll_overview_activeticket',

    ll_tabinvestticket: 'll_tabinvestticket',
    ll_tabrewardticket: 'll_tabrewardticket',

    ll_thisweekresult: 'll_thisweekresult',
    ll_previousweekresult: 'll_previousweekresult',

    ll_activetickettable_createterm: 'll_activetickettable_createterm',
    ll_activetickettable_picknumber: 'll_activetickettable_picknumber',

    ll_mission_invitefriend: 'll_mission_invitefriend',
    ll_mission_copyrefcode: 'll_mission_copyrefcode',
    ll_mission_send5invites: 'll_mission_send5invites',
    ll_mission_typecontact: 'll_mission_typecontact',
    ll_mission_recordvideo: 'll_mission_recordvideo',
    ll_mission_entervideolink: 'll_mission_entervideolink',
    ll_mission_uploadvideo: 'll_mission_uploadvideo',

    ll_promobanner_accounts: 'll_promobanner_accounts',
    ll_promobanner_livechat: 'll_promobanner_livechat',
    ll_livechat_CTA: 'll_livechat_CTA',

    badgeReviewTrustPilotAccountDesktop: 'badge_review_trustPilot_account_desktop',
    badgeReviewTrustPilotAccountMobile: 'badge_review_trustPilot_account_mobile',
    badgeReviewTrustPilotLendingLottery: 'badge_review_trustPilot_lending_lottery',
    badgeReviewTrustPilotBadgeList: 'badge_review_trustPilot_badge_list',

    tracking_bounce_rate_signup: 'tracking_bounce_rate_signup',
    tracking_bounce_rate: 'tracking_bounce_rate',

    nftFacebookShare: 'nft_facebook_share',
    nftMessengerShare: 'nft_messenger_share',
    nftTwitterShare: 'nft_twitter_share',
    nftWhatsappShare: 'nft_whatsapp_share',
    nftTelegramShare: 'nft_telegram_share',
    nftLinkedinShare: 'nft_linkedin_share',
    nftLineShare: 'nft_line_share',
    nftSmsShare: 'nft_sms_share',
    nftCopyLinkShare: 'nft_copy_link_share',

    unknown_error: 'unknown_error',
    report_error: 'report_error',

    activityMenuNFTReward: 'activity_menu_nft_reward',
    activityMenuNFTCollection: 'activity_menu_nft_collection',
    activityMenuMCTAirdrop: 'activity_menu_mct_airdrop',
    activityMenuMCTBonus: 'activity_menu_mct_internal_bonus',

    menuCommunityLiveChat: 'menu-community-live-chat',
    menuCommunityForum: 'menu-community-forum',
    menuCommunityDiscord: 'menu-community-discord',

    menuMembershipMCTMembership: 'menu-membership-mct-membership',
    menuMembershipMCTMascot: 'menu-membership-mct-mascot',

    air_login_click: 'air_login_click',
    air_createaccount_click: 'air_createaccount_click',
    air_entercode_click: 'air_entercode_click',
    air_entercode_type: 'air_entercode_type',
    air_spin_click: 'air_spin_click',
    air_popup_signup_click: 'air_popup_signup_click',
    air_popup_otherchance_click: 'air_popup_otherchance_click',
    air_gleamlink_click: 'air_gleamlink_click',
    air_FAQ_howjoin: 'air_FAQ_howjoin',
    air_FAQ_getspin: 'air_FAQ_getspin',
    air_FAQ_manyspin: 'air_FAQ_manyspin',
    air_FAQ_maxmct: 'air_FAQ_maxmct',
    air_FAQ_wheremct: 'air_FAQ_wheremct',
    air_menubar: 'air_menubar',
    air_livechat_click: 'air_livechat_click',

    deposit_promo_10_account: 'deposit_promo_10_account',
    deposit_promo_10_livechat: 'deposit_promo_10_livechat',
    
    kycStep1: 'kyc_step_1',
    kycStep2: 'kyc_step_2',
    kycStep3: 'kyc_step_3',
    kycStep4: 'kyc_step_4',
    kycStep5: 'kyc_step_5',
    kycStep6: 'kyc_step_6',
    kycStep7: 'kyc_step_7',
    kycStep8: 'kyc_step_8',
    kycStep9: 'kyc_step_9',
    kycStep10: 'kyc_step_10',

    submitNewKycFlow: 'submitNewKycFlow',
    submitOldKycFlow: 'submitOldKycFlow',

    newFlow: 'deposit_ach_new_flow',
    newFlowConfirm: 'new_flow_confirm',
    newFlowCancel: 'new_flow_cancel',
    newFlowQuiz: 'new_flow_quiz',
    newFlowConfirmCancel: 'new_flow_confirm_cancel',
    newFlowConfirmSubmit: 'new_flow_confirm_submit',
    newFlowResultHighRisk: 'new_flow_result_high_risk',
    newFlowResultSuccess: 'new_flow_result_success',
    newFlowCloseQuiz: 'new_flow_close_quiz',
    newFlowCompleteQuiz: 'new_flow_complete_quiz',
    newFlowExceptionRecaptcha: 'new_flow_result_exception_recaptcha',
    newFlowExceptionInsufficientBankAccount: 'new_flow_result_exception_insufficient_bank_account',
    newFlowExceptionNotAbletoGenerateRefCode: 'new_flow_result_exception_not_able_to_generate_ref_code',
    newFlowExceptionPlaidUnableToAccessLinkedBank: 'new_flow_result_exception_plaid_unable_to_access_linked_bank',
    newFlowExceptionRequestFailed: 'new_flow_result_exception_request_failed',
    newFlowExceptionCode403: 'new_flow_result_exception_code_403',
    newFlowExceptionCommon: 'new_flow_result_exception_common',

    oldFlow: 'deposit_ach_old_flow',
    oldFlowConfirm: 'old_flow_confirm',
    oldFlowCancel: 'old_flow_cancel',
    oldFlowQuiz: 'old_flow_quiz',
    oldFlowConfirmCancel: 'old_flow_confirm_cancel',
    oldFlowConfirmSubmit: 'old_flow_confirm_submit',
    oldFlowResultHighRisk: 'old_flow_result_high_risk',
    oldFlowResultSuccess: 'old_flow_result_success',
    oldFlowCloseQuiz: 'old_flow_close_quiz',
    oldFlowCompleteQuiz: 'old_flow_complete_quiz',
    oldFlowExceptionRecaptcha: 'old_flow_result_exception_recaptcha',
    oldFlowExceptionInsufficientBankAccount: 'old_flow_result_exception_insufficient_bank_account',
    oldFlowExceptionNotAbletoGenerateRefCode: 'old_flow_result_exception_not_able_to_generate_ref_code',
    oldFlowExceptionPlaidUnableToAccessLinkedBank: 'old_flow_result_exception_plaid_unable_to_access_linked_bank',
    oldFlowExceptionRequestFailed: 'old_flow_result_exception_request_failed',
    oldFlowExceptionCode403: 'old_flow_result_exception_code_403',
    oldFlowExceptionCommon: 'old_flow_result_exception_common',

    //Borrow
    initiate_borrow: 'initiate_borrow',
    borrow_help_button: 'borrow_help_button',
    borrow_help_button_close: 'borrow_help_button_close',
    borrow_help_button_hide: 'borrow_help_button_hide',
    borrow_helpcallus_button: 'borrow_helpcallus_button',
    borrow_helpbookcall_button: 'borrow_helpbookcall_button',
    borrow_helpchat_button: 'borrow_helpchat_button',
    borrow_borrowamount: 'borrow_borrowamount',
    borrow_collateralamount: 'borrow_collateralamount',
    borrow_collateralrequired_scroll: 'borrow_collateralrequired_scroll',
    borrow_collateralrequired_hover: 'borrow_collateralrequired_hover',
    borrow_collateralrequired_click: 'borrow_collateralrequired_click',
    borrow_loanincrypto_scroll: 'borrow_loanincrypto_scroll',
    borrow_loanincrypto_hover: 'borrow_loanincrypto_hover',
    borrow_loanincrypto_click: 'borrow_loanincrypto_click',
    borrow_term30_click: 'borrow_term30_click',
    borrow_term90_click: 'borrow_term90_click',
    borrow_term180_click: 'borrow_term180_click',
    borrow_loanisolatedON_click: 'borrow_loanisolatedON_click',
    borrow_term30_hover: 'borrow_term30_hover',
    borrow_term90_hover: 'borrow_term90_hover',
    borrow_term180_hover: 'borrow_term180_hover',
    borrow_loanisolatedON_hover: 'borrow_loanisolatedON_hover',
    borrow_borrownowCTA_button: 'borrow_borrownowCTA_button',
    borrow_borrownowCTA_buttonhover: 'borrow_borrownowCTA_buttonhover',
    borrow_login_button: 'borrow_login_button',
    borrow_register_button: 'borrow_register_button',
    borrow_login_click_hover: 'borrow_login_click_hover',
    borrow_register_click_hover: 'borrow_register_click_hover',
    borrow_copyaddress_button: 'borrow_copyaddress_button',
    borrow_back_button: 'borrow_back_button',
    borrow_done_button: 'borrow_done_button',
    borrow_setupatime_button: 'borrow_setupatime_button',
    borrow_whyborrowvideo_click: 'borrow_whyborrowvideo_click',
    borrow_whyborrowvideo_hover: 'borrow_whyborrowvideo_hover',
    borrow_whyborrow1_click: 'borrow_whyborrow1_click',
    borrow_whyborrow2_click: 'borrow_whyborrow2_click',
    borrow_whyborrow5boxes_hover: 'borrow_whyborrow5boxes_hover',
    borrow_viewpress_button: 'borrow_viewpress_button',
    borrow_howtoborrow5steps_hover: 'borrow_howtoborrow5steps_hover',
    borrow_FAQ_maxloan: 'borrow_FAQ_maxloan',
    borrow_FAQ_loanreceivingtime: 'borrow_FAQ_loanreceivingtime',
    borrow_FAQ_creditscorecheck: 'borrow_FAQ_creditscorecheck',
    borrow_FAQ_protectcollateral: 'borrow_FAQ_protectcollateral',
    borrow_FAQ_norepayloan: 'borrow_FAQ_norepayloan',
    borrow_FAQ_repayearly: 'borrow_FAQ_repayearly',
    borrow_FAQ_cryptopricedrop: 'borrow_FAQ_cryptopricedrop',
    borrow_FAQ_recallexcess: 'borrow_FAQ_recallexcess',
    borrow_FAQ_LTVratio: 'borrow_FAQ_LTVratio',
    borrow_FAQ_howtorepay: 'borrow_FAQ_howtorepay',
    borrow_FAQ_repaylate: 'borrow_FAQ_repaylate',
    borrow_FAQ_isolatedloan: 'borrow_FAQ_isolatedloan',
    borrow_FAQ_multicollateralrepayment: 'borrow_FAQ_multicollateralrepayment',
    borrow_FAQ_wheretoborrowmulticollateral: 'borrow_FAQ_wheretoborrowmulticollateral',
    borrow_FAQ_why24hrsexvolume: 'borrow_FAQ_why24hrsexvolume',
    borrow_FAQ_loanautotopup: 'borrow_FAQ_loanautotopup',
    borrow_FAQ_loantopuptoggle: 'borrow_FAQ_loantopuptoggle',
    borrow_FAQ_repaynotification: 'borrow_FAQ_repaynotification',
    borrow_FAQ_payallloan: 'borrow_FAQ_payallloan',
    borrow_FAQ_repayandrecallexcesss: 'borrow_FAQ_repayandrecallexcesss',
    borrow_FAQ_cannotrecallexcess: 'borrow_FAQ_cannotrecallexcess',
    borrow_FAQ_whatcollateralrate: 'borrow_FAQ_whatcollateralrate',
    borrow_signupnowCTA_button: 'borrow_signupnowCTA_button',
    borrow_signupnowCTA_buttonhover: 'borrow_signupnowCTA_buttonhover',
    borrow_borrownowFooterCTA_button: 'borrow_borrownowFooterCTA_button',
  },
  labels: {
  }
};

const ga = (category, action, label, value) => {
  try {
    if (value && typeof value === 'number') {
      window.gtag('event',action , {
        'event_category': category,
        'event_label': label,
        'value': value
      });
    } else {
      window.gtag('event',action , {
        'event_category': category,
        'event_label': label
      });
    }
  } catch (err) {console.log(err);}
};
export default ga;
