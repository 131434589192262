import { DEFAULT_INVESTMENT_RATE, DEFAULT_SUMMARY_INVESTMENT, INVESTMENT_TYPE } from 'src/resources/constants/app';
import { GET_PROFILE as GET_PROFILE_FROM_LOGIN, LOGIN } from 'src/screens/login/type';
import { REGISTER } from 'src/screens/register/type';
import UserModel from 'src/models/user';
import currentUser from 'src/utils/authentication';
import { AMOUNT_RATE } from 'src/components/exchangeMethods/constant';
import { TERM_DEPOSIT_SUMMARY, VOID_TERM_DEPOSIT_SUMMARY } from 'src/screens/yourAccounts/redux/type';
import { CHANGE_MIDDLE_NAME } from 'src/screens/kyc/type';
import { INFO } from 'src/screens/memberShip/type';
import {
  CHECK_TRIAL_USER_AVAIABLE,
  GET_PROFILE_INTEREST,
  GET_SYSTEM_INTEREST,
  LOGOUT,
  SECONDARY_MARKET_NOTIFICATION_SETTINGS,
  SESSION_EXPIRED,
  SET_REQUIRE_OTP,
  SET_RETRY_OTP,
  SET_TOKEN,
  UPDATE_PROFILE_INFO,
  WITHDRAW_EMAIL_SETTING,
  UPDATE_REFERRAL_CODE,
  GET_USER_ATTRIBUTES,
  GET_USER_LOYALTY_BENEFITS, GET_MCT_LOYALTY_PROGRAM_INFO, GET_BALANCE, ADD_NEW_PLAID_BANK, UPDATE_PLAID_BANK,
} from './type';

const initialState = {
  token: '',
  profile: {},
  userAttributes: {},
  loyaltyBenefits: {},
  cryptoLoanHistory: [],
  isJoinedTrial: false,
  isTrialUser: false,
  trialLeft: 0,
  profileInterest: {},
  systemInterest: 0,
  isRequireOTP: false,
  loginCallBack: null,
  numRetryOTP: 0,
  summaryInvestment: { ...DEFAULT_SUMMARY_INVESTMENT[INVESTMENT_TYPE.INVESTMENT] },
  summarySecondaryInvestment: { ...DEFAULT_SUMMARY_INVESTMENT[INVESTMENT_TYPE.SECONDARY_MARKET] },
  notificationSetting: {
    maxTerm: 1.0,
    minRate: DEFAULT_INVESTMENT_RATE.toFixed(2),
  },
  isAddNewPlaidBank: false,
  isUpdatePlaidBank: false,
};

export default (state = initialState, { type, payload, data, more }) => {
  switch (type) {
    case `${CHECK_TRIAL_USER_AVAIABLE}_SUCCESS`:
      return {
        ...state,
        isTrialUser: !!data.trialAvailable,
        isJoinedTrial: data.joinedTrial,
        trialLeft: data?.trialLeft
      };
    case `${GET_BALANCE}_SUCCESS`:
      return {
        ...state,
        profile: {
          ...state.profile,
          constantBalance: data/AMOUNT_RATE || 0
        }
      };
    case LOGOUT:
      if (__CLIENT__){
        const lang = localStorage.getItem('lang');
        localStorage.clear();
        if (lang) {
          localStorage.setItem('lang', lang);
        }
        const {isRedirect, redirectTo} = data;
        if(isRedirect) {
          window.location.href = redirectTo;
        }

      }
      return initialState;
    case `${REGISTER}_SUCCESS`:
    case `${LOGIN}_SUCCESS`:
      return {
        ...state,
        token: data.Token
      };
    case SET_TOKEN:
      return {
        ...state,
        token: data
      };
    case SET_REQUIRE_OTP:
      return {
        ...state,
        ...data
      };
    case SET_RETRY_OTP:
      return {
        ...state,
        numRetryOTP: data ? state.numRetryOTP + 1 : 0
      };
    case SESSION_EXPIRED:
      if (__CLIENT__) {
        currentUser.removeAccessToken();
      }
      return initialState;
    case 'GET_PROFILE':
      return {
        ...state,
        profile: {
          ...state.profile,
          ...payload
        }
      };
    case 'UPDATE_AUTH_STATE':
      return {
        ...state,
        ...payload
      };
    case `${GET_PROFILE_FROM_LOGIN}_SUCCESS`:
    case `${UPDATE_PROFILE_INFO}_SUCCESS`:
      if (data) {
        const userProfile = UserModel.userProfile(data);
        //hardcode test reject
        // userProfile.verifiedLevel = VERIFIED_LEVEL.UserVerifiedLevelRejected;
        // userProfile.DiscardNote = 'E005 : The country you listed does not match the country on your ID.';
        return {
          ...state,
          profile: {
            ...state.profile,
            ...userProfile
          }
        };
      }
      return {
        ...state,
        profile: {
          ...state.profile,
          ...payload
        }
      };
    // case 'GET_TRANSACTIONS':
    //   return {
    //     ...state,
    //     historyList: payload
    //   };
    // case 'UPDATE_TRANSACTION_SUCCESS': {
    //   return {
    //     ...state,
    //     historyList: {
    //       ...state.historyList,
    //       transactions: state.historyList.transactions.map(
    //         e => (e.id === payload.id ? payload : e)
    //       )
    //     }
    //   };
    // }
    // case 'GET_TRANSFER_ORDERS':
    //   return {
    //     ...state,
    //     transferOrders: payload
    //   };
    // case 'GET_REFERRALS':
    //   return {
    //     ...state,
    //     referrals: payload
    //   };
    // case 'GET_REFERRAL_EARNING_PROMOTION':
    //   return {
    //     ...state,
    //     referralsEarningPromotion: payload
    //   };
    // case 'GET_REFERRAL_EARNING_REFERRAL':
    //   return {
    //     ...state,
    //     referralsEarningReferral: payload
    //   };
    case 'PUT_CRYPTO_LOAN_HISTORY': {
      return {
        ...state,
        cryptoLoanHistory: payload
      };
    }
    case `${GET_PROFILE_INTEREST}_SUCCESS`: {
      return {
        ...state,
        profileInterest: data
      };
    }
    case `${GET_SYSTEM_INTEREST}_SUCCESS`: {
      return {
        ...state,
        systemInterest: data
      };
    }
    case `${TERM_DEPOSIT_SUMMARY}_SUCCESS`: {
      const s = {...(DEFAULT_SUMMARY_INVESTMENT[INVESTMENT_TYPE.INVESTMENT])};
      data.forEach(item => {
        s[item.status] = item.count;
      });

      return {
        ...state,
        summaryInvestment: {...s}
      };
    }
    case `${VOID_TERM_DEPOSIT_SUMMARY}_SUCCESS`: {
      const s = {...(DEFAULT_SUMMARY_INVESTMENT[INVESTMENT_TYPE.SECONDARY_MARKET])};
      data.forEach(item => {
        s[item.status] = item.count;
      });

      return {
        ...state,
        summarySecondaryInvestment: {...s}
      };
    }
    case `${SECONDARY_MARKET_NOTIFICATION_SETTINGS}_SUCCESS`: {
      return {
        ...state,
        notificationSetting: {
          ...data
        }
      };
    }
    case `${WITHDRAW_EMAIL_SETTING}_SUCCESS`: {
      return {
        ...state,
        profile: {
          ...state.profile,
          withdrawConfirmedEmailOn: more.enable,
        }
      };
    }
    case `${UPDATE_REFERRAL_CODE}_SUCCESS`: {
      return {
        ...state,
        profile: {
          ...state.profile,
          referralCode: more.referralCode,
        }
      };
    }
    case `${CHANGE_MIDDLE_NAME}_SUCCESS`: {
      return {
        ...state,
        profile: {
          ...state.profile,
          middleName: more.middleName,
        }
      };
    }
    case `${INFO}_SUCCESS`: {
      return {
        ...state,
        membershipInfo: data,
      };
    }
    case `${GET_USER_ATTRIBUTES}_SUCCESS`: {
      return {
        ...state,
        userAttributes: data,
      };
    }
    case `${GET_USER_LOYALTY_BENEFITS}_SUCCESS`: {
      return {
        ...state,
        loyaltyBenefits: data,
      };
    }
    case `${GET_MCT_LOYALTY_PROGRAM_INFO}_SUCCESS`: {
      return {
        ...state,
        benefitSettings: data,
      };
    }
    case ADD_NEW_PLAID_BANK:
      return {
        ...state,
        isAddNewPlaidBank: data
      };
    case UPDATE_PLAID_BANK:
      return {
        ...state,
        isUpdatePlaidBank: data
      };
    default:
      return state;
  }
};
