import { GET_CREDIT_SCORE, GET_REMAIN_ACH, SAVE_DEPOSIT_ORDER_INFO } from './action';

const initState = {
  orderInfo: null,
  remainAch: null,
  creditScore: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case SAVE_DEPOSIT_ORDER_INFO:
      return {
        ...state,
        orderInfo: action.data,
      };
    case `${GET_REMAIN_ACH}_SUCCESS`:
      return {
        ...state,
        remainAch: action.data,
      };
    case `${GET_CREDIT_SCORE}_SUCCESS`:
      return {
        ...state,
        creditScore: action.data,
      };
    default:
      return state;
  }
};
