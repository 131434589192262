import axios from 'axios';
import { API_BASE, API_URL } from 'src/resources/constants/url';
import authentication from 'src/utils/authentication';
import { REQUEST_TIMEOUT } from 'src/resources/constants/app';
import { requestHeaderInterceptor } from './utils';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const instance = axios.create({
  baseURL: API_BASE,
  timeout: REQUEST_TIMEOUT,
  crossDomain: true,
  headers
});


// Add a request interceptor
instance.interceptors.request.use(requestHeaderInterceptor, error => Promise.reject(error));

// Add a response interceptor
instance.interceptors.response.use(
  response => {
    try {
      const {
        data: { Result, Error: err }
      } = response;
      // this will be replace soon
      if (err) {
        throw {
          fakeError: true,
          error: true,
          status: -1, // status code
          data: err,
          code: -1, // error code
          message: err, // error msg
          statusText: err
        };
      } // end
      return Result;
    } catch (e) {
      // fake error
      if (e?.fakeError) {
        delete e?.fakeError;
        throw e;
      } // end
      return null;
    }
  },
  error => {
    try {
      if (error && error.response) {
        const {
          config, response: { status, data, statusText }
        } = error;
        const errorData = {
          error: true,
          status: status, // status code
          data: data,
          code: data?.Error?.Code, // error code
          message: data?.Error?.Message, // error msg
          statusText: statusText
        };
        if ((status === 401) && !`${API_BASE}${API_URL.USER.USER_SIGN_IN}`.includes(config?.url)) {
          authentication.removeAccessToken();
          return Promise.reject({
            sessionExpired: true,
            ...errorData
          });
        }
        console.warn('Response error', error);
        return Promise.reject(errorData);
      }
      if (error?.request) {
        return Promise.reject({
          error: true,
          message: error?.message,
          code: 'request_failed'
        });
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export default instance;
