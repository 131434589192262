import React from 'react';
import loadable from '@loadable/component';
import BrowserDetect from 'src/services/browser-detect';
import styles from './styles.scss';
import lightLoading from './img/light.json';
import darkLoading from './img/dark.json';
import lightDesktopLoading from './img/lightDesktop.json';
import darkDesktopLoading from './img/darkDesktop.json';

const LottiePlayer = loadable(() => import('src/components/lottiePlayer'));

export const LottieLoader = ({height, fontSize = 16, src}) => {
  return (
    <LottiePlayer
      autoplay
      loop
      src={src}
      style={{
        height: `${height}px`,
        // width: `${height}px`,
        overflow: 'unset',
        fontSize,
      }}
    />
  );
};

const Loading = ({
  as: Com,
  loading = true,
  size = 5,
  sizeUnit = 'px',
  color,
  height = 24,
  // light,
  dark = true,
  // isOld = true,
  ...options
}) => {
  const LoaderComponent = Com || LottieLoader;
  // let colorLoading = color;
  // if (!colorLoading) {
  //   dark && (colorLoading = '#0062cc');
  //   light && (colorLoading = '#fff');
  // }

  let src = null;
  if(BrowserDetect.isDesktop) {
    src = dark ? darkDesktopLoading : lightDesktopLoading;
  } else {
    src = dark ? darkLoading : lightLoading;
  }

  return (
      <LoaderComponent
        height={height}
        loading={loading}
        size={size}
        sizeUnit={sizeUnit}
        // color={colorLoading}
        className={styles.container}
        src={src}
        {...options}
      />
  );

  // return isOld ? (
  //   <LoaderComponent
  //     height={height}
  //     loading={loading}
  //     size={size}
  //     sizeUnit={sizeUnit}
  //     color={colorLoading}
  //     className={styles.container}
  //     {...options}
  //   />
  // ) : (
  //   <LottiePlayer
  //     autoplay
  //     loop
  //     src={dark ? darkLoading : lightLoading}
  //     style={{
  //       height: `${height}px`,
  //       width: `${height}px`,
  //     }}
  //   />
  // );
};

export default Loading;

/** USE WITH ANOTHER LOADER */
/**
 * import { AnotherLoader } from 'react-spinners';
 *
 * <Loader as={AnotherLoader} {...loader options here} />
 */


/** https://www.npmjs.com/package/react-spinners */
