import {FAIL_DEFAULT_LANGUAGE} from 'src/resources/constants/languages';
import localStore from 'src/services/localStore';
import {APP, SYSTEM_CONFIGS} from 'src/resources/constants/app';
import {AMOUNT_RATE} from 'src/components/exchangeMethods/constant';
import { camelizeKeys } from 'src/utils/object';
import moment from 'moment-timezone/moment-timezone';
import APP_TYPE, { CONTENT_VARIABLES } from './type';

const {
  HEADER_TITLE_SET,
  HEADER_RIGHT_REMOVE,
  HEADER_LEFT_REMOVE,
  HEADER_HIDE,
  HEADER_THEME,
  HEADER_DEFAULT,
  UPDATE_APP_STATE,
  SET_LANGUAGE,
  HIDE_CONFIRM,
  SHOW_CONFIRM,
  SHOW_SCAN_QRCODE,
  HIDE_SCAN_QRCODE,
  SHOW_QRCODE_CONTENT,
  HIDE_QRCODE_CONTENT,
  SHOW_REQUIRE_PASSWORD,
  HIDE_REQUIRE_PASSWORD,
  CONVERT_USD_TO_VND,
  CHANGE_HISTORY_TAB,
  GET_SYSTEM_CONFIGS,
  GET_SYSTEM_BADGES,
  GET_UNREAD_NOTIFICATIONS,
  UPDATE_DEBUG_MODE,
  UPDATE_LOCK_REDIRECT_MODE,
  USER_KYC_US_TRIAL_AMOUNT,
  GET_CONTENT_VARIABLE,
} = APP_TYPE;

const initState = {
  version: '0.0.2', // localStore.get(APP.VERSION),

  rootLoading: true,

  locale: FAIL_DEFAULT_LANGUAGE, // localStore.get(APP.LOCALE) || 'en',

  isCalling: false,
  isLoading: false,

  isModal: false,
  isModalContent: null,

  isAlert: false,
  isAlertContent: null,
  configAlert: {
    isShow: false,
    message: '',
  },

  isError: false,
  isWarning: false,

  overlay: false,

  isNotFound: false,

  headerTitle: HEADER_DEFAULT,
  headerBack: false,
  headerRightContent: null,
  headerLeftContent: null,
  showHeader: false,
  headerTheme: false,

  ipInfo: localStore.get(APP.IP_INFO) || { country_code: '', is_default: true },

  isBannedCash: false,
  isBannedPrediction: false,
  isBannedChecked: false,
  isBannedIp: false,

  isNerworkError: false,

  modal: {
    className: '',
    show: false,
    body: null,
    title: null,
    centered: false,
  },
  openQrScanner: false,
  currencyRate: {
    usdToVnd: null,
  },
  meHistoryTab: false,
  showSignUpHeader: false,
  showBorrowHeader: false,
  ...SYSTEM_CONFIGS,
  badges: null,
  unReadNotifications: {},
  showPromotionHeader: () => {
    const diffTime = moment.utc().diff(moment.utc('2020-12-31').endOf('day'), 'second') || 0;
    return diffTime < 0;
  },
  isDebug: false,
  contentVariables: {
    ...CONTENT_VARIABLES
  },
  flexCollaterals: []
};

export default (state = initState, action) => {
  switch (action.type) {
    case CONVERT_USD_TO_VND:
      return {
        ...state,
        currencyRate: {
          ...state.currencyRate,
          usdToVnd: action.payload,
        }
      };
    case SHOW_CONFIRM:
      return {
        ...state,
        passcodeData: action.payload,
      };
    case HIDE_CONFIRM:
      return {
        ...state,
        passcodeData: action.payload,
      };
    case SHOW_QRCODE_CONTENT:
      return {
        ...state,
        qRCodeContentData: action.payload,
      };
    case HIDE_QRCODE_CONTENT:
      return {
        ...state,
        qRCodeContentData: action.payload,
      };
    case SHOW_REQUIRE_PASSWORD:
      return {
        ...state,
        passwordData: action.payload,
      };
    case HIDE_REQUIRE_PASSWORD:
      return {
        ...state,
        passwordData: action.payload,
      };
    case HEADER_TITLE_SET:
      return {
        ...state,
        headerTitle: action.payload,
      };
    case HEADER_RIGHT_REMOVE:
      return {
        ...state,
        headerRightContent: null,
      };
    case HEADER_LEFT_REMOVE:
      return {
        ...state,
        headerLeftContent: null,
      };
    case HEADER_HIDE:
      return {
        ...state,
        showHeader: false,
      };
    case HEADER_THEME: {
      return {
        ...state,
        ...action.payload
      };
    }
    case UPDATE_APP_STATE:
      return {
        ...state,
        ...action.payload
      };
    case SET_LANGUAGE: {
      // if (!action.autoDetect) {
      //   if(__CLIENT__) {
      //     localStore.save(APP.LOCALE, action.payload);
      //   }
      // }
      return {
        ...state,
        locale: action.payload,
      };
    }
    case SHOW_SCAN_QRCODE:
      return {
        ...state,
        openQrScanner: true
      };
    case HIDE_SCAN_QRCODE:
      return {
        ...state,
        openQrScanner: false
      };
    case CHANGE_HISTORY_TAB: {
      return {
        ...state,
        meHistoryTab: action.payload
      };
    }
    case `${GET_SYSTEM_CONFIGS}_SUCCESS`: {
      return {
        ...state,
        API_RESERVE_STABLECOIN_APPROVE_MIN_AGENT_AMOUNT: parseFloat(Number(action?.data?.API_RESERVE_STABLECOIN_APPROVE_MIN_AGENT_AMOUNT) / AMOUNT_RATE) || SYSTEM_CONFIGS.API_RESERVE_STABLECOIN_APPROVE_MIN_AGENT_AMOUNT,
        API_RESERVE_STABLECOIN_APPROVE_MIN_SUPER_AMOUNT: parseFloat(Number(action?.data?.API_RESERVE_STABLECOIN_APPROVE_MIN_SUPER_AMOUNT) / AMOUNT_RATE) || SYSTEM_CONFIGS.API_RESERVE_STABLECOIN_APPROVE_MIN_SUPER_AMOUNT,
        API_RESERVE_INTERNAL_APPROVE_MIN_AGENT_AMOUNT: parseFloat(Number(action?.data?.API_RESERVE_INTERNAL_APPROVE_MIN_AGENT_AMOUNT) / AMOUNT_RATE) || SYSTEM_CONFIGS.API_RESERVE_INTERNAL_APPROVE_MIN_AGENT_AMOUNT,
        RESERVE_REDEEM_PRIMETRUST_MIN_AMOUNT: parseFloat(Number(action?.data?.RESERVE_REDEEM_PRIMETRUST_MIN_AMOUNT) / AMOUNT_RATE) || SYSTEM_CONFIGS.RESERVE_REDEEM_PRIMETRUST_MIN_AMOUNT,
        API_PLAID_PUBLIC_KEY: action?.data?.API_PLAID_PUBLIC_KEY,
        API_BORROW_MATCHING_FEE_PERCENT: parseFloat(Number(action?.data?.API_BORROW_MATCHING_FEE_PERCENT) / AMOUNT_RATE) || SYSTEM_CONFIGS.API_BORROW_MATCHING_FEE_PERCENT,
        USER_KYC_US_TRIAL_AMOUNT: parseFloat(Number(action?.data?.USER_KYC_US_TRIAL_AMOUNT) / AMOUNT_RATE) || SYSTEM_CONFIGS.USER_KYC_US_TRIAL_AMOUNT,
        USER_KYC_US_TRIAL_PERIOD: action?.data?.USER_KYC_US_TRIAL_PERIOD,
        USER_MAX_INSTANT_CREDIT: parseFloat(Number(action?.data?.USER_MAX_INSTANT_CREDIT) / AMOUNT_RATE) || SYSTEM_CONFIGS.USER_MAX_INSTANT_CREDIT,
        API_BORROW_MAX_USD_LOAN: parseFloat(Number(action?.data?.API_BORROW_MAX_USD_LOAN) / AMOUNT_RATE) || SYSTEM_CONFIGS.API_BORROW_MAX_USD_LOAN,
        API_BORROW_MIN_USD_LOAN: parseFloat(Number(action?.data?.API_BORROW_MIN_USD_LOAN) / AMOUNT_RATE) || SYSTEM_CONFIGS.API_BORROW_MIN_USD_LOAN,
        API_RESERVE_WIRE_TRANSFER_RULE_TIME: action?.data?.API_RESERVE_WIRE_TRANSFER_RULE_TIME,
        API_RESERVE_WIRE_TRANSFER_RULE_AMOUNT: parseFloat(Number(action?.data?.API_RESERVE_WIRE_TRANSFER_RULE_AMOUNT) / AMOUNT_RATE) || SYSTEM_CONFIGS.API_RESERVE_WIRE_TRANSFER_RULE_AMOUNT,
        API_RESERVE_WIRE_TRANSFER_REFUND_AMOUNT: parseFloat(Number(action?.data?.API_RESERVE_WIRE_TRANSFER_REFUND_AMOUNT) / AMOUNT_RATE) || SYSTEM_CONFIGS.API_RESERVE_WIRE_TRANSFER_REFUND_AMOUNT,
        API_USER_NONUS_KYC_INQUIRY_ENABLED: action?.data?.API_USER_NONUS_KYC_INQUIRY_ENABLED,
        API_COLLATERAL_SWAP_FEE_RATE: Number(action?.data?.API_COLLATERAL_SWAP_FEE_RATE) || SYSTEM_CONFIGS.API_COLLATERAL_SWAP_FEE_RATE,
        API_COLLATERAL_SWAP_BUFFER_PRICE_RATE: Number(action?.data?.API_COLLATERAL_SWAP_BUFFER_PRICE_RATE) || SYSTEM_CONFIGS.API_COLLATERAL_SWAP_BUFFER_PRICE_RATE,
        API_COLLATERAL_SWAP_MIN_USD_AMOUNT: Number(action?.data?.API_COLLATERAL_SWAP_MIN_USD_AMOUNT) || SYSTEM_CONFIGS.API_COLLATERAL_SWAP_MIN_USD_AMOUNT,
        API_COLLATERAL_SWAP_MAX_USD_AMOUNT: Number(action?.data?.API_COLLATERAL_SWAP_MAX_USD_AMOUNT) || SYSTEM_CONFIGS.API_COLLATERAL_SWAP_MAX_USD_AMOUNT,
      };
    }
    case `${GET_SYSTEM_BADGES}_SUCCESS`: {
      const badges = {};
      camelizeKeys(action.data).forEach(item => {
        badges[item.type] = item;
      });
      return {
        ...state,
        badges,
      };
    }
    case `${GET_UNREAD_NOTIFICATIONS}_SUCCESS`: {
      return {
        ...state,
        unReadNotifications: action.data
      };
    }
    case UPDATE_DEBUG_MODE: {
      return {
        ...state,
        isDebug: action.payload
      };
    }
    case UPDATE_LOCK_REDIRECT_MODE: {
      return {
        ...state,
        isLockRedirect: action.payload
      };
    }
    case `${USER_KYC_US_TRIAL_AMOUNT}_SUCCESS`: {
      return {
        ...state,
        kycTrialBonus: action.data,
      };
    }
    case `${GET_CONTENT_VARIABLE}_SUCCESS`: {
      return {
        ...state,
        contentVariables: {
          ...action.data
        }
      };
    }
    case `GET_FLEX_COLLATERALS_SUPPORTED_SUCCESS`: {
      return {
        ...state,
        flexCollaterals: [...action.data]
      };
    }
    default:
      return state;
  }
};
