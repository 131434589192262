import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { loadableReady } from '@loadable/component';

import App from 'src/app';

TagManager.initialize({ gtmId: APP_ENV.social.googleTagmanagerId });
const container = document.getElementById('root-app');

loadableReady(() => {
  ReactDom.hydrate(
    <Router>
      <App />
    </Router>, container
  );
});

if(process.env.NODE_ENV === 'development' && typeof(module) !== 'undefined' && module.hot) {
  module.hot.accept('./app', () => {
    ReactDom.render(
      <Router>
        <App />
      </Router>, container
    );
  });
}