import makeRequest, { SERVICES } from 'src/redux/action';
import { API_ROOT, API_URL } from 'src/resources/constants/url';

const GET_CATEGORIES = 'GET_CATEGORIES';
const GET_TAGS = 'GET_TAGS';
const CREATE_TOPIC = 'CREATE_TOPIC';
const GET_CATEGORY_DETAIL = 'GET_CATEGORY_DETAIL';
const GET_TOPICS = 'GET_TOPICS';
const GET_TOPIC_DETAIL = 'GET_TOPIC_DETAIL';
const POST_REPLY = 'POST_REPLY';
const EDIT_REPLY = 'EDIT_REPLY';
const DELETE_REPLY = 'DELETE_REPLY';
const GET_REPLIES_BY_TOPIC = 'GET_REPLIES_BY_TOPIC';
const GET_REPLIES_BY_REPLY = 'GET_REPLIES_BY_REPLY';
const PIN_TOPIC = 'PIN_TOPIC';
const UNPIN_TOPIC = 'UNPIN_TOPIC';
const SAVE_TOPIC = 'SAVE_TOPIC';
const POST_TOPIC_REACTION = 'POST_TOPIC_REACTION';
const GET_TOPIC_INTERACTION_DETAILS = 'GET_TOPIC_INTERACTION_DETAILS';
const GET_REPLY_INTERACTION_DETAILS = 'GET_REPLY_INTERACTION_DETAILS';
const GET_SUB_CATEGORY_DETAIL = 'GET_SUB_CATEGORY_DETAIL';
const GET_EDIT_TOPICS = 'GET_EDIT_TOPICS';
const UPDATE_TOPIC = 'UPDATE_TOPIC';
const ACTIVE_TOPIC = 'ACTIVE_TOPIC';
const DRAFT_TOPIC = 'DRAFT_TOPIC';
const GET_TOPIC_REPLY_USERS = 'GET_TOPIC_REPLY_USERS';
const GET_USER_DETAIL = 'GET_USER_DETAIL';
const SUBSCRIBE_NOTIFY_TOPIC = 'SUBSCRIBE_NOTIFY_TOPIC';
const GET_CATEGORY_USERS = 'GET_CATEGORY_USERS';
const GET_TOPIC_COUNT_SUMMARY = 'GET_TOPIC_COUNT_SUMMARY';
const DELETE_TOPIC = 'DELETE_TOPIC';
const GET_RELATIVE_TOPICS = 'GET_RELATIVE_TOPICS';
const GET_POPULAR_TOPICS = 'GET_POPULAR_TOPICS';
const SAVE_FAVORITE_TOPIC = 'SAVE_FAVORITE_TOPIC';
const GET_FAVORITE_TOPICS = 'GET_FAVORITE_TOPICS';
const GET_SEARCH_USERS = 'GET_SEARCH_USERS';
export const SHOW_FORUM_EDITOR = 'SHOW_FORUM_EDITOR';
export const HIDE_FORUM_EDITOR = 'HIDE_FORUM_EDITOR';
export const SAVE_TOPIC_DETAIL = 'SAVE_TOPIC_DETAIL';
export const SAVE_CATEGORY_DETAIL = 'SAVE_CATEGORY_DETAIL';
export const SAVE_HEADER_DETAIL = 'SAVE_HEADER_DETAIL';
export const SAVE_RECENTLY_VISITED_THREADS = 'SAVE_RECENTLY_VISITED_THREADS';
export const SAVE_TOPICS = 'SAVE_TOPICS';
export const SAVE_TOPIC_COUNT_SUMMARY = 'SAVE_TOPIC_COUNT_SUMMARY';
export const SAVE_USER_DETAILS = 'SAVE_USER_DETAILS';
export const SAVE_SEARCH_RESULTS = 'SAVE_SEARCH_RESULTS';
export const SAVE_CATEGORY_TOPICS = 'SAVE_CATEGORY_TOPICS';
export const SAVE_CATEGORY_PINNED_TOPICS = 'SAVE_CATEGORY_PINNED_TOPICS';
export const SAVE_CATEGORY_MEMBERS = 'SAVE_CATEGORY_MEMBERS';
export const SAVE_CATEGORIES = 'SAVE_CATEGORIES';
export const SAVE_PARENT_PATH = 'SAVE_PARENT_PATH';

const showForumEditor = (thread) => ({ type: SHOW_FORUM_EDITOR, data: thread });
const hideForumEditor = () => ({ type: HIDE_FORUM_EDITOR });
const saveTopicDetail = (thread) => ({ type: SAVE_TOPIC_DETAIL, data: thread });
const saveCategoryDetail = (category) => ({ type: SAVE_CATEGORY_DETAIL, data: category });
const saveHeaderDetail = (data) => ({ type: SAVE_HEADER_DETAIL, data: data });
const saveRecentlyVisitedThreads = (threads) => ({ type: SAVE_RECENTLY_VISITED_THREADS, data: threads });
const saveTopics = (threads) => ({ type: SAVE_TOPICS, data: threads });
const saveTopicCountSummary = (summary) => ({ type: SAVE_TOPIC_COUNT_SUMMARY, data: summary });
const saveUserDetails = (user) => ({ type: SAVE_USER_DETAILS, data: user });
const saveSearchResults = (data) => ({ type: SAVE_SEARCH_RESULTS, data });
const saveCategoryTopics = (topics) => ({ type: SAVE_CATEGORY_TOPICS, data: topics });
const saveCategoryPinnedTopics = (topics) => ({ type: SAVE_CATEGORY_PINNED_TOPICS, data: topics });
const saveCategoryMembers = (members)  => ({ type: SAVE_CATEGORY_MEMBERS, data: members });
const saveCategories = (categories) => ({ type: SAVE_CATEGORIES, data: categories });
const saveParentPath = (path) => ({ type: SAVE_PARENT_PATH, data: path });

const getCategories = (params) => makeRequest({
  type: GET_CATEGORIES,
  url: `${API_ROOT}/community-api/forum/categories/`,
  service: SERVICES.ROOT,
  params
});

const getTags = () => makeRequest({
  type: GET_TAGS,
  url: `${API_ROOT}/community-api/forum/tags/`,
  service: SERVICES.ROOT,
});

const createTopic = (payload, params) => makeRequest({
  type: CREATE_TOPIC,
  url: `${API_ROOT}/community-api/forum/edit-topics/`,
  service: SERVICES.ROOT,
  method: 'POST',
  data: payload,
  params
});


const updateTopic = (topicId, payload) => makeRequest({
  type: UPDATE_TOPIC,
  url: `${API_ROOT}/community-api/forum/edit-topics/${topicId}`,
  service: SERVICES.ROOT,
  method: 'PUT',
  data: payload,
});

const getCategoryById = (categoryId) => makeRequest({
  type: GET_CATEGORY_DETAIL,
  url: `${API_ROOT}/community-api/forum/categories/`,
  service: SERVICES.ROOT,
  params: {
    parent_id: categoryId,
    all: 0
  }
});

const getSubCategoryById = (subCategoryId) => makeRequest({
  type: GET_SUB_CATEGORY_DETAIL,
  url: `${API_ROOT}/community-api/forum/categories/${subCategoryId}/`,
  service: SERVICES.ROOT,
  params: {
    all: 1
  }
});

const getTopics = (params) => makeRequest({
  type: GET_TOPICS,
  url: `${API_ROOT}/community-api/forum/topics/`,
  service: SERVICES.ROOT,
  params
});

const getEditTopics = (params) => makeRequest({
  type: GET_EDIT_TOPICS,
  url: `${API_ROOT}/community-api/forum/edit-topics/`,
  service: SERVICES.ROOT,
  params
});


const getTopicById = ({ id }) => makeRequest({
  type: GET_TOPIC_DETAIL,
  url: `${API_ROOT}/community-api/forum/topics/${id}/`,
  service: SERVICES.ROOT,
});

const postReply = (payload) => makeRequest({
  type: POST_REPLY,
  url: `${API_ROOT}/community-api/forum/edit-topic-replies/`,
  service: SERVICES.ROOT,
  method: 'POST',
  data: payload,
});

const editReply = (replyId, payload) => makeRequest({
  type: EDIT_REPLY,
  url: `${API_ROOT}/community-api/forum/edit-topic-replies/${replyId}`,
  service: SERVICES.ROOT,
  method: 'PUT',
  data: payload,
});

const deleteReply = (replyId) => makeRequest({
  type: EDIT_REPLY,
  url: `${API_ROOT}/community-api/forum/edit-topic-replies/${replyId}`,
  service: SERVICES.ROOT,
  method: 'DELETE',
});

const getRepliesByTopicId = ({ topicId, replyId, all, page, pageSize, created_at_order }) => makeRequest({
  type: GET_REPLIES_BY_TOPIC,
  url: `${API_ROOT}/community-api/forum/replies/`,
  service: SERVICES.ROOT,
  params: {
    topic: topicId,
    reply: replyId,
    all,
    page,
    page_size: pageSize,
    created_at_order
  }
});

const saveTopic = (topicId) => makeRequest({
  type: SAVE_TOPIC,
  url: `${API_ROOT}/community-api/forum/edit-topics/${topicId}/mark-save/`,
  service: SERVICES.ROOT,
  method: 'POST'
});

const postTopicReaction = (topicId, payload) => makeRequest({
  type: POST_TOPIC_REACTION,
  url: `${API_ROOT}/community-api/forum/edit-topics/${topicId}/like/`,
  service: SERVICES.ROOT,
  method: 'POST',
  data: payload
});

const pinTopic = ({ topicId }) => makeRequest({
  type: PIN_TOPIC,
  url: `${API_ROOT}/community-api/admin/topics/${topicId}/pin/`,
  service: SERVICES.ROOT,
  method: 'POST',
});

const unpinTopic = ({ topicId }) => makeRequest({
  type: UNPIN_TOPIC,
  url: `${API_ROOT}/community-api/admin/topics/${topicId}/unpin/`,
  service: SERVICES.ROOT,
  method: 'POST',
});

const getTopicInteractionDetails = (topicId) => makeRequest({
  type: GET_TOPIC_INTERACTION_DETAILS,
  url: `${API_ROOT}/community-api/forum/topics/${topicId}/interaction-details/ `,
  service: SERVICES.ROOT,
});

const postReplyReaction = (replyId, payload) => makeRequest({
  type: POST_TOPIC_REACTION,
  url: `${API_ROOT}/community-api/forum/edit-topic-replies/${replyId}/like/`,
  service: SERVICES.ROOT,
  method: 'POST',
  data: payload
});

const getReplyInteractionDetails = (replyId) => makeRequest({
  type: GET_REPLY_INTERACTION_DETAILS,
  url: `${API_ROOT}/community-api/forum/replies/${replyId}/interaction-details/ `,
  service: SERVICES.ROOT,
});

const activateTopic = (topicId) => makeRequest({
  type: ACTIVE_TOPIC,
  url: `${API_ROOT}/community-api/forum/edit-topics/${topicId}/activate/`,
  service: SERVICES.ROOT,
  method: 'POST',
});

const draftTopic = (topicId) => makeRequest({
  type: DRAFT_TOPIC,
  url: `${API_ROOT}/community-api/forum/edit-topics/${topicId}/draft/`,
  service: SERVICES.ROOT,
  method: 'POST',
});

const getTopicReplyUsers = (topicId) => makeRequest({
  type: GET_TOPIC_REPLY_USERS,
  url: `${API_ROOT}/community-api/forum/reply-users/`,
  service: SERVICES.ROOT,
  params: {
    topic: topicId
  }
});

const getUserDetail = (userId) => makeRequest({
  type: GET_USER_DETAIL,
  url: `${API_ROOT}/community-api/forum/profile/`,
  service: SERVICES.ROOT,
  params: {
    user_id: userId
  }
});

const subscribeNotifyTopic = (topicId, params) => makeRequest({
  type: SUBSCRIBE_NOTIFY_TOPIC,
  url: `${API_ROOT}/community-api/forum/edit-topics/${topicId}/subscribe/`,
  method: 'POST',
  service: SERVICES.ROOT,
  params
});

const shareTopic = (topicId, payload) => makeRequest({
  type: SUBSCRIBE_NOTIFY_TOPIC,
  url: `${API_ROOT}/community-api/forum/edit-topics/${topicId}/share/`,
  method: 'POST',
  service: SERVICES.ROOT,
  payload
});

// counterReset: 

const getCategoryUsers = (categoryId) => makeRequest({
  type: GET_CATEGORY_USERS,
  url: `${API_ROOT}/community-api/forum/topic-users/`,
  service: SERVICES.ROOT,
  params: {
    category: categoryId
  }
});

const getTopicCountSummary = () => makeRequest({
  type: GET_TOPIC_COUNT_SUMMARY,
  url: `${API_ROOT}/community-api/forum/edit-topics/summary/`,
  service: SERVICES.ROOT,
});

const deleteTopic = (topicId) => makeRequest({
  type: DELETE_TOPIC,
  url: `${API_ROOT}/community-api/forum/edit-topics/${topicId}`,
  service: SERVICES.ROOT,
  method: 'DELETE',
});

const getRelativeTopics = (topicId) => makeRequest({
  type: GET_USER_DETAIL,
  url: `${API_ROOT}/community-api/forum/related-topics/`,
  service: SERVICES.ROOT,
  params: {
    topic: topicId
  }
});
// community-api/forum/topics/?order_popular=-

const getPopularTopics = () => makeRequest({
  type: GET_POPULAR_TOPICS,
  url: `${API_ROOT}/community-api/forum/topics/`,
  service: SERVICES.ROOT,
  params: {
    order_popular: '+'
  }
});

const saveFavoriteTopic = (categoryId) => makeRequest({
  type: SAVE_FAVORITE_TOPIC,
  url: `${API_ROOT}/community-api/forum/categories-favorite/${categoryId}/favorite/`,
  service: SERVICES.ROOT,
  method: 'POST'
});

const getFavoriteTopics = () => makeRequest({
  type: GET_FAVORITE_TOPICS,
  url: `${API_ROOT}/community-api/forum/categories-favorite/`,
  service: SERVICES.ROOT,
});

const getSearchUsers = (search) => makeRequest({
  type: GET_SEARCH_USERS,
  url: `${API_ROOT}/chat-api/user/search`,
  service: SERVICES.ROOT,
  params: {
    keyword: search
  }
});

export default  {
  getCategories,
  getTags,
  getTopics,
  getCategoryById,
  createTopic,
  getTopicById,
  postReply,
  deleteReply,
  editReply,
  getRepliesByTopicId,
  pinTopic,
  unpinTopic,
  saveTopic,
  postTopicReaction,
  getTopicInteractionDetails,
  postReplyReaction,
  getReplyInteractionDetails,
  getSubCategoryById,
  getEditTopics,
  updateTopic,
  activateTopic,
  draftTopic,
  getTopicReplyUsers,
  getUserDetail,
  subscribeNotifyTopic,
  shareTopic,
  getCategoryUsers,
  getTopicCountSummary,
  deleteTopic,
  hideForumEditor,
  showForumEditor,
  saveTopicDetail,
  saveCategoryDetail,
  saveHeaderDetail,
  saveRecentlyVisitedThreads,
  getRelativeTopics,
  saveTopics,
  saveTopicCountSummary,
  getPopularTopics,
  saveUserDetails,
  saveSearchResults,
  saveCategoryTopics,
  saveCategoryPinnedTopics,
  saveCategoryMembers,
  saveCategories,
  saveParentPath,
  saveFavoriteTopic,
  getFavoriteTopics,
  getSearchUsers
};
