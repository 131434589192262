import axios from 'axios';
import { REQUEST_TIMEOUT} from 'src/resources/constants/app';
import { requestHeaderInterceptor, responseErrorInterceptor} from './utils';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'blob'
};

const instance = axios.create({
  timeout: REQUEST_TIMEOUT,
  crossDomain: true,
  responseType: 'arraybuffer',
  headers
});

// Add a request interceptor
instance.interceptors.request.use(requestHeaderInterceptor, error => Promise.reject(error));

// Add a response interceptor
instance.interceptors.response.use(
  response => {
    try {
      return response;
    } catch (e) {
      return null;
    }
  },
  responseErrorInterceptor,
);

export default instance;
