import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

/**
 * Transform Object keys to camelCase
 * @param {Object} obj
 */
export function camelizeKeys(obj) {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (!!obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
}

export function upperFirstKeys(obj) {
  if (Array.isArray(obj)) {
    return obj.map(v => upperFirstKeys(v));
  } else if (!!obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [upperFirst(key)]: upperFirstKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
}

export function camelize(value) {
  return camelCase(value);
}

export function arrayKeyValueToObject(arr) {
  if (!Array.isArray(arr))  return arr;
  const obj = {};
  arr.forEach(item => {
    obj[item.key] = item.value;
  });
  return obj;
}

/**
 * Get Object key by value
 * @param {Object} obj
 * @param {Object Value} val
 */
export function getKeyByValue(obj, val) {
  return Object.keys(obj)[Object.values(obj).indexOf(val)];
}
