import axios from 'axios';
import sha256 from 'crypto-js/sha256';
import Cookies from 'js-cookie';

import configureStore from 'src/redux/store';

const fbApiUrl = `https://graph.facebook.com/v13.0/${APP_ENV.FACEBOOK_PIXEL_ID}/events?access_token=${APP_ENV.FACEBOOK_CONVERSION_TOKEN}`;

const GENDER = {
  0: 'f',
  1: 'm',
};

export const postData = (eventName = 'PageView', needHash = true) => {
  const store = configureStore();
  const state = store.getState();
  const ipAddress = state.app?.ipInfo?.ip_address || '';
  const country = state.app?.ipInfo?.country_code?.toLowerCase() || '';
  const city = state.app?.ipInfo?.city || '';
  const userId = state.auth?.profile?.id || '';
  const email = state.auth?.profile?.email || '';
  const phone = state.auth?.profile?.phoneNumber || '';
  const dob = state.auth?.profile?.dob?.replace(/\D/g, '') || '';
  const firstName = state.auth?.profile?.firstName || '';
  const lastName = state.auth?.profile?.lastName || '';
  const gender = GENDER[state.auth?.gender] || '';
  const postCode = state.auth?.profile?.addressPostalCode || '';

  const userData = {
    client_user_agent: window.navigator.userAgent,
    client_ip_address: ipAddress,
  };

  if (userId) userData.external_id = needHash ? sha256(userId).toString() : userId;
  if (email) userData.em = needHash ? sha256(email).toString() : email;
  if (phone) userData.ph = needHash ? sha256(phone).toString() : phone;
  if (country) userData.country = needHash ? sha256(country).toString() : country;
  if (city) userData.ct = needHash ? sha256(city).toString() : city;
  if (dob) userData.dob = needHash ? sha256(dob).toString() : dob;
  if (firstName) userData.fn = needHash ? sha256(firstName).toString() : firstName;
  if (lastName) userData.ln = needHash ? sha256(lastName).toString() : lastName;
  if (gender) userData.ge = needHash ? sha256(gender).toString() : gender;
  if (postCode) userData.zp = needHash ? sha256(postCode).toString() : postCode;
  if (Cookies.get('_fbp')) userData.fbp = Cookies.get('_fbp');
  if (Cookies.get('_fbc')) userData.fbc = Cookies.get('_fbc');

  return {
    data: [{
      event_name: eventName,
      event_time: Math.floor(Date.now() / 1000),
      action_source: 'website',
      event_source_url: window.location.href,
      user_data: userData,
    }],
  };
};

export const sendFbViewEvent = async () => {
  await axios.post(fbApiUrl, postData());
};

export const sendFbCompleteRegistrationEvent = async () => {
  await axios.post(fbApiUrl, postData('CompleteRegistration'));
};
