import { makeRequest } from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';
import { camelizeKeys } from 'src/utils/object';

export const getAllFlexCollaterals = (params) => makeRequest({
  type: 'GET_FLEX_COLLATERALS_SUPPORTED',
  url: `${API_URL.LOAN.GET_ALL_COLLATERALS}?${params}`,
  normalize: res => camelizeKeys(res)
});

export const genCollateralAddress = (params) => makeRequest({
  url: `${API_URL.PRO_LENDING.COLLATERAL_ADDRESS}?${params}`,
  method: 'POST',
  normalize: res => camelizeKeys(res)
});

export const getCollateralBalance = (id) => makeRequest({
  url: `${API_URL.PRO_LENDING.COLLATERAL_BALANCE}/${id}`,
  normalize: res => camelizeKeys(res)
});
