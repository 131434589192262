import BigNumber from 'bignumber.js';

export function formatAmount(value, fractionDigits = 2) {
  const amount = BigNumber(value).dividedBy(Math.pow(10, 2));
  return amount.toFixed(fractionDigits);
}

export function formatAmountDecimal(value, decimal = 2) {
  const amount = new BigNumber(value);
  return amount.decimalPlaces(decimal).toString();
}

export function formatCollateralAmount(value, fractionDigits = 4, roundingMode = BigNumber.ROUND_UP) {
  //return ((value) / Math.pow(10, 8)).toFixed(fractionDigits);
  return new BigNumber(value).dividedBy(Math.pow(10, 8)).toFixed(fractionDigits, roundingMode);
}

export function formatCollateralAmountDisplay(value, fractionDigits = 4, roundingMode = BigNumber.ROUND_UP) {
  if (isNaN(value)) return 0;
  return new BigNumber(((value) / Math.pow(10, 8))).toFormat(fractionDigits, roundingMode);
}

export function formatCollateralDisplayShorten(value, options = {}) {
  if (isNaN(value)) return 0;
  const { decimals, roundingMode = BigNumber.ROUND_DOWN } = options || {};
  const baseValue = new BigNumber(((value) / Math.pow(10, 8)));
  let floatDigit = decimals ? decimals : baseValue.isLessThan(100) ? 8 : 4;
  return baseValue.toFormat(floatDigit, roundingMode).replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1');
}

export function formatCollateralToServer(value) {
  return Number(new BigNumber((value) * Math.pow(10, 8)).toFixed(0));
}

export function formatAmountToServer(value, currency = 'USD') {
  if(currency.toUpperCase() === 'VND') {
    return parseInt(Number(value));
  }
  return parseInt((Number(value) * Math.pow(10, 2)), 10);
}

export function formatAmountFromClient(value) {
  return new BigNumber(value).dividedBy(Math.pow(10, 2)).toNumber();
}

export function formatNumber(value) {
  return BigNumber(value / 100).toFixed();
}

export function convertString2Number(value) {
  return Number(String(value).replace(/[^.\d]/g, ''));
}

export function convertFrom10e8(value) {
  if (isNaN(value)) return 0;
  return new BigNumber(value).dividedBy(Math.pow(10, 8)).toNumber();
}

export function convertTo10e8(value) {
  if (isNaN(value)) return 0;
  return new BigNumber(value).multipliedBy(Math.pow(10, 8)).toNumber();
}

export function convertFrom10e2(value) {
  if (isNaN(value)) return 0;
  return new BigNumber(value).dividedBy(Math.pow(10, 2)).toNumber();
}

export function convertTo10e2(value) {
  if (isNaN(value)) return 0;
  return new BigNumber(value).multipliedBy(Math.pow(10, 2)).toNumber();
}