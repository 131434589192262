import {API_URL} from 'src/resources/constants/url';
import currentUser from 'src/utils/authentication';
import http from 'src/utils/http';

export const sendEmailVerifyCode = async data => {
  try {
    const options = {
      url: API_URL.ME.VERIFY_EMAIL,
      method: 'POST',
      data,
    };
    if (currentUser.isLogin()) {
      options.headers = { Authorization: 'Bearer ' + currentUser.getToken() };
    }

    const res = await http(options);
    return res;
  } catch (err) {
    console.log('ERROR sendEmailVerifyCode', err);
    throw err;
  }
};

export const sendEmailConfirm = async data => {
  try {
    const options = {
      url: `${API_URL.ME.EMAIL_CONFIRM_RESERVE}/?id=${data.id}&token=${data.token}`,
      method: 'POST',
      data,
    };
    if (currentUser.isLogin()) {
      options.headers = { Authorization: 'Bearer ' + currentUser.getToken() };
    }

    const res = await http(options);
    return res;
  } catch (err) {
    console.log('ERROR sendEmailConfirm', err);
    throw err;
  }
};

export const confirmCollateralWithdraw = async data => {
  try {
    const options = {
      url: `${API_URL.ME.CONFIRM_COLLATERAL_CRYPTO_WITHDRAW}`,
      method: 'POST',
      data,
    };
    if (currentUser.isLogin()) {
      options.headers = { Authorization: 'Bearer ' + currentUser.getToken() };
    }

    const res = await http(options);
    return res;
  } catch (err) {
    console.log('ERROR confirmCollateralWithdraw', err);
    throw err;
  }
};

export const updateProfile = async data => {
  try {
    const options = {
      url: API_URL.ME.PROFILE,
      method: 'PATCH',
      data,
    };
    if (currentUser.isLogin()) {
      options.headers = { Authorization: 'Bearer ' + currentUser.getToken() };
    }
    const res = await http(options);
    return res;
  } catch (err) {
    console.log('ERROR updateProfile', err);
    return null;
  }
};

export const changePassword = async (data, captcha) => {
  try {
    const options = {
      url: API_URL.ME.CHANGE_PASSWORD,
      method: 'POST',
      data,
    };
    if (currentUser.isLogin()) {
      options.headers = { Authorization: 'Bearer ' + currentUser.getToken(), recaptcha: captcha };
    }

    const res = await http(options);

    return res;
  } catch (err) {
    console.log('ERROR changePassword', err);
    throw err;
  }
};

// /api/user/two-fa/
export const getTwoFactorCode = async () => {
  try {
    const options = {
      url: API_URL.ME.TWO_FACTOR,
      method: 'GET',
    };
    if (currentUser.isLogin()) {
      options.headers = { Authorization: 'Bearer ' + currentUser.getToken() };
    }
    const res = await http(options);
    return res;
  } catch (err) {
    console.log('ERROR getTwoFactorCode', err);
    throw err;
  }
};

// /api/user/two-fa/
export const sendTwoFactorCode = async ({code, token, type}) => {
  try {
    const options = {
      url: API_URL.ME.TWO_FACTOR,
      method: 'POST',
    };
    if (currentUser.isLogin()) {
      options.headers = {
        Authorization: 'Bearer ' + currentUser.getToken(),
      };
      options.data = {
        OTP: code,
        Token: token,
        Type: type,
      };
    }
    const res = await http(options);
    return res;
  } catch (err) {
    console.log('ERROR sendTwoFactorCode', err);
    throw err;
  }
};

// /api/user/two-fa/
export const disableTwoFactorCode = async ({code, token, type}) => {
  try {
    const options = {
      url: API_URL.ME.TWO_FACTOR,
      method: 'DELETE',
    };
    if (currentUser.isLogin()) {
      options.headers = {
        Authorization: 'Bearer ' + currentUser.getToken(),
      };
      options.data = {
        OTP: code,
        Token: token,
        Type: type,
      };
    }
    const res = await http(options);
    return res;
  } catch (err) {
    console.log('ERROR disableTwoFactorCode', err);
    throw err;
  }
};

export const getLanguages = async () => {
  try {
    const options = {
      url: API_URL.SYSTEM.GET_LANGUAGES,
      method: 'GET',
    };
    if (currentUser.isLogin()) {
      options.headers = {
        Authorization: 'Bearer ' + currentUser.getToken(),
      };
    }
    const res = await http(options);
    return res;
  } catch (err) {
    console.log('ERROR getLanguages', err);
    return [];
  }
};

export const uploadFile = async (file, type = 'public', accessToken) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    if (type) {
      formData.append('type', type);
    }
    const token = currentUser.getToken() || accessToken;
    const options = {
      url: API_URL.ME.FILE_UPLOAD,
      method: 'POST',
      data: formData,
      timeout: 60000
    };
    if (token) {
      options.headers = {
        Authorization: 'Bearer ' + token,
        'Content-type': 'multipart/form-data',
      };
    }
    return await http(options);
  } catch (err) {
    console.error('Failed to upload ', type, file, err);
    throw err;
    // return false;
  }
};

export const logRuntimeError = async (error) => {
  console.log('logRuntimeError', error);
  // const state = store.getState();
  // const userId = state?.auth?.profile?.id;
  // if (APP_ENV.isProduction) {
  //   const blogApi = new ConstantApi('api/slack/request');
  //   blogApi.post({
  //     url: 'https://hooks.slack.com/services/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',
  //     data: {
  //       text: `UserId: ${userId}; ${error}`
  //     }
  //   }).then(r => r).catch(err => err);
  // }
};
