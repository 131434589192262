import BigNumber from 'bignumber.js';

const PRICE_DECIMAL = 2;

export function formatMoneyByLocale(price = 0, locale = 'USD', decimalNumber = 0) {
  switch (locale.toLowerCase()) {
    case 'vnd':
      return new BigNumber(price).decimalPlaces(0).toFormat(decimalNumber);
    default:
      return new BigNumber(price).toFormat(PRICE_DECIMAL, BigNumber.ROUND_DOWN).replace('.00', '');
  }
}

export function formatCurrencyByLocale(value = 0,  decimalNumber = 2, roundingMode = BigNumber.ROUND_DOWN) {
  if (isNaN(value)) return 0;
  return new BigNumber(value).decimalPlaces(decimalNumber, roundingMode).toFormat(decimalNumber);
}

export function displayCurrencyByLocale(value = 0,  decimalNumber = 2) {
  return new BigNumber(value).decimalPlaces(decimalNumber).toFormat(decimalNumber).replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1');
}

export function roundNumberByLocale(price = 0, locale = 'USD', decimalNumber = 0) {
  switch (locale.toLowerCase()) {
    case 'vnd':
      return new BigNumber(price).decimalPlaces(0).toFormat(decimalNumber);
    default:
      return new BigNumber(price).decimalPlaces(PRICE_DECIMAL).toFormat(PRICE_DECIMAL).replace('.00', '');
  }
}

export function formatDecimal(number = 0, options = {}) {
  return new Intl.NumberFormat('en-US', options).format(Number(number) || 0);
}

export const formatMoneyShorten = (amount) => {
  const m = new BigNumber(amount).dividedBy(Math.pow(10, 6)).toNumber();
  const k = new BigNumber(amount).dividedBy(Math.pow(10, 3)).toNumber();

  if (m >= 1) {
    return `${formatMoneyByLocale(m)} M`;
  } else if (k >= 1) {
    return `${formatMoneyByLocale(k)} K`;
  }

  return formatMoneyByLocale(amount);
};

export function formatNumberLimitLength(number, maxLength = 8) {
  const str = String(number);
  if (str.includes('.')) {
    const d = str.split('.')[0];
    const f = str.split('.')[1];
    const result =  `${d}${d.length < maxLength ? '.' + f.slice(0, maxLength - d.length) : ''}`;
    return Number(result.replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1'));
  }
  return number;
}

export function formatCryptoPrice(price, options = {}) {
  if (!price) return '';
  const { shorten = true } = options;
  let ans = formatCurrencyByLocale(price, 8);
  if (shorten) ans = ans.replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1');
  return ans;
}

export const formatAmount = (value) => {
  return new BigNumber(value).dividedBy(100).toFixed();
};