import React from 'react';
import loadable from '@loadable/component';

import { URL } from 'src/resources/constants/url';
import LoadingSkeleton, { LoadingBadgeSkeleton, LoadingHomeSkeleton } from 'src/components/loadingSkeleton';

const AsyncWebView = loadable(
  () => import(/*webpackChunkName: "webview"*/ '../screens/webview'),
);

const AsyncNewHome = loadable(
  () => import(/*webpackChunkName: "newHome"*/ '../screens/newHome'),
  { fallback: <LoadingHomeSkeleton /> },
);

const AsyncConstantLoan = loadable(
  () => import(/*webpackChunkName: "borrow"*/ '../screens/constantLoan'),
);

const AsyncBorrowConfirm = loadable(
  () => import(/*webpackChunkName: "borrow"*/ '../screens/borrowConfirm'),
);

const AsyncWithdraw = loadable(
  () => import(/*webpackChunkName: "withdraw"*/ '../screens/withdrawFiat'),
);

const AsyncMemberShip = loadable(
  () => import(/*webpackChunkName: "membership"*/ '../screens/memberShip'),
);

const AsyncMemberShipMCT = loadable(
  () => import(/*webpackChunkName: "membershipMCT"*/ '../screens/membershipMCT'),
);

const AsyncTransferConstant = loadable(
  () => import(/*webpackChunkName: "transferConstant"*/ '../screens/transferConstant'),
);

const AsyncTransferCrypto = loadable(
  () => import(/*webpackChunkName: "transferCrypto"*/ '../screens/transferCrypto'),
);

const AsyncDepositFiat = loadable(
  () => import(/*webpackChunkName: "depositFiat"*/ '../screens/depositFiat'),
);

const AsyncDepositFiatPlaidConfirm = loadable(
  () => import(/*webpackChunkName: "AsyncDepositFiatPlaidConfirm"*/ '../screens/depositFiat/confirmUrlPlaid'),
);

const AsyncDepositCrypto = loadable(
  () => import(/*webpackChunkName: "depositCrypto"*/ '../screens/depositCrypto'),
);
const AsyncCryptoLend = loadable(
  () => import(/*webpackChunkName: "cryptoLend"*/ '../screens/cryptoLend'),
);
const AsyncCryptoLendSignUp = loadable(
  () => import(/*webpackChunkName: "AsyncCryptoLendSignUp"*/ '../screens/cryptoLend/signup'),
);

const AsyncCryptoLendToEarn = loadable(
  () => import(/*webpackChunkName: "AsyncCryptoLendToEarn"*/ '../screens/lendingToEarn'),
);

const AsyncDepositConfirmation = loadable(
  () => import(/*webpackChunkName: "deposit_confirmation"*/ '../screens/depositFiat/confirmation'),
);

const AsyncAboutUs = loadable(
  () => import(/*webpackChunkName: "ourStory"*/ '../screens/ourStory'),
);
const AsyncLogin = loadable(
  () => import(/*webpackChunkName: "login"*/ '../screens/login'),
);
const AsyncLoginMoneyMade = loadable(
  () => import(/*webpackChunkName: "loginMoneyMade"*/ '../screens/login/moneymade'),
);
const AsyncRegister = loadable(
  () => import(/*webpackChunkName: "register"*/ '../screens/register'),
);
const AsyncRegisterBorrow = loadable(
  () => import(/*webpackChunkName: "signupborrow"*/ '../screens/affiliates/registerDark'),
);
const AsyncForgetPassword = loadable(
  () => import(/*webpackChunkName: "forgetPassword"*/ '../screens/forgetPassword'),
);
const AsyncForgetPasswordFinish = loadable(
  () => import(/*webpackChunkName: "forgetPassword_finish"*/ '../screens/forgetPassword/finish'),
);

const AsyncActivateAccount = loadable(
  () => import(/*webpackChunkName: "activateAccount"*/ '../screens/activateAccount'),
);

const AsyncThankYou = loadable(
  () => import(/*webpackChunkName: "thankYou"*/ '../screens/thankYou'),
);

const AsyncUnsubscribeEmail = loadable(
  () => import(/*webpackChunkName: "unsubscribeEmail"*/ '../screens/unsubscribeEmail'),
);

const AsyncEmailConfirm = loadable(
  () => import(/*webpackChunkName: "emailConfirm"*/ '../screens/emailConfirm'),
);

const AsyncEmailConfirmCryptoWithdrawal = loadable(
  () => import(/*webpackChunkName: "emailConfirmCryptoWithdrawal"*/ '../screens/emailConfirmCryptoWithdrawal'),
);

const AsyncCallUs = loadable(
  () => import(/*webpackChunkName: "ourStory"*/ '../screens/callUs'),
);

const AsyncMe = loadable(
  () => import(/*webpackChunkName: "me"*/ '../screens/me/pages/Me'),
);
const AsyncMeSupport = loadable(
  () => import(/*webpackChunkName: "BankInfo"*/ '../screens/me/pages/support'),
);
const AsyncGetStarted = loadable(
  () => import(/*webpackChunkName: "BankInfo"*/ '../screens/me/pages/getStarted'),
  { ssr: false },
);
const AsyncAccountInfo = loadable(
  () => import(/*webpackChunkName: "AccountInfo"*/ '../screens/me/pages/AccountInfo'),
);
const AsyncNotificationSetting = loadable(
  () => import(/*webpackChunkName: "NotificationSettings"*/ '../screens/me/pages/NotificationSettings'),
);
const AsyncUpdatePassword = loadable(
  () => import(/*webpackChunkName: "NotificationSettings"*/ '../screens/updatePassword'),
);
const AsyncAccountSecurity = loadable(
  () => import(/*webpackChunkName: "Security"*/ '../screens/me/pages/Security'),
);
const AccountActivities = loadable(
  () => import(/*webpackChunkName: "Security"*/ '../screens/me/pages/AccountActivities'),
);
const AddressBook = loadable(
  () => import(/*webpackChunkName: "Security"*/ '../screens/me/pages/AddressBook'),
);
const AsyncAccountSecurity2FA = loadable(
  () => import(/*webpackChunkName: "TwoFactor"*/ '../screens/me/pages/Security/TwoFactor/onOff'),
);
const AsyncAccountSecuritySms = loadable(
  () => import(/*webpackChunkName: "smsAuthentication"*/ '../screens/me/pages/Security/smsAuthentication/onOff'),
);

const AsyncBalanceHistory = loadable(
  () => import(/*webpackChunkName: "History_TransferTabs"*/ '../screens/me/pages/History/TransferTabs'),
  { ssr: false },
);
const AsyncHistoryDeposit = loadable(
  () => import(/*webpackChunkName: "History_DepositsTabs"*/ '../screens/me/pages/History/DepositsTabs'),
  { ssr: false },
);
const AsyncoOverView = loadable(
  () => import(/*webpackChunkName: "History_DepositsTabs"*/ '../screens/me/pages/History/Overview'),
  { ssr: false },
);
const AsyncHistoryWithDraw = loadable(
  () => import(/*webpackChunkName: "History_WithdrawsTabs"*/ '../screens/me/pages/History/WithdrawsTabs'),
  { ssr: false },
);
const AsyncHistoryInvestment = loadable(
  () => import(/*webpackChunkName: "History_Investments"*/ '../screens/me/pages/History/Investments'),
  { ssr: false },
);
const AsyncBatchTransfer = loadable(
  () => import(/*webpackChunkName: "batchtransfer"*/ '../screens/me/pages/batchtransfer'),
);
const AsyncBatchTransferDetail = loadable(
  () => import(/*webpackChunkName: "batchtransfer_detail"*/ '../screens/me/pages/batchtransfer/detail'),
  { ssr: false },
);
const AsyncOpenOrder = loadable(
  () => import(/*webpackChunkName: "History_OpenOrder"*/ '../screens/me/pages/History/OpenOrder'),
  { ssr: false },
);
const AsyncInterestHistory = loadable(
  () => import(/*webpackChunkName: "History_Interest"*/ '../screens/me/pages/History/interestTabs'),
  { ssr: false },
);

const AsyncLendingLotteryHistory = loadable(
  () => import(/*webpackChunkName: "Lending_Lottery_History"*/ '../screens/me/pages/History/LendingLotteryTabs'),
);

const AsyncNftRewards = loadable(
  () => import(/*webpackChunkName: "Nft_Rewards"*/ '../screens/me/pages/History/NftRewardsTabs'),
);

const AsyncNftRewardsDetail = loadable(
  () => import(/*webpackChunkName: "Nft_Rewards_Detail"*/ '../screens/me/pages/History/NftRewardsTabs/rewardItemDetail'), { ssr: false }
);

const AsyncNftCollection = loadable(
  () => import(/*webpackChunkName: "Nft_Rewards"*/ '../screens/me/pages/History/MascotNFTTabs'),
);

const AsyncCryptoSwapHistory = loadable(
  () => import(/*webpackChunkName: "Crypto_swap_history"*/ '../screens/me/pages/History/cryptoSwapTabs'),
);

const AsyncMCTEarningHistory = loadable(
  () => import(/*webpackChunkName: "MCT_earning_history"*/ '../screens/me/pages/History/mctEarning'),
);

const AsyncMCTStakingHistory = loadable(
  () => import(/*webpackChunkName: "MCT_earning_history"*/ '../screens/me/pages/History/mctStaking'),
);

const AsyncMCTPrizewheelHistory = loadable(
  () => import(/*webpackChunkName: "mctPrizeWheel"*/ '../screens/me/pages/History/mctPrizeWheel'),
);

const AsyncInvest = loadable(
  () => import(/*webpackChunkName: "invest"*/ '../screens/invest'),
);

const AsyncInvestConfirmation = loadable(
  () => import(/*webpackChunkName: "invest"*/ '../screens/depositConfirmation'),
  { ssr: false },
);
const AsyncYourAccount = loadable(
  () => import(/*webpackChunkName: "yourAccounts"*/ '../screens/yourAccounts'),
);

const AccountHistory = loadable(
  () => import(/*webpackChunkName: "yourAccountsMobile"*/ 'src/screens/yourAccounts/mobile'),
);
const AccountHistoryDetail = loadable(
  () => import(/*webpackChunkName: "yourAccountsMobileDetail"*/ 'src/screens/yourAccounts/mobile/detail'),
);

const AsyncCollateralBalance = loadable(
  () => import(/*webpackChunkName: "yourAccounts_balances"*/ '../screens/yourAccounts/balances'),
);

// const AsyncProLending = loadable(
//   () => import(/*webpackChunkName: "prolending"*/ '../screens/prolending'),
// );

const AsyncSecondaryMarket = loadable(
  () => import(/*webpackChunkName: "secondaryMarket"*/ '../screens/secondaryMarket'),
);

const AsyncReferral = loadable(
  () => import(/*webpackChunkName: "referral"*/ '../screens/referral'),
);
const AsyncPricingAndServiceTime = loadable(
  () => import(/*webpackChunkName: "pricingAndServiceTime"*/ '../screens/pricingAndServiceTime'),
);
const AsyncAffiliates = loadable(
  () => import(/*webpackChunkName: "AsyncAffiliates"*/ '../screens/affiliates'),
);
const AsyncAffiliateAssociationRegister = loadable(
  () => import(/*webpackChunkName: "affiliatesRegister"*/ '../screens/affiliates/register'),
);
const AsyncTestimonials = loadable(
  () => import(/*webpackChunkName: "whyPeopleLoveUs"*/ '../screens/whyPeopleLoveUs'),
);

const AsyncAgreements = loadable(
  () => import(/*webpackChunkName: "agreements"*/ '../screens/agreements'),
);
const AsyncNotifications = loadable(
  () => import(/*webpackChunkName: "notifications"*/ '../screens/notifications'),
);

const AsyncNotificationDetail = loadable(
  () => import(/*webpackChunkName: "notificationDetail"*/ '../screens/notifications/detail'),
);

const AsyncLendingLottery = loadable(
  () => import(/*webpackChunkName: "lendingLottery"*/ '../screens/lendingLottery'),
);

const AsyncLendingLoterryPickLottery = loadable(
  () => import(/*webpackChunkName: "lendingLotteryPickLottery"*/ '../screens/lendingLottery/pickLottery'),
);

const AsyncLendingLotteryLandingPages = loadable(
  () => import(/*webpackChunkName: "lendingLotteryLanding"*/ '../screens/lendingLottery/landing'),
);

const AsyncLendingLotteryDrawNumber = loadable(
  () => import(/*webpackChunkName: "lendingLotteryPastHistoryLandingPages"*/ '../screens/lendingLottery/drawNumber'),
);

const HelpAndSupport = loadable(
  () => import(/*webpackChunkName: "helpAndSupport"*/ '../screens/helpAndSupport'),
);

const HelpAndSupportDetails = loadable(
  () => import(/*webpackChunkName: "HelpAndSupportDetails"*/ '../screens/helpAndSupportDetail'),
);

const Announcement = loadable(
  () => import(/*webpackChunkName: "Announcement"*/ '../screens/announcement'),
);

const QandA = loadable(
  () => import(/*webpackChunkName: "QandA"*/ '../screens/announcement/QandA'),
  { ssr: false }
);

const DiscussionForum = loadable(
  () => import(/*webpackChunkName: "DiscussionForum"*/ '../screens/announcement/discussionForum'),
  { ssr: false }
);

// const LoanOriginators = loadable(
//   () => import(/*webpackChunkName: "LoanOriginators"*/ '../screens/loanOriginators/landing'),
// );

// const LOConstantRating = loadable(
//   () => import(/*webpackChunkName: "LOConstantRating"*/ '../screens/loanOriginators/constantRating'),
// );

// const LOAutoInvest = loadable(
//   () => import(/*webpackChunkName: "LOAutoInvest"*/ '../screens/loanOriginators/auto'),
// );
// const LOAutoInvestDetail = loadable(
//   () => import(/*webpackChunkName: "LOAutoInvestDetail"*/ '../screens/loanOriginators/auto/detail'),
// );

// const LOManualInvest = loadable(
//   () => import(/*webpackChunkName: "LOManualInvest"*/ '../screens/loanOriginators/manual'),
// );
// const LOManualInvestDetail = loadable(
//   () => import(/*webpackChunkName: "LOManualInvestDetail"*/ '../screens/loanOriginators/manual/detail'),
// );

// const LOManualInvestConfirm = loadable(
//   () => import(/*webpackChunkName: "LOManualInvestConfirm"*/ '../screens/loanOriginators/manual/manualForm/confirmForm'),
// );

// const LOLoanOriginator = loadable(
//   () => import(/*webpackChunkName: "LOLoanOriginator"*/ '../screens/loanOriginators/lo'),
// );

// const LOLoanOriginatorDetail = loadable(
//   () => import(/*webpackChunkName: "LOLoanOriginatorDetail"*/ '../screens/loanOriginators/lo/detail'),
// );

const LOSecurityRisk = loadable(
  () => import(/*webpackChunkName: "LOSecurityRisk"*/ '../screens/loanOriginators/securityAndRisk/risk'),
);

const LOOnlineSecurity = loadable(
  () => import(/*webpackChunkName: "LOOnlineSecurity"*/ '../screens/loanOriginators/securityAndRisk/onlineSecurity'),
);

const AsyncLoanDisbursement = loadable(
  () => import(/*webpackChunkName: "loanDisbursementDetail"*/ '../screens/loanDisbursement'),
);
const AsyncLoanPayment = loadable(
  () => import(/*webpackChunkName: "loanPaymentDetail"*/ '../screens/loanPayment'),
);

const AsyncBadges = loadable(
  () => import(/*webpackChunkName: "badges"*/ '../screens/badges'),
  { fallback: <LoadingBadgeSkeleton /> },
);

const AsyncWithdrawFee = loadable(
  () => import(/*webpackChunkName: "badges"*/ '../screens/withdrawFee'),
);

const AsyncStatistic = loadable(
  () => import(/*webpackChunkName: "statistic"*/ '../screens/statistic'),
);

const AsyncWithdrawCryptoDetail = loadable(
  () => import(/*webpackChunkName: "withdrawCollateralDetail"*/ '../screens/withdrawCryptoDetail'),
);

const AsyncLiveChat = loadable(
  () => import(/*webpackChunkName: "liveChat"*/ '../screens/liveChat'),
);

const AsyncRegisterAccreditation = loadable(
  () => import(/*webpackChunkName: "registerAccreditation"*/ '../screens/registerAccreditation'),
);

const AsyncCryptoShort = loadable(
  () => import(/*webpackChunkName: "cryptoShort"*/ '../screens/cryptoShort'),
);

const AsyncCryptoFuture = loadable(
  () => import(/*webpackChunkName: "cryptoFuture"*/ '../screens/cryptoFuture'),
);

const AsyncForum = loadable(
  () => import(/*webpackChunkName: "forum"*/ '../screens/forum'),
);

const AsyncPersonalBudgetLanding = loadable(
  () => import(/*webpackChunkName: "personalBudget"*/ '../screens/personalBudget/landing'),
  { fallback: <LoadingSkeleton /> },
);

const AsyncPersonalBudget = loadable(
  () => import(/*webpackChunkName: "personalBudget"*/ '../screens/personalBudget'),
  { fallback: <LoadingSkeleton /> },
);

const AsyncPersonalBudgetDashboard = loadable(
  () => import(/*webpackChunkName: "personalBudget"*/ '../screens/personalBudget/dashboard'),
  { fallback: <LoadingSkeleton /> },
);

// const AsyncGiftCards = loadable(
//   () => import(/*webpackChunkName: "statistic"*/ '../screens/oldGiftCards'),
//   { fallback: <LoadingSkeleton /> },
// );

// const AsyncGiftCardDetail = loadable(
//   () => import(/*webpackChunkName: "statistic"*/ '../screens/giftCards/detail'),
//   { fallback: <LoadingSkeleton /> },
// );

// const AsyncGiftCardConfirm = loadable(
//   () => import(/*webpackChunkName: "statistic"*/ '../screens/giftCards/confirm'),
//   { fallback: <LoadingSkeleton /> },
// );

const AsyncPersonalBudgetTransactions = loadable(
  () => import(/*webpackChunkName: "personalBudget"*/ '../screens/personalBudget/transactions'),
  { fallback: <LoadingSkeleton /> },
);

const AsyncPersonalBudgetLinkBanks = loadable(
  () => import(/*webpackChunkName: "personalBudget"*/ '../screens/personalBudget/linkBanks'),
  { fallback: <LoadingSkeleton /> },
);
const AsyncPersonalBudgetBudgets = loadable(
  () => import(/*webpackChunkName: "personalBudget"*/ '../screens/personalBudget/budgets'),
  { fallback: <LoadingSkeleton /> },
);

const AsyncShortSimple = loadable(
  () => import(/*webpackChunkName: "cryptoShort"*/ '../screens/shortSimple'),
);

const AsyncSubmitTestimonial = loadable(
  () => import(/*webpackChunkName: "submitTestimonial"*/ '../screens/submitTestimonial'),
);

const AsyncInstitutionalLoans = loadable(
  () => import(/*webpackChunkName: "institutionalLoans"*/ '../screens/institutionalLoans'),
);

const AsyncPoFinancingLoans = loadable(
  () => import(/*webpackChunkName: "AsyncPoFinancingLoans"*/ '../screens/poFinancingLoans'),
);

const AsyncDebitCard = loadable(
  () => import(/*webpackChunkName: "debitCard"*/ '../screens/debitCard'),
);

const AsyncCreditPayment = loadable(
  () => import(/*webpackChunkName: "credits"*/ '../screens/debitCard/credits'),
);

const AsyncDebitCardTransactions = loadable(
  () => import(/*webpackChunkName: "transactions"*/ '../screens/debitCard/transactions'),
);

const AsyncDebitCardStatement = loadable(
  () => import(/*webpackChunkName: "statments"*/ '../screens/debitCard/statements'),
);

const AsyncDebitCardPhysical = loadable(
  () => import(/*webpackChunkName: "physicalCard"*/ '../screens/debitCard/physicalCard'),
);

const AsyncDebitCardReward = loadable(
  () => import(/*webpackChunkName: "debitCardReward"*/ '../screens/debitCard/reward'),
);

const AsyncDebitCardLanding = loadable(
  () => import(/*webpackChunkName: "debitCardLanding"*/ '../screens/debitCard/landing'),
);

const AsyncCryptoSwap = loadable(
  () => import(/*webpackChunkName: "cryptoSwap"*/ '../screens/cryptoSwap'),
);

const AsyncCryptoSwapConfirm = loadable(
  () => import(/*webpackChunkName: "cryptoSwap"*/ '../screens/cryptoSwap/confirm'),
);

const AsyncForumChat = loadable(
  () => import(/*webpackChunkName: "forumChat"*/ '../screens/forumChat'),
);

const AsyncForumDashboard = loadable(
  () => import(/*webpackChunkName: "Forum.Dashboard"*/ '../screens/forumChat/topic/Forum.AllTopic.List'),
);

const AsyncForumCategory = loadable(
  () => import(/*webpackChunkName: "Forum.Category.Detail"*/ '../screens/forumChat/category/Forum.Category.Detail'),
);

const AsyncForumTopic = loadable(
  () => import(/*webpackChunkName: "Forum.Topic.Detail"*/ '../screens/forumChat/topic/Forum.Topic.Detail'),
);

const AsyncForumSavedTopic = loadable(
  () => import(/*webpackChunkName: "Forum.SavedTopic.List"*/ '../screens/forumChat/yourTopic/Forum.SavedTopic.List'),
);

const AsyncForumDraftTopic = loadable(
  () => import(/*webpackChunkName: "Forum.DraftTopic.List"*/ '../screens/forumChat/yourTopic/Forum.DraftTopic.List'),
);

const AsyncForumRecentTopic = loadable(
  () => import(/*webpackChunkName: "Forum.RecentTopic.List"*/ '../screens/forumChat/yourTopic/Forum.RecentTopic.List'),
);

const AsyncForumRules = loadable(
  () => import(/*webpackChunkName: "Forum.Rules"*/ '../screens/forumChat/Forum.Rules'),
);


const AsyncForumMyTopic = loadable(
  () => import(/*webpackChunkName: "Forum.MyTopic.List"*/ '../screens/forumChat/yourTopic/Forum.MyTopic.List'),
);

const AsyncForumSheduleTopicList = loadable(
  () => import(/*webpackChunkName: "Forum.SheduleTopic.List"*/ '../screens/forumChat/yourTopic/Forum.SheduleTopic.List'),
);

const AsyncAuthDiscordCallback = loadable(
  () => import(/*webpackChunkName: "Forum.MyTopic.List"*/ '../screens/login/components/discordLogin/callback.js'),
);

const AsyncAuthPlaidCallback = loadable(
  () => import(/*webpackChunkName: "AsyncAuthPlaidCallback"*/ '../screens/me/pages/BankInfo/plaidLinkWithOAuth'),
  { ssr: false },
);

const AsyncConstantToken = loadable(
  () => import(/*webpackChunkName: "AsyncConstantToken"*/ '../screens/constantToken'),
);

const AsyncMCTAirdrop = loadable(
  () => import(/*webpackChunkName: "MCT_Airdrop"*/ '../screens/me/pages/History/MCTAirdrop'),
);

const AsyncMCTPrizeWheel = loadable(
  () => import(/*webpackChunkName: "MCT_PrizeWheel"*/ '../screens/mctPrizeWheel'),
);

const AsyncPrizeWheelResultLandingPages = loadable(
  () => import(/*webpackChunkName: "prizeWheelResult"*/ '../screens/prizeWheel/result'),
);

const AsyncManageLoans = loadable(
  () => import(/* webpackChunkName: "manageLoans" */ '../screens/manageLoans'),
);

const AsyncManageInvestment = loadable(
  () => import(/* webpackChunkName: "manageInvestment" */ '../screens/manageInvestment'),
);

const AsyncRedeemMCT = loadable(
  () => import(/*webpackChunkName: "MCT_PrizeWheel"*/ '../screens/redeemMCT'),
);

const AsyncSubmitKyc = loadable(
  () => import(/*webpackChunkName: "MCT_PrizeWheel"*/ '../screens/kycNew/newKycFlow'),
);

const AsyncMCTStaking = loadable(
  () => import(/*webpackChunkName: "MCT_Staking"*/ '../screens/mctStaking'),
);

const AsyncBuyMCT = loadable(
  () => import(/*webpackChunkName: "AsyncBuyMCT"*/ '../screens/buyMCT'),
);

const AsyncBuyMCTConfirm = loadable(
  () => import(/*webpackChunkName: "AsyncBuyMCTConfirm"*/ '../screens/buyMCT/confirm'),
);

const AsyncMCTInteralBonus = loadable(
  () => import(/*webpackChunkName: "AsyncMCTInteralBonus"*/ '../screens/me/pages/History/internalMCTReward'),
);

const AsyncMascotNFTTeaser = loadable(
  () => import(/*webpackChunkName: "AsyncMascotNFTTeaser"*/ '../screens/mascotNFT/teaser'),
);

const AsyncMascotNFT = loadable(
  () => import(/*webpackChunkName: "AsyncMascotNFT"*/ '../screens/mascotNFT'),
);

const AsyncMascotNFTDetail = loadable(
  () => import(/*webpackChunkName: "AsyncMascotNFTDetail"*/ '../screens/mascotNFT/detail'),
);

const AsyncMascotNFT2 = loadable(
  () => import(/*webpackChunkName: "AsyncMascotNFT2"*/ '../screens/mascotNFT/newUI'),
);

const AsyncMascotMintGen1 = loadable(
  () => import(/*webpackChunkName: "AsyncMascotNFTMintGen1"*/ '../screens/mascotNFT/mintGen1'),
);

/**
 * {
 path: '/some-path',
 component: YourComponent,
 componentProps: { name: 'Component Name' },
 auth: bool ==> need to auth to see this view
 containerProps: { header: true [,headerProps] , footer: true [,footerProps], bodyWrapper: true, noContainer: false }; noContainer = true to render children without container
 ...react-router props
 },
 */
const routes = [
  {
    path: '/webview',
    component: AsyncWebView,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.HOME, // ====> move to URL later
    component: AsyncNewHome,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.DEPOSIT_FIAT,
    component: AsyncDepositFiat,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.DEPOSIT_FIAT_PLAID_CONFIRM,
    component: AsyncDepositFiatPlaidConfirm,
    exact: true,
    containerProps: {
      header: false,
      footer: false,
    },
  },
  {
    path: URL.DEPOSIT_CRYPTO,
    component: AsyncDepositCrypto,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.FLEX,
    component: AsyncDepositFiat,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.FLEX_CRYPTO,
    component: AsyncCryptoLend,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: `${URL.DEPOSIT_CONFIRMATION}/:id`,
    component: AsyncDepositConfirmation,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.TESTIMONIALS,
    component: AsyncTestimonials,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.AGREEMENTS,
    component: AsyncAgreements,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.CRYPTO_LOAN,
    component: AsyncConstantLoan,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.BORROW_CONFIRM + '/:id',
    component: AsyncBorrowConfirm,
    exact: true,
    containerProps: {
      header: false,
      footer: false,
    },
  },
  {
    path: URL.WITHDRAW,
    component: AsyncWithdraw,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.MEMBERSHIP_OLD,
    component: AsyncMemberShip,
    exact: true,
    auth: false,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.MEMBERSHIP,
    component: AsyncMemberShipMCT,
    exact: true,
    auth: false,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.ANNOUNCEMENT,
    component: Announcement,
    exact: true,
    auth: false,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.QANDA,
    component: QandA,
    exact: true,
    auth: false,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.DISCUSSION_FORUM,
    component: DiscussionForum,
    exact: true,
    auth: false,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  // {
  //   path: URL.HELP_AND_SUPPORT + '/:category',
  //   component: HelpAndSupportDetails,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: URL.HELP_AND_SUPPORT,
  //   component: HelpAndSupport,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: URL.BOOK_A_CALL,
  //   component: HelpAndSupport,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  {
    path: URL.TRANSFER,
    component: AsyncTransferConstant,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.TRANSFER_CRYPTO,
    component: AsyncTransferCrypto,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.INVEST,
    component: AsyncInvest,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: `${URL.INVEST_CONFIRMATION}/:id`,
    component: AsyncInvestConfirmation,
    exact: true,
    containerProps: {
      header: false,
      footer: false,
    },
  },
  {
    path: URL.ACCOUNTS,
    component: AsyncYourAccount,
    exact: true,
    auth: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: `${URL.LOAN_DISBURSEMENT_DETAIL}/:id`,
    component: AsyncLoanDisbursement,
    exact: true,
    auth: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    }
  },
  {
    path: URL.LOAN_PAYMENT_DETAIL + '/:id',
    component: AsyncLoanPayment,
    exact: true,
    auth: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    }
  },
  {
    path: URL.COLLATERAL_BALANCE,
    component: AsyncCollateralBalance,
    exact: true,
    auth: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  // {
  //   path: URL.PRO_LENDING,
  //   component: AsyncProLending,
  //   exact: true,
  //   containerProps: {
  //     footer: false,
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  {
    path: URL.SECONDARY_MARKET,
    component: AsyncSecondaryMarket,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.GET_PRICES,
    component: AsyncPricingAndServiceTime,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.FEE_AND_SERVICES_TIMES,
    component: AsyncPricingAndServiceTime,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.AFFILIATES,
    component: AsyncAffiliates,
    auth: false,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.AFFILIATE_JEANGALEA,
    component: AsyncAffiliateAssociationRegister,
    auth: false,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.AFFILIATE_FINANCIAL_PROFESSIONAL,
    component: AsyncAffiliateAssociationRegister,
    auth: false,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.AFFILIATE_FREE_BITCON,
    component: AsyncAffiliateAssociationRegister,
    auth: false,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.GROW,
    component: AsyncInvest,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  // {
  //   path: URL.ABOUT_US,
  //   component: AsyncAboutUs,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: URL.CALL_US,
  //   component: AsyncCallUs,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  {
    path: URL.USER_SIGN_IN,
    component: AsyncLogin,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
      footer: false,
      mobileHeader: false,
    },
  },
  {
    path: URL.USER_SIGN_IN_MONEY_MADE,
    component: AsyncLoginMoneyMade,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: false,
      bgBody: true,
      header: false,
      footer: false,
    },
  },
  {
    path: URL.USER_SIGN_UP,
    component: AsyncRegister,
    exact: true,
    containerProps: {
      bgHeader: true,
      bodyWrapper: false,
      footer: false,
      mobileHeader: false,
    },
  },
  {
    path: URL.USER_SIGN_UP_BORROW,
    component: AsyncRegisterBorrow,
    exact: true,
    containerProps: {
      bgHeader: true,
      bodyWrapper: false,
      footer: false,
      mobileHeader: false,
    },
  },
  {
    path: URL.CRYPTO_LEND_SIGN_UP,
    component: AsyncCryptoLendSignUp,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.CRYPTO_LEND_TO_EARN,
    component: AsyncCryptoLendToEarn,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
      footer: false,
    },
  },
  {
    path: URL.REFERRAL,
    component: AsyncReferral,
    // auth: true,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.USER_FORGET_PASSWORD,
    component: AsyncForgetPassword,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
      footer: true,
    },
  },
  {
    path: URL.USER_FORGET_PASSWORD_FINISH,
    component: AsyncForgetPasswordFinish,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
      footer: true,
    },
  },
  {
    path: URL.ACTIVATE_ACCOUNT,
    component: AsyncActivateAccount,
    exact: true,
  },
  {
    path: URL.THANK_YOU,
    component: AsyncThankYou,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
      footer: true
    },
  },
  {
    path: URL.UNSUBSCRIBE_EMAIL,
    component: AsyncUnsubscribeEmail,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
      footer: true
    },
  },
  {
    path: URL.EMAIL_CONFIRM_RESERVE,
    component: AsyncEmailConfirm,
    exact: true,
  },
  {
    path: URL.EMAIL_CONFIRM_CRYPTO_WITHDRAWAL,
    component: AsyncEmailConfirmCryptoWithdrawal,
    exact: true,
  },
  {
    path: URL.SUBMIT_KYC,
    component: AsyncSubmitKyc,
    exact: true,
  },
  {
    path: URL.ME,
    component: AsyncMe,
    // exact: true,
    auth: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
      mobileHeader: false,
      mobileFooter: false,
    },
    routes: [
      // {
      //   path: URL.ME,
      //   component: AsyncGetStarted,
      //   exact: true,
      //   auth: true,
      // },
      // {
      //   path: URL.ME_PROFILE,
      //   component: AsyncGetStarted,
      //   exact: true,
      //   auth: true,
      // },
      // {
      //   path: URL.ME_BANK_INFO,
      //   component: AsyncGetStarted,
      //   exact: true,
      //   auth: true,
      // },
      {
        path: URL.ME_MYINFORMATION,
        component: AsyncAccountInfo,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_NOTIFICATION_SETTINGS,
        component: AsyncNotificationSetting,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_SECURITIES,
        component: AsyncAccountSecurity,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_ADDRESS_BOOK,
        component: AddressBook,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_LOGIN_HISTORY,
        component: AccountActivities,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_SECURITIES_GOOGLE_AUTH,
        component: AsyncAccountSecurity2FA,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_SECURITIES_SMS_AUTH,
        component: AsyncAccountSecuritySms,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_HISTORY_DEPOSIT,
        component: AsyncHistoryDeposit,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_HISTORY_OVERVIEW,
        component: AsyncoOverView,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_HISTORY_WITHDRAW,
        component: AsyncHistoryWithDraw,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_HISTORY_INVESTMENT,
        component: AsyncHistoryInvestment,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_LOCAL_HISTORY,
        component: AsyncBalanceHistory,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_BATCH_TRANSFER,
        component: AsyncBatchTransfer,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_BATCH_TRANSFER_DETAIL,
        component: AsyncBatchTransferDetail,
        // exact: true,
        auth: true,
      },
      {
        path: URL.ME_OPEN_ORDER,
        component: AsyncOpenOrder,
        // exact: true,
        auth: true,
      },
      {
        path: URL.ME_INTEREST_HISTORY,
        component: AsyncInterestHistory,
        // exact: true,
        auth: true,
      },
      {
        path: URL.ME_LENDING_LOTTERY,
        component: AsyncLendingLotteryHistory,
        // exact: true,
        auth: true,
      },
      {
        path: URL.ME_NFT_REWARD_DETAIL,
        component: AsyncNftRewardsDetail,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_NFT_REWARDS,
        component: AsyncNftRewards,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_NFT_COLLECTION,
        component: AsyncNftCollection,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_CRYPTO_SWAP,
        component: AsyncCryptoSwapHistory,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_MCT_AIRDROP,
        component: AsyncMCTAirdrop,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_MCT_INTERNAL_BONUS,
        component: AsyncMCTInteralBonus,
        exact: true,
        auth: true,
      },
      // {
      //   path: URL.ME_MCT_EARNING,
      //   component: AsyncMCTEarningHistory,
      //   exact: true,
      //   auth: true,
      // },
      {
        path: URL.ME_MCT_STAKING,
        component: AsyncMCTStakingHistory,
        exact: true,
        auth: true,
      },
      {
        path: URL.ME_MCT_PRIZEWHEEL,
        component: AsyncMCTPrizewheelHistory,
        exact: true,
        auth: true,
      },
    ],
  },
  {
    path: URL.KYC_SUPPORT,
    component: AsyncMeSupport,
    exact: true,
    // auth: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.NOTIFICATIONS,
    component: AsyncNotifications,
    exact: true,
    auth: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.NOTIFICATIONS + '/:id',
    component: AsyncNotificationDetail,
    exact: true,
    auth: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.UPDATE_PASSWORD,
    component: AsyncUpdatePassword,
    exact: true,
  },
  /*{
    path: URL.LANDING_PAGES_DEVERSIFY,
    component: AsyncDiversifyLandingPages,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },*/
  /*{
    path: URL.LANDING_PAGES_SECURE_RETURN,
    component: AsyncSecureReturnsLandingPages,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },*/
  /*{
    path: URL.LANDING_PAGES_STEADY_GROWTH,
    component: AsyncSteadyGrowthLandingPages,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },*/
  /*{
    path: URL.LANDING_PAGES_NET_RETURN,
    component: AsyncNetReturnsLandingPages,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },*/
  /*{
    path: URL.LANDING_PAGES_INVEST_EASY,
    component: AsyncInvestEasyLandingPages,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },*/
  /*{
    path: URL.LANDING_PAGES_LO_EARN_A_BETTER_RATE,
    component: AsyncLOEarnABetterRateLandingPages,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },*/
  // {
  //   path: URL.LANDING_PAGES_TRIAL_BONUS,
  //   component: AsyncTrialBonusLandingPages,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: URL.LANDING_PAGES_PROMOTION_GENERAL_3K,
  //   component: AsyncPromotionGeneral3KLandingPages,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  {
    path: URL.LANDING_PAGES_PROMOTION_GENERAL_2K,
    component: AsyncRegister,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.LANDING_PAGES_PRIZE_WHEEL,
    component: AsyncMCTPrizeWheel,
    exact: true,
    containerProps: {
      bodyWrapper: false,
    },
  },
  {
    path: URL.LANDING_PAGES_PRIZE_WHEEL_BIRTHDAY,
    component: AsyncMCTPrizeWheel,
    exact: true,
    containerProps: {
      bodyWrapper: false,
    },
  },
  {
    path: URL.PRIZE_WHEEL_BIRTHDAY_RESULT,
    component: AsyncPrizeWheelResultLandingPages,
    exact: true,
    containerProps: {
      bodyWrapper: false,
    },
  },
  {
    path: URL.LENDING_LOTTERY,
    component: AsyncLendingLottery,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: `${URL.LENDING_LOTTERY_PICK_LOTTERY}/:id`,
    component: AsyncLendingLoterryPickLottery,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: `${URL.LENDING_LOTTERY_LANDING}`,
    component: AsyncLendingLotteryLandingPages,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: `${URL.LENDING_LOTTERY_LANDING_DRAW_NUMBER}`,
    component: AsyncLendingLotteryDrawNumber,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  // {
  //   path: `${URL.LOAN_ORIGINATORS}${URL.LO_AUTO_INVEST}`,
  //   component: LOAutoInvest,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: `${URL.LOAN_ORIGINATORS}${URL.LO_HOW_IT_WORK}`,
  //   component: LoanOriginators,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: `${URL.LOAN_ORIGINATORS}${URL.LO_CONSTANT_RATING}`,
  //   component: LOConstantRating,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: `${URL.LOAN_ORIGINATORS}${URL.LO_AUTO_INVEST}/:id`,
  //   component: LOAutoInvestDetail,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: `${URL.LOAN_ORIGINATORS}${URL.LO_MANUAL_INVEST}`,
  //   component: LOManualInvest,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: `${URL.LOAN_ORIGINATORS}${URL.LO_MANUAL_INVEST}/:id`,
  //   component: LOManualInvestDetail,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: `${URL.LOAN_ORIGINATORS}${URL.LO_MANUAL_INVEST_CONFIRM}/:id`,
  //   component: LOManualInvestConfirm,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: `${URL.LOAN_ORIGINATORS}${URL.LO_LOAN_ORIGINATOR}`,
  //   component: LOLoanOriginator,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: `${URL.LOAN_ORIGINATORS}${URL.LO_LOAN_ORIGINATOR}/:id`,
  //   component: LOLoanOriginatorDetail,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  {
    path: URL.LO_SECURITY_RISK,
    component: LOSecurityRisk,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.LO_ONLINE_SECURITY,
    component: LOOnlineSecurity,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  // {
  //   path: `${URL.LOAN_ORIGINATORS}`,
  //   component: LoanOriginators,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  {
    path: `${URL.BADGES}`,
    component: AsyncBadges,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: `${URL.WITHDRAW_FEE}`,
    component: AsyncWithdrawFee,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.ACCOUNT_HISTORY,
    component: AccountHistory,
    exact: true,
    auth: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: false,
      bgBody: false,
      header: false,
      footer: false
    },
  },
  {
    path: URL.ACCOUNT_HISTORY + '/:id',
    component: AccountHistoryDetail,
    exact: true,
    auth: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: false,
      bgBody: false,
      header: false,
      footer: false
    },
  },
  // {
  //   path: URL.STATISTICS,
  //   component: AsyncStatistic,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: URL.GIFT_CARDS,
  //   component: AsyncGiftCards,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: URL.GIFT_CARDS + '/:id',
  //   // component: AsyncGiftCardDetail,
  //   component: AsyncGiftCards,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //   },
  // },
  // {
  //   path: URL.GIFT_CARDS_CONFIRM,
  //   component: AsyncGiftCardConfirm,
  //   exact: true,
  //   auth: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //     bgHeader: true,
  //     bgBody: true,
  //     footer: false,
  //   },
  // },
  {
    path: URL.WITHDRAW_CRYPTO_DETAIL + '/:id',
    component: AsyncWithdrawCryptoDetail,
    exact: true,
    containerProps: {
      header: false,
      footer: false,
    },
  },
  // {
  //   path: URL.LIVE_CHAT,
  //   component: AsyncLiveChat,
  //   exact: true,
  //   containerProps: {
  //     header: false,
  //     footer: false,
  //   },
  // },
  {
    path: URL.REGISTER_ACCREDITATION,
    component: AsyncRegisterAccreditation,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.CRYPTO_SHORT,
    component: AsyncCryptoShort,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.CRYPTO_SHORT_TRIAL,
    component: AsyncCryptoShort,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.CRYPTO_FUTURE,
    component: AsyncCryptoFuture,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.CRYPTO_FUTURE_TRIAL,
    component: AsyncCryptoFuture,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.FORUM,
    component: AsyncForum,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.FORUM + '/:topic',
    component: AsyncForum,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.AUTH_DISCORD_CALLBACK,
    component: AsyncAuthDiscordCallback,
    exact: true,
  },
  {
    path: URL.AUTH_PLAID_CALLBACK,
    component: AsyncAuthPlaidCallback,
    exact: true,
  },
  {
    path: URL.FORUM_CHAT,
    component: AsyncForumChat,
    containerProps: {
      bodyWrapper: false,
      bgBody: true,
      footer: false,
      header: false
    },
    routes: [
      {
        path: URL.FORUM_CHAT,
        component: AsyncForumDashboard,
        exact: true,
      },
      {
        path: URL.FORUM_CHAT_CATEGORY,
        component: AsyncForumCategory,
        exact: true,
      },
      {
        path: URL.FORUM_CHAT_TOPIC,
        component: AsyncForumTopic,
        exact: true,
      },
      {
        path: URL.FORUM_CHAT_SAVED_TOPIC,
        component: AsyncForumSavedTopic,
        exact: true,
      },
      {
        path: URL.FORUM_CHAT_DRAFT_TOPIC,
        component: AsyncForumDraftTopic,
        exact: true,
      },
      {
        path: URL.FORUM_CHAT_RECENT_TOPIC,
        component: AsyncForumRecentTopic,
        exact: true,
      },
      {
        path: URL.FORUM_CHAT_RULES,
        component: AsyncForumRules,
        exact: true,
      },
      {
        path: URL.FORUM_CHAT_MY_TOPICS,
        component: AsyncForumMyTopic,
        exact: true,
      },
      {
        path: URL.FORUM_CHAT_SHEDULE_TOPICS,
        component: AsyncForumSheduleTopicList,
        exact: true,
      },
    ]
  },
  {
    path: URL.PERSONAL_BUDGET_LANDING,
    component: AsyncPersonalBudgetLanding,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: false,
    },
  },
  {
    path: URL.PERSONAL_BUDGET,
    component: AsyncPersonalBudget,
    auth: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
    routes: [
      {
        path: URL.PERSONAL_BUDGET_DASHBOARD,
        component: AsyncPersonalBudgetDashboard,
        exact: true,
        auth: true,
      },
      {
        path: URL.PERSONAL_BUDGET_TRANSACTIONS,
        component: AsyncPersonalBudgetTransactions,
        exact: true,
        auth: true,
      },
      {
        path: URL.PERSONAL_BUDGET_LINK_BANKS,
        component: AsyncPersonalBudgetLinkBanks,
        exact: true,
        auth: true,

      },
      {
        path: URL.PERSONAL_BUDGET_BUDGETS,
        component: AsyncPersonalBudgetBudgets,
        exact: true,
        auth: true,
      },
    ]
  },
  {
    path: URL.SHORT_SIMPLE,
    component: AsyncShortSimple,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.SHORT_SIMPLE_TRIAL,
    component: AsyncShortSimple,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.SUBMIT_TESTIMONIAL,
    component: AsyncSubmitTestimonial,
    exact: true,
    containerProps: {
      footer: false,
    },
  },
  {
    path: URL.INSTITUTIONAL_LOANS,
    component: AsyncInstitutionalLoans,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.PO_FINANCING_LOANS,
    component: AsyncPoFinancingLoans,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.DEBIT_CARD_LANDING,
    component: AsyncDebitCardLanding,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.DEBIT_CARD,
    component: AsyncDebitCard,
    auth: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
      mobileHeader: false,
      mobileFooter: false,
    },
    routes: [
      {
        path: URL.CREDIT_PAYMENT,
        component: AsyncCreditPayment,
        exact: true,
        auth: true,
      },
      {
        path: URL.DEBIT_CARD_STATEMENTS,
        component: AsyncDebitCardStatement,
        exact: true,
        auth: true,
      },
      {
        path: URL.DEBIT_CARD_TRANSACTIONS,
        component: AsyncDebitCardTransactions,
        exact: true,
        auth: true,
      },
      {
        path: URL.DEBIT_CARD_PHYSICAL,
        component: AsyncDebitCardPhysical,
        exact: true,
        auth: true,
      },
      {
        path: URL.DEBIT_CARD_REWARD,
        component: AsyncDebitCardReward,
        exact: true,
        auth: true,
      },
    ],
  },
  {
    path: URL.CRYPTO_SWAP,
    component: AsyncCryptoSwap,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.CRYPTO_SWAP_CONFIRM,
    component: AsyncCryptoSwapConfirm,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      header: false,
      footer: false,
    },
  },
  {
    path: URL.CONSTANT_TOKEN,
    component: AsyncConstantToken,
    exact: true,
    containerProps: {
      footer: false,
    },
  },
  // {
  //   path: URL.AIRDROP_WHEEL,
  //   component: AsyncAirdropWheel,
  //   exact: true,
  //   containerProps: {
  //     bodyWrapper: false,
  //   },
  // },
  {
    path: URL.MCT_PRIZE_WHEEL,
    component: AsyncMCTPrizeWheel,
    exact: true,
    containerProps: {
      bodyWrapper: false,
    },
  },
  {
    path: URL.ACCOUNTS_INVESTMENT,
    component: AsyncManageInvestment,
    exact: true,
    auth: true,
  },
  {
    path: URL.ACCOUNTS_LOANS,
    component: AsyncManageLoans,
    exact: true,
    auth: true,
  },
  {
    path: URL.REDEEM_MCT,
    component: AsyncRedeemMCT,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      footer: false,
    },
  },
  {
    path: URL.BUY_MCT,
    component: AsyncBuyMCT,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      bgHeader: true,
      bgBody: true,
    },
  },
  {
    path: URL.BUY_MCT_CONFIRM,
    component: AsyncBuyMCTConfirm,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      header: false,
      footer: false,
    },
  },
  {
    path: URL.MCT_STAKING,
    component: AsyncMCTStaking,
    exact: true,
    containerProps: {
      bodyWrapper: false,
    }
  },
  {
    path: URL.MCT_MASCOT_TEASER,
    component: AsyncMascotNFTTeaser,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      footer: {
        theme: 'dark'
      }
    }
  },
  {
    path: URL.MCT_MASCOT,
    component: AsyncMascotNFT2,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      footer: {
        theme: 'dark'
      }
    }
  },
  {
    path: URL.MCT_MASCOT_2_MINT,
    component: AsyncMascotMintGen1,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      footer: false,
    },
  },
  {
    path: `${URL.MASCOT_NFT_DETAIL}/:id`,
    component: AsyncMascotNFTDetail,
    exact: true,
    containerProps: {
      bodyWrapper: false,
      footer: false,
    }
  },
];

export default routes;
