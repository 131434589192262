const primetrustStatus = {

  getStatusInfo(verifiedLevel) {
    let status = '';
    let classStatus = '';
    let disabledForm = false;

    switch(verifiedLevel) {
      case 0:
        status = 'statusNeedVerifyEmail';
        classStatus = 'unverified';
        disabledForm = true;
        break;
      case 1:
        status = 'statusUnverified';
        classStatus = 'unverified';
        disabledForm = false;
        break;
      case 2:
      case 4:
      case 5:
        status = 'statusProcessing';
        classStatus = 'processing';
        disabledForm = true;
        break;
      case 3:
        status = 'statusPendding';
        classStatus = 'failed';
        disabledForm = false;
        break;
      case 6:
      case 7:
        status = 'statusVerified';
        classStatus = 'verified';
        disabledForm = true;
    }

    return { status, classStatus, disabledForm };
  },
};
export default primetrustStatus;
