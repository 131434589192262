import BigNumber from 'bignumber.js';

const AMOUNT_RATE = 100;
const AMOUNT_DECIMAL_NUMBER = 2;

class AmountFormater {
  /**
   * `amount` send to server must be multiply by `AMOUNT_RATE` (interger number)
   */
  // static toServer = amount => parseInt(Number(amount) * AMOUNT_RATE) || 0;
  static toServer = amount => parseInt(BigNumber(Number(amount) * AMOUNT_RATE).toFixed(0)) || 0;

  /**
   * `amount` get from server must be divided by `AMOUNT_RATE` (decimal number)
   */
  static fromServer = amount => {
    const _tmp = parseFloat(Number(amount) / AMOUNT_RATE) || 0;
    return _tmp.toFixed(AMOUNT_DECIMAL_NUMBER);
  };
}

export default AmountFormater;
