import LocalStore from 'src/services/localStore';
import { UPDATE_DEBUG_IS_US } from './action';

const initState = {
  isUSIp: LocalStore.get('is_debug_us'),
};

export default (state = initState, { type, data }) => {
  switch (type) {
    case UPDATE_DEBUG_IS_US:
      LocalStore.save('is_debug_us', data);
      return {
        ...state,
        isUSIp: data,
      };
    default:
      return state;
  }
};
