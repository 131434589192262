export const API_ROOT = APP_ENV.API_ROOT_URL;
export const API_STAGING_URL = APP_ENV.API_STAGING_URL || APP_ENV.API_ROOT_URL;
export const API_BASE = APP_ENV.BASE_API_URL;
export const EXCHANGE_API_BASE = APP_ENV.BASE_EXCHANGE_API_URL;
export const EXCHANGE_TRANSFER_API = APP_ENV.BASE_EXCHANGE_TRANSFER_URL;
export const BASE_SAVING_API = APP_ENV.BASE_SAVING_API_URL;
export const BASE_LOAN_ORIGINATOR_API = APP_ENV.BASE_LOAN_ORIGINATOR_API_URL;
// export const BASE_SAVING_MISC_API = APP_ENV.BASE_SAVING_MISC_URL;
// export const BLOG_URL = APP_ENV.BLOG_URL;
export const LOTTERY_URL = APP_ENV.LOTTERY_URL;
export const URL = {
  KYC_GUIDELINE: 'https://myconstant.com/blog/how-to-verify-your-id-on-constant-(kyc/',
  USER_SIGN_IN: '/log-in',
  USER_SIGN_IN_MONEY_MADE: '/moneymade/oath',
  USER_SIGN_UP: '/sign-up',
  USER_SIGN_UP_BORROW: '/sign-up-borrow',
  USER_FORGET_PASSWORD: '/forget-password',
  USER_FORGET_PASSWORD_FINISH: '/forget-finish',
  ACTIVATE_ACCOUNT: '/activate-account',
  THANK_YOU: '/thank-you',
  UNSUBSCRIBE_EMAIL: '/unsubscribe',
  HOME: '/',
  ABOUT_US: '/about-us',
  CALL_US: '/call-us',
  BOOK_A_CALL: '/book-a-call',
  ME: '/me',
  ME_PROFILE: '/me/profile',
  ME_CHANGE_PASSWORD: '/me/change-password',
  ME_MYINFORMATION: '/me/information',
  ME_SECURITIES: '/me/securities',
  ME_LOGIN_HISTORY: '/me/login-history',
  ME_SECURITIES_GOOGLE_AUTH: '/me/securities/google-auth',
  ME_SECURITIES_SMS_AUTH: '/me/securities/sms-auth',
  ME_NOTIFICATION_SETTINGS: '/me/notification-settings',
  ME_ACCOUNT_LEVEL: '/me/accountLevel',
  ME_HISTORY_OVERVIEW: '/me/overview',
  ME_HISTORY_DEPOSIT: '/me/deposit',
  ME_HISTORY_WITHDRAW: '/me/withdraw',
  ME_HISTORY_INVESTMENT: '/me/investment',
  ME_LOCAL_HISTORY: '/me/balance-history',
  ME_HISTORY_SHORT_SELLING: '/me/short-selling-history',
  ME_ADDRESS_BOOK: '/me/saved-addresses',
  ME_BANK_INFO: '/me/bankInfo',
  KYC_SUPPORT: '/kyc/support',
  REFERRAL: '/referral',
  IP_DOMAIN: 'https://ipfind.co/me',
  TRANSFER: '/transfer',
  TRANSFER_CRYPTO: '/transfer-crypto',
  CRYPTO_LOAN: '/crypto-backed-loan',
  BORROW_CONFIRM: '/borrow-confirm',
  ME_BATCH_TRANSFER: '/me/batch-transfer',
  ME_BATCH_TRANSFER_DETAIL: '/me/batch-transfer/:id',
  ME_OPEN_ORDER: '/me/open-order',
  ME_INTEREST_HISTORY: '/me/interest-history',
  ME_LENDING_LOTTERY: '/me/lending-lottery',
  ME_NFT_REWARDS: '/me/nft-rewards',
  ME_NFT_COLLECTION: '/me/mct-mascot',
  ME_NFT_REWARD_DETAIL: '/me/nft-rewards/detail',
  ME_CRYPTO_SWAP: '/me/crypto-swap',
  ME_MCT_AIRDROP: '/me/mct-airdrop',
  ME_MCT_EARNING: '/me/mct-earning',
  ME_MCT_STAKING: '/me/mct-staking',
  ME_MCT_PRIZEWHEEL: '/me/mct-prizewheel',
  ME_MCT_INTERNAL_BONUS: '/me/mct-internal-bonus',
  ACCOUNTS: '/accounts',
  ACCOUNTS_INVESTMENT: '/accounts/investment',
  ACCOUNTS_LOANS: '/accounts/loans',
  LOAN_DISBURSEMENT_DETAIL: '/accounts/loan-disbursement',
  LOAN_PAYMENT_DETAIL: '/accounts/loan-payment',
  DEPOSIT: '/deposit',
  DEPOSIT_FIAT: '/deposit-money-online',
  DEPOSIT_FIAT_PLAID_CONFIRM: '/deposit-plaid-confirm',
  DEPOSIT_CRYPTO: '/online-multi-crypto-wallet',
  FLEX: '/flex',
  FLEX_CRYPTO: '/lend-crypto-to-earn-interest',
  DEPOSIT_CONFIRMATION: '/deposit/confirmation',
  UPDATE_PASSWORD: '/update-password',
  INVEST: '/lend-money-online-to-earn-interest',
  BLOG_WORDPRESS: 'https://myconstant.com/blog/',
  WITHDRAW: '/withdraw',
  WITHDRAW_CRYPTO_DETAIL: '/withdraw-crypto-detail',
  MEMBERSHIP: '/membership',
  MEMBERSHIP_OLD: '/membership-old',
  PRO_LENDING: '/pro-lending',
  SECONDARY_MARKET: '/secondary-market',
  INVEST_CONFIRMATION: '/invest/confirmation',
  GET_PRICES: '/getPrices',
  FEE_AND_SERVICES_TIMES: '/fee-and-service-times',
  AFFILIATES: '/affiliates',
  AFFILIATES_TARGET_CIRCLE: '/affiliates-targetcircle',
  AFFILIATE_JEANGALEA: '/sign-up/jeangalea',
  AFFILIATE_FINANCIAL_PROFESSIONAL: '/sign-up/financialprofessional',
  AFFILIATE_FREE_BITCON: '/sign-up/freebitcoin',
  COLLATERAL_BALANCE: '/collateral-balance',
  TESTIMONIALS: '/why-people-love-us',
  AGREEMENTS: '/agreements',
  EMAIL_CONFIRM_RESERVE: '/email-confirm-reserve',
  EMAIL_CONFIRM_CRYPTO_WITHDRAWAL: '/email-confirm-crypto-withdrawal',
  SUPPORT_CENTER: '/help-and-support',
  EMAIL_SUPPORT: 'mailto:hello@myconstant.com',
  TELEGRAM_SUPPORT: 'https://t.me/constantp2p',
  HELP_SUPPORT: 'https://constant.zendesk.com/hc/en-us/categories/360002435692-FAQs',
  NOTIFICATIONS: '/notifications',
  GROW: '/grow',
  // LANDING_PAGES_TRIAL_BONUS: '/trial-bonus',
  // LANDING_PAGES_PROMOTION_GENERAL_3K: '/3k-promo-general',
  LANDING_PAGES_PROMOTION_GENERAL_2K: '/2k-promo-general',
  LANDING_PAGES_PRIZE_WHEEL: '/prize-wheel',
  LANDING_PAGES_PRIZE_WHEEL_BIRTHDAY: '/prize-wheel/january',
  PRIZE_WHEEL_BIRTHDAY_RESULT: '/prize-wheel/result',
  LENDING_LOTTERY: '/lending-lottery',
  LENDING_LOTTERY_PICK_LOTTERY: '/lending-lottery/pick',
  LENDING_LOTTERY_LANDING: '/lending-lottery/result',
  LENDING_LOTTERY_LANDING_DRAW_NUMBER: '/lending-lottery/draw-number',
  HELP_AND_SUPPORT: '/help-and-support',
  LOAN_ORIGINATORS: '/loan-originators',
  LO_AUTO_INVEST: '/auto-invest',
  LO_HOW_IT_WORK: '/how-it-works',
  LO_MANUAL_INVEST: '/manual-invest',
  LO_MANUAL_INVEST_CONFIRM: '/manual-invest-confirm',
  LO_CONSTANT_RATING: '/constant-rating',
  LO_LOAN_ORIGINATOR: '/loan-originator',
  LO_SECURITY_RISK: '/security-risk',
  LO_ONLINE_SECURITY: '/online-security',
  BADGES: '/badges',
  WITHDRAW_FEE: '/withdraw-fees',
  FRAME_SSO: '/sso',
  ACCOUNT_HISTORY: '/accounts/history',
  STATISTICS: '/statistics',
  GIFT_CARDS: '/gift-cards',
  GIFT_CARDS_CONFIRM: '/gift-card-confirm',
  LIVE_CHAT: '/live-chat',
  REGISTER_ACCREDITATION: '/register-accreditation',
  CRYPTO_SHORT: '/short-selling',
  CRYPTO_SHORT_TRIAL: '/trial-for-short-selling',
  CRYPTO_FUTURE: '/predict-cryptocurrency-prices-to-win-cash',
  CRYPTO_FUTURE_TRIAL: '/trial-for-predicting-cryptocurrency-prices',
  FORUM: '/conversations',
  SHORT_SIMPLE: '/short-selling-simple',
  SHORT_SIMPLE_TRIAL: '/trial-for-short-selling-simple',
  PERSONAL_BUDGET: '/personal-budget',
  PERSONAL_BUDGET_LANDING: '/personal-budget/landing',
  PERSONAL_BUDGET_DASHBOARD: '/personal-budget/dashboard',
  PERSONAL_BUDGET_TRANSACTIONS: '/personal-budget/transactions',
  PERSONAL_BUDGET_LINK_BANKS: '/personal-budget/link-banks',
  PERSONAL_BUDGET_BUDGETS: '/personal-budget/budgets',
  SUBMIT_TESTIMONIAL: '/submit-testimonial',
  SPENDING: '/spending',
  DEBIT_CARD: '/debit-card',
  DEBIT_CARD_STATEMENTS: '/debit-card/statements',
  DEBIT_CARD_TRANSACTIONS: '/debit-card/transactions',
  DEBIT_CARD_PHYSICAL: '/debit-card/physical-card',
  CREDIT_PAYMENT: '/debit-card/credit-payment',
  DEBIT_CARD_REWARD: '/debit-card/reward',
  DEBIT_CARD_LANDING: '/debit-card/landing',
  INSTITUTIONAL_LOANS: '/institutional-loans',
  PO_FINANCING_LOANS: '/invoice-factoring',
  CRYPTO_SWAP: '/crypto-swap',
  CRYPTO_SWAP_CONFIRM: '/crypto-swap/confirm',
  FORUM_CHAT: '/community',
  FORUM_CHAT_CATEGORY: '/community/c/:category_slug/:category_id',
  FORUM_CHAT_TOPIC: '/community/t/:topic_slug/:topic_id',
  FORUM_CHAT_SAVED_TOPIC: '/community/saved',
  FORUM_CHAT_DRAFT_TOPIC: '/community/draft',
  FORUM_CHAT_RECENT_TOPIC: '/community/recent',
  FORUM_CHAT_RULES: '/community/rules',
  FORUM_ALL_TOPIC: '/community/all',
  FORUM_CHAT_MY_TOPICS: '/community/threads',
  FORUM_CHAT_SHEDULE_TOPICS: '/community/shedule',
  AUTH_DISCORD_CALLBACK: '/discord-oauth-callback',
  DISCORD: 'https://discord.gg/ncjPApdgBz',

  CONSTANT_TOKEN: '/mct',
  AIRDROP_WHEEL: '/airdrop',
  CRYPTO_LEND_SIGN_UP: '/12lend-signup',
  MCT_PRIZE_WHEEL: '/mct-prize-wheel',
  CRYPTO_LEND_TO_EARN: '/lending-to-earn',
  REDEEM_MCT: '/redeem',
  SUBMIT_KYC: '/submit-kyc',
  MCT_STAKING: '/mct-staking',
  BUY_MCT: '/buy-mct',
  BUY_MCT_CONFIRM: '/buy-mct/confirm',
  MCT_MASCOT_TEASER: '/mct-mascot-teaser',
  MCT_MASCOT: '/NFT-collection',
  MCT_MASCOT_2_MINT: '/NFT-collection/mint',
  MASCOT_NFT_DETAIL: '/NFT-collection/detail',
  AUTH_PLAID_CALLBACK: '/plaid-oauth-callback',
  ANNOUNCEMENT: '/announcement',
  QANDA: '/discussion-forum',
  DISCUSSION_FORUM: '/discussion-forum',
  UPDATE_ADDRESS: '/update-address',
  UPDATE_WALLET: '/update-wallet',
  BALANCE_CONFIRMATION: '/balance-confirmation',
  UPDATE_BANK_INFO: '/update-bank-info',
  UPDATE_BANK_INFO_INTERNATIONAL: '/update-bank-info-international',
};

export const SOCIAL = {
  GITHUB: 'https://github.com/constant-money',
  WHITE_PAPER: 'http://files.constant.money/whitepaper.pdf',
  GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=com.constantapp&hl=en',
  APP_STORE: 'https://apps.apple.com/us/app/myconstant-p2p-lending/id1497107082?ls=1',
  QR_CODE_DOWNLOAD: 'https://constantapp.page.link/getapp',
  HEADER_DOWNLOAD: 'https://constantapp.page.link/gfzw',
  FIREBASE_LINK_DOWNLOAD: 'https://constantapp.page.link/?link=https://myconstant.com/?apn=com.constantapp&isi=1497107082&ibi=com.constant.constantapp&utm_source={utm_source}&ct={ct}&utm_campaign=DownloadApp&efr=1',
  DISCORD: 'https://discord.gg/ncjPApdgBz',
};

export const API_URL = {
  EXCHANGE: {
    GET_FIAT_CURRENCY: 'exchange/quote',
    REVIEWS: 'exchange/reviews/',
    TRANSFER: 'exchange-api/transfer/transfer-file/',
    CANCEL_TRANSFER: '../transfer/transfers/{id}/',
    TRANSFER_DETAIL: 'exchange-api/transfer/transfer-file/{id}/transfers',
    TRANSFER_DETAIL_NEW:
      'exchange-api/transfer/transfer-file/{id}/detail-transfers/',
    CHECK_INTERNAL_USER: 'internal-user/',
    GET_WITHDRAW_HISTORY: '/all-withdraws/',
    EXPORT_WITHDRAW_HISTORY: '/all-withdraws-export/',
    GET_DEPOSIT_BANKS: '/exchange-api/exchange/deposit-banks/',
    GET_CRYPTO_ADDRESS: '/crypto-address/',
    GET_STABLE_COIN_EXCHANGE_RATE: 'exchange-api/crypto/fiat-exchange-rate/',
    GET_PLAID_SPLIT_AMOUNT: 'buy-orders/{id}/plaid-split-amount/',
    UPDATE_PLAID_SPLIT: 'buy-orders/{id}/plaid-split/',
    PLAID_SPLIT: '/plaid-split',
    PUBLIC_BUY_METHODS: '/public-buy-methods',
    FIRST_DEPOSIT_GA_EVENT: '/first-deposit-ga-event/',
    GET_LOTTERY_FIRST_DEPOSIT_PROMOTION: '/lottery-promotion/',
    GET_FIRST_DEPOSITED_TIME: '/first-deposit/',
    GET_PLAID_BANK_INFO: '/api/plaid/banks',
  },
  SAVING: {
    ACCREDITATION_REGISTRATION: '/accreditation-registrations/',
    SAVING_TERM_DEPOSITS: '/term-deposits/',
    SAVING_TERM_DEPOSITS_PAGED: '/paged-term-deposits/',
    MATCHED_SAVING_TERM_DEPOSITS: '/matched-term-deposits/',
    ORDER_TERM_DEPOSITS: '/term-deposit-orders/',
    TERM_DEPOSIT_SUMMARY: '/term-deposit-summary/',
    TERM_DEPOSIT_SUMMARY_MOBILE: '/term-deposit-summary-2/',
    VOID_TERM_DEPOSIT_SUMMARY: '/void-term-deposit-summary/',
    VOID_TERM_DEPOSIT_SUMMARY_MOBILE: '/void-term-deposit-summary-2/',
    CHECK_TRIAL_KYC: '/check-trial-kyc/',
    GET_EARNED_INTEREST: '/earned-interest/',
    GET_USER_BONUS_INTEREST_RATE: '/user-bonus-interest-rates/',
    APPLY_REFERRAL: '/apply-referral/',
    APPLY_SHARE: '/apply-share/',
    REFERRAL_INVITATION: '/referral-invitation/',
    GET_TERM_DEPOSIT_SETTINGS: '/term-deposit-settings/',
    INVESTMENT_EXPORT: '/export-term-deposits/',
    FIRST_INVESTMENT_PROMOTION: '/first-term-deposit-promotion/',
    HIGH_TERM_DEPOSIT_PROMOTION: '/high-term-deposit-promotion/',
    GET_LOTTERY_NEW_MONEY_INVEST_PROMOTION: '/lottery-promotion/',
    GET_WELCOME_BACK_INVESTMENT_PROMOTION: '/welcome-back-investment-promotion/',
    GET_WELCOME_BACK_FLEX_PROMOTION: '/welcome-back-flex-promotion/',
    GET_AFFILIATE_CUSTOM_INFO: '/saving-api/content/affiliate-custom-info/',
    PROMOTION_LIST: '/promotion-list/',
    AUTH_MONEY_MADE: '/saving-api/reward/moneymade-oauth/',
    GET_TERM_DEPOSIT_AVAILABLE: '/term-deposit-available/'
  },
  USER: {
    USER_SIGN_IN: '/auth/login',
    REQUEST_LOGIN_QR: '/auth/request-login-qr',
    LOGIN_QR: '/auth/login-qr',
    USER_TOKEN_REFRESH: '/token/refresh/', // POST /user/token
    USER_SIGN_UP: '/auth/register',
    USER_PROFILE: '/auth/profile', // GET /user/profile
    USER_FORGET_PASSWORD: '/auth/forgot', // POST user/forgot-password/
    USER_FORGET_PASSWORD_FINISH: '/auth/reset', // POST user/forgot-password/reset/
    FIRST_RESET_PASSWORD: '/auth/first-reset-password', // POST user/forgot-password/reset/
    USER_VERYFY_PASSWORD: '/user/verify-password/',
    USER_LOG: '/user/logs/',
    USER_BANKS: '/auth/banks',
    USER_BANK: '/auth/bank',
    USER_LINKED_BANKS: '/plaid/',
    PLAID_PUBLIC_TOKEN: '/plaid/public-token?ID={id}',
    PLAID_LINK_TOKEN: '/plaid/link-token',
    PLAID_LINK_TOKEN_OAUTH: '/plaid/link-token-oauth',
    PLAID_LINK_ERROR: '/plaid/link-error',
    EMAIL_VERIFICATION: '/auth/verification/email',
    UPDATE_PROFILE: '/auth/update-info',
    UPDATE_BASIC_INFO: '/auth/update-basic-info',
    UPDATE_USERNAME: '/auth/update-username',
    GET_BALANCE: '/auth/balance',
    AUTH_SOCIAL: 'auth/register-social',
    CHECK_TRIAL_USER_AVAIABLE: '/check-trial-available/',
    STATISTICS: '/system/statistics',
    ENABLE_FLEX: 'api/auth/flex-or-primetrust',
    USER_NOTIFICATIONS: 'api/auth/notification',
    GET_UNREAD_NOTIFICATIONS: 'auth/unread-notification',
    GET_NOTIFICATION_DETAIL: 'auth/notification-detail',
    CHECK_REFERRAL_CODE: '/auth/check-referral-code',
    GET_VIEWING_BANNER: '/auth/get-viewing-banner',
    SKIP_BANNER: '/auth/skip-banner',
    CLICK_BANNER: '/auth/click-banner',
    UNSUBSCRIBE_EMAIL: '/unsubscribe-email',
    CRYPTO_EXTERNAL_ADDRESS: '/auth/crypto-external-addresses',
    UPDATE_AVATAR: '/auth/update-avatar',
    UPLOAD_TESTIMONIAL: '/auth/testimonials/upload',
    GET_INFO_TESTIMONIAL: '/auth/testimonials/info',
    REPORT_ERROR: '/auth/report/error',
    GET_USER_ATTRIBUTES: '/auth/attributes',
  },
  KYC: {
    FIRST_STEP: '/auth/update-info?step=1',
    SECOND_STEP: '/auth/update-info?step=2',
    COMPLETE_PERSONA: '/auth/inquiries/completed',
    UPDATE_COMPANY_INFO: '/auth/update-company-info',
  },
  SYSTEM: {
    PAYMENT_SUBSCRIBE: '/system/subscribe',
    COUNTRY: '/system/countries', // GET /system/country-default-configs/
    GET_BANK_INFO: '/system/banks/', // GET system/banks/?currency=&country=
    SUBMIT_CONTACT: '/system/contacts/',
    GET_LANGUAGES: '/system/languages',
    GET_POPULAR_PLACE_BY_COUNTRY: '/system/popular-places/', // GET system/popular-places/?country=HK&language=zh-Hant-HK
    GET_POPULAR_BANK_BY_COUNTRY: '/system/popular-banks/', // GET system/popular-places/?country=HK&language=zh-Hant-HK
    SYSTEM_INTEREST: '/auth/system/interest',
    GET_SYSTEM_CONFIGS: '/system/configs',
    GET_SYSTEM_CONFIG: '/system/config',
    GET_SYSTEM_BADGES: '/system/badges',
    SECONDARY_MARKET_NOTIFICATION_SETTINGS: '/buy-investment-notifications/',
    WITHDRAW_EMAIL_SETTING: '/auth/update-withdraw-email-setting',
    RESERVE_BORROW_COINS: '/system/reserve-borrow-coins',
    BOOK_A_CALL: '/feedback',
    WITHDRAW_STABLE_COINS: '/system/reserve-withdraw-coins',
    WITHDRAW_STABLE_COIN_NETWORKS: '/system/reserve-withdraw-coin-networks',
    GET_CONTENT_VARIABLE: '/system/content-variable',
    TRACK_IP_INFO: '/api/system/ip-track-info',
    EVENTS: '/system/events',
  },
  SMS_AUTHEN: {
    REQUEST_VERIFY_PHONE_NUMBER: '/auth/verify-phone-number-on-request',
    CHECK_VERIFY_PHONE_NUMBER: '/auth/verify-phone-number-on-check',
    REQUEST_OFF_VERIFY_PHONE_NUMBER: '/auth/verify-phone-number-off-request',
    CHECK_OFF_VERIFY_PHONE_NUMBER: '/auth/verify-phone-number-off-check',
    REQUEST_OTP_TOKEN_PHONE_NUMBER: '/auth/request-otp-token',
    REQUEST_OTP_PHONE_PHONE_NUMBER: '/auth/request-otp-phone',
  },
  LANDING: {
    STATIC_PAGE: '/content/static-page/',
    FAQ: '/content/faq/',
    SUBSCRIBE_EMAIL: '/email/newsletters',
  },
  ME: {
    PROFILE: '/auth/profile', //kt
    SEND_VERIFY_EMAIL: '/auth/verification/email',
    VERIFY_EMAIL: '/auth/verification/email/check',
    SEND_VERIFY_PHONE: '/auth/verification/phone',
    VERIFY_PHONE: '/auth/verification/phone/check',
    KYC_UPDATE: '/auth/verification/kyc',
    CHECK_KYC_VERIFY: '/auth/verification/kyc/check',
    CHANGE_PASSWORD: '/auth/change-password',
    ORDER_HISTORY: '/reserve/history',
    TRANSFER_ORDER_HISTORY: '/admin/reserve-transfer-order',
    VERIFY_IDCARD: '/user/verify-id/',
    VERIFY_SELFIE: '/user/verify-selfie/',
    TWO_FACTOR: '/auth/authenticate2fa',
    LOGIN_HITORY: '/auth/login-history',
    REFERRALS: '/user/referrals/',
    FILE_UPLOAD: '/storage/upload',
    FILE_UPLOAD_OCR: '/storage/upload/ocr',
    VIEW_FILE: '/auth/tax-image',
    CURRENCY_LEVEL_LIMIT: '/system/currency-level-limits/',
    REFERRALS_EARNING_PROMOTION: '/exchange/promotions/',
    REFERRALS_EARNING_REFERRALS: '/exchange/referrals/',
    LOAN_GET_APPLICATION_DETAIL: '/loan-terms',
    LOAN_CANCEL_APPLICATION: '/loan-applications/{id}/cancel/',
    LOAN_TERM_PAY: '/loan-terms/{id}/pay/',
    OPEN_ORDERS: 'reserve/open',
    OPEN_OVERVIEW: 'reserve/get-latest-in-out',
    OVERVIEW_HISTORY: 'reserve/get-latest-in-out-list',
    OVERVIEW_SUMMARY: 'reserve/get-latest-in-out-summary',
    GET_RESERVE_TYPE_GROUP: 'reserve/get-reserve-type-group',
    INVEST_OPEN_ORDER: '/all-open-investments/',
    INTEREST_HISTORY: '/reserve/interest',
    INTEREST_CRYPTO_HISTORY: '/collateral-loan/collateral-user/flex-interest-history',
    COLLATERAL_HISTORY: '/collateral-loan/collateral-user/history',
    COLLATERAL_HISTORY_DETAIL: '/collateral-loan/collateral-user/history/detail',
    PROFILE_INTEREST: '/auth/profile-interest',
    DEPOSIT_HISTORY: '/all-deposits/',
    EXPORT_DEPOSIT_HISTORY: '/all-deposits-export/',
    EXPORT_WITHDRAW_HISTORY: '/all-withdraws-export/',
    EXPORT_OVERVIEW_HISTORY: '/reserve/export-latest-in-out',
    EXPORT_TOPUP_HISTORY: '/collateral-loan/matched/transactions/xlsx?type=5',
    EXPORT_COLLATERAL_HISTORY: '/collateral-loan/collateral-user/history/xlsx',
    EMAIL_CONFIRM_RESERVE: '/email-confirm-reserve',
    CONFIRM_COLLATERAL_CRYPTO_WITHDRAW: '/collateral-loan/collateral-withdraw-confirmed',
    CANCEL_COLLATERAL_WITHDRAW: '/collateral-loan/collateral-withdraw/{id}/reject',
    CANCEL_RESERVE_ORDER: '/reserve/{id}/reject',
    WITHDRAW_FEES: '/withdraw-fees',
    LOG_USER_ACTIVITY: '/auth/user-activity',
    GET_HOLDING_BALANCE_DETAIL: '/reserve/get-hold-withdraw-detail',
    UPDATE_MCT_SETTING_FEE: '/collateral-loan/mct-token/setting-fee',
    GET_MCT_REWARD_HISTORY: '/api/collateral-loan/mct-token/reward-by-date',
    GET_MCT_STAKING_HISTORY: '/api/collateral-saving/terms',//?symbol&status&from_date&to_date&page&limit
  },
  AGENT: {
    GET_AGENT_STATUS: '/agent-registrations/',
    USER_CRYPTO_WALLET: '/wallet-address-items/',
    USER_CRYPTO_WALLET_CURRENCY: '/wallet-currencies/',
    BECOME_AGENT: '/agent-registrations/',
    GET_SELL_ORDER: '/agent-sell-orders/',
    GET_SELL_ORDER_DETAIL: '/agent-sell-orders/{id}/bank/',
    GET_RISK_SELL_ORDER: '/risk-detector/?order_id=',
    REJECT_RISK_SELL_ORDER: '/agent-sell-orders/{id}/risk/',
    PICK_SELL_ORDER: '/agent-sell-orders/{id}/pick/',
    ACCEPT_SELL_ORDER: '/agent-sell-orders/{id}/fiat-transfer/',
    GET_BUY_ORDER: '/agent-buy-orders/',
    TERM_DEPOSITS: '/agent-term-deposits/',
    GET_LOAN: '/admin/loan',
    APPROVE_LOAN: '/admin/loan',
    APPROVE_TRANSFER_ORDER: 'admin/reserve-transfer-order/{id}/approve',
    CANCEL_TRANSFER_ORDER: 'admin/reserve-transfer-order/{id}/cancel',
  },
  DIRECT: {
    REDEEM: '/reserve/redeem', // POST
    TRANSFER: '/reserve/transfer', // POST
    CALC_FEE: '/reserve/calculate-fee',
    CALC_FEE_AMOUNT: '/reserve/calculate-fee-amount',
    GET_MAX_AMOUNT_TRANSFER_CRYPTO: '/reserve/calculate-amount-withdraw-crypto',
    GET_TOTAL_TRANSFER_TODAY: '/reserve/total-transfer-today',
    GET_AVAILABLE_WITHDRAW: '/reserve/get-available-withdraw',
    GET_ORDER_DETAIL: '/reserve/detail',
    GET_REMAIN_ACH: '/reserve/get-remain-ach',
    GET_CREDIT_SCORE: '/reserve/get-credit-score',
    SUBMIT_BANK_STATEMENT_BANK_VERIFY: '/reserve/bank-verify/upload/{id}',
    SUBMIT_BANK_STATEMENT_ACH_RISK: '/reserve/ach-risk/upload/{id}',
  },
  LOCAL: {
    INIT_ORDER: '/local/init-order',
    BUY_ORDER: 'local/buy',
    SELL_ORDER: 'local/sell',
    MAKE_BUY_ORDER: 'buy-orders/',
    MAKE_TRIAL_ORDER: '/term-deposits/trial/',
    MAKE_SELL_ORDER: 'sell-orders/',
    CANCEL_BUY_ORDER: 'buy-orders/{id}/cancel/',
    GET_ORDER_DETAIL: 'orders/{id}/bank/',
    CONFIRM_ORDER: 'buy-orders/{id}/fiat-transfer/',
    ACCEPT_ORDER: 'sell-orders/{id}/transfer/',
    CANCEL_SELL_ORDER: 'sell-orders/{id}/cancel/',
    FINISH_ORDER: 'local/finish-order',
    GET_FEE: 'local/fee',
    UPDATE_CURRENCY_RATE: '/local/exchange-rate',
    HISTORY: '/orders/',
    BANK_INFO: '/local/bank-info',
    GET_CURRENCY_LIST: '/currencies/',
    GET_BUY_AMOUNT: '/buy-amount/',
    GET_SELL_AMOUNT: '/sell-amount/',
    CHECK_VALID_WITHDRAW: '/reserve/check-valid-withdraw',
    SEND_ZENDESK_REQUEST: 'sell-orders/{id}/send-zendesk-request/',
    RECEIPT_REQUEST: '/reserve/receipt/upload/{id}',
    SEND_ZENDESK_REQUEST_RESERVE: '/reserve/receipt/request-zendesk/{id}',
  },
  LOAN: {
    GET_COLLATERALS: '/collateral-loan/collaterals',
    GET_ALL_COLLATERALS: '/collateral-loan/all-collaterals',
    REQUEST_LOAN: '/collateral-loan/create',
    GET_LOAN_DETAIL: '/collateral-loan/detail/{id}',
    CRYPTO_HISTORY: '/collateral-loan/history',
    PAY_INTEREST: '/collateral-loan/pay',
    PAY_FINISH: '/collateral-loan/finish',
    EDIT_RENEW: 'collateral-loan/edit',
    GET_EXCHANGE_RATE: '../crypto/sell-fiat-price/',
    GET_FIAT_EXCHANGE_RATE: '../crypto/fiat-exchange-rate/',
    GET_LOAN_HISTORY: '/collateral-loan/matched/transactions',
    GET_BEST_RATE: '../saving-p2p/public-best-invest-booking/',
    GET_COLLATERAL_LOAN_SUMMARY: '/collateral-loan/summary',
    GET_COLLATERAL_LOAN_SUMMARY_MOBILE: '/collateral-loan/summary-2',
    GET_AVAILABLE_BORROW_CREDIT: '/collateral-loan/collateral-credit/available',
    GET_BORROW_CREDIT_REPORT: '/collateral-loan/collateral-credit/summary',
    GET_BORROW_ISOLATED_REPORT: '/collateral-loan/collateral-isolated/summary',
    UPDATE_AUTO_TOPUP: '/collateral-loan/collateral-credit/auto-topup',
    UPDATE_AUTO_TOPUP_ISOLATED: '/collateral-loan/matched/isolated-auto-topup',
    GET_COLLATERAL_AMOUNT_TO_TOPUP: '/collateral-loan/collateral-credit/topup/:id',
    TOPUP_COLLATERAL_CREDIT: '/collateral-loan/collateral-credit/topup/:id',
    GET_AVAILABLE_RECALL_COLLATERAL: '/collateral-loan/collateral-credit/recall/:id',
    RECALL_EXCESS_COLLATERAL_CREDIT: '/collateral-loan/collateral-credit/recall/:id',
    ISOLATE_COLLATERAL_LOAN: '/collateral-loan/matched/isolated/:id',
    TOPUP_ISOLATED_COLLATERAL_LOAN: '/collateral-loan/matched/topup/:id',
    RECALL_ISOLATED_COLLATERAL_LOAN: '/collateral-loan/matched/recall/:id',
    DEPOSIT_COLLATERAL_MANUAL: '/collateral-loan/collateral-deposit-fixed',
    UPDATE_COLLATERAL_MANUAL_TX_HASH: '/collateral-loan/collateral-deposit-fixed-txhash',
    COLLATERAL_REMAIN_HOLDING: '/collateral-loan/collateral-remain-holding',
    GET_COLLATERALS_PROMOTIONS: '/collateral-promotions/promotions',
    CHECK_REFEREE_FIRST_BORROW: '/collateral-loan/check-referee-first-borrow',
    LOAN_CONTACT_US_REQUEST: '/saving-api/content/contact-us/',
    GET_LOAN_INTEREST_RATE_SETTINGS: '/collateral-loan/interest-rate-settings',
  },
  PRO_LENDING: {
    BORROW_BOOKINGS: '../saving-p2p/public-borrow-bookings/',
    GET_SECONDARY_INVESTMENTS: '../saving-p2p/public-single-borrow-bookings/',
    MATCH_STATS: '../saving-p2p/match-stats/',
    SELF_BOOKINGS: '../saving-p2p/top-self-matches/',
    OPEN_BOOKINGS: '../saving-p2p/bookings/',
    MATCHED_BOOKINGS: '../saving-p2p/top-matches/',
    MATCH_BORROW_BOOKING: '../saving-p2p/borrow-bookings/{id}/match/',
    CANCEL_INVEST_BOOKING: '../saving/term-deposits/{id}/cancel/',
    CANCEL_SECONDARY_INVEST_BOOKING: '../saving/term-deposits/{id}/cancel-borrow/',
    PREMATCH_BORROW_BOOKING: '../saving-p2p/borrow-bookings/prematch/',
    INVEST_BOOKINGS: '../saving-p2p/public-invest-bookings/',
    RESERVE_BOOKINGS: '../saving/term-deposit-reserves/',
    BOOKING_STATS: '../saving-p2p/booking-stats',
    MATCHES: '../saving-p2p/matches',
    COLLATERAL_ADDRESS: '/collateral-loan/collateral-address',
    COLLATERAL_BALANCE: '/collateral-loan/collateral-balance',
    COLLATERAL_PAY: 'collateral-loan/matched/pay/',
    COLLATERAL_GET: '/collateral-loan-pro/get-collateral/',
    COLLATERAL_AUTO_TOPUP_POST: '/collateral-loan/auto-topup/',
    COLLATERAL_AUTO_RENEW_POST: '/collateral-loan/update-renew/',
    COLLATERAL_PAYOFF: '/collateral-loan/payoff/',
    COLLATERAL_CANCEL: '/collateral-loan/cancel/',
    BORROW_CANCEL: '/collateral-loan/exchange/cancel/',
    COLLATERAL_DELETE: '/collateral-loan/delete/',
    COLLATERAL_TOPUP: '/collateral-loan/topup/',
    CANCEL_COLLATERAL_TOPUP: '/collateral-loan/matched/cancel/',
    COLLATERAL_WITHDRAW: '/collateral-loan/collateral-withdraw',
    BALANCE_REWARD_GET: '../reward/reward-user/',
    APPLY_REWARD_TO_BORROW: '/collateral-loan/reward-redeem/',
    APPLY_REWARD_TO_INVEST: '../saving/term-deposits/{id}/apply-referral/',
    COLLATERAL_RECOMMENDED_GET: '/collateral-loan/topup/',
    GET_COLLATERAL_WITHDRAW_FEE: '/collateral-loan/collateral-withdraw-fee',
    GET_BNE_COLLATERAL_WITHDRAW_FEE: '/collateral-loan/bne-collateral-withdraw-fee',
    GET_LIST_PURCHASSE_2ND_INVESTMENT: '/purchasing-void-term-deposit/',
    GET_LIST_PURCHASSE_LO_2ND_INVESTMENT: '/lo-investment/purchasing-void-investments/',
  },
  MEMBERSHIP: {
    INFO: '../reward/membership/',
    HISTORY: '../reward/membership-history/',
    REVIEW_HISTORY: '../reward/membership-history/{id}/review/',
    POINTS_HISTORY: '../reward/membership-point-history/',
  },
  REFERRAL: {
    REFERRAL_USERS: '/saving-api/reward/paging-referral-users/',
    REFERRAL_HISTORY: '/saving-api/reward/paging-referral-lending-rewards/',
    REFERRAL_PROMOTION: '/saving-api/reward/referral-promotion/',
    UPDATE_REFERRAL_CODE: '/auth/update-referralcode',
    REFERRAL_INVITATION_REMIND: '/saving-api/saving/referral-invitation/remind-2/',
    GET_REFERRAL_PROMOTION_HISTORY_ACTION: '/saving-api/reward/referral-lending-referee-rewards/',
    REFERRAL_SUMMARY: '/saving-api/reward/referral-lending-rewards/summary/',
    PROMOTION_REFERRAL_REWARDS: '/saving-api/reward/referral-vouchers/',
    MCT_REFERRAL_REWARDS: '/saving-api/reward/referral-mct-reward/',
    MCT_REFERRAL_REWARD_SUMMARY_BY_USER: '/saving-api/reward/referral-mct-reward/summary/',
    CRYPTO_REFERRAL_REWARD_SUMMARY_BY_USER: '/saving-api/reward/referral-crypto-reward/summary/',
    CRYPTO_REFERRAL_REWARD_SUMMARY_TOTAL_BY_USER: '/saving-api/reward/referral-crypto-reward/total/',
    CLAIM_AIRDROP_REWARDS: '/api/collateral-loan/mct-airdrop/claim/{id}',
    CRYPTO_REFERRAL_REWARDS: '/saving-api/reward/referral-crypto-reward/',
  },
  STATISTIC: {
    STATISTIC_INVESMENT: '/saving-api/report/statistic-investment/',
    STATISTIC_INVESMENT_BY_MONTH: '/saving-api/report/statistic-investment-by-month/',
    STATISTIC_INVESMENT_BY_COUNTRY: '/saving-api/report/statistic-investment-by-country/',
  },
  AFFILIATES: {
    APPLY_AFFILIATE: '../reward/public-affiliates/',
    REWARD_SUMMARY: '../reward/referral-lending-rewards/summary/',
    AFFILIATE_REWARDS: '../reward/affiliate-rewards/',
    AFFILIATE_REWARD_SUMMARY: '../reward/affiliate-period-reward-summaries/',
    AFFILIATE_SUMMARY: '../reward/affiliate-summary/',
  },
  HELP_AND_SUPPORT: {
    CATEGORIES: '/blog-api/faqcategories?_sort=order:DESC&active=true',
    CATEGORY: '/blog-api/faqs?_sort=order:DESC&active=true&faqcategories={id}',
    SEARCH: '/blog-api/faqs?_sort=order:DESC&active=true&_q={q}',
  },
  GLOSSARY: 'glossaries',
  LO_LOAN: {
    GET_LOAN_ORIGINATORS: '/lo-loan/loan-originators/',
    GET_LOANS: '/lo-loan/loans/',
    INVEST_LO_LOANS: '/lo-loan/loans/{id}/invest/',
    GET_LOAN_ORIGINATOR_DESCRIPTION: '/blog-api/loanorgs/',
    GET_AUTO_INVEST_INVESTED_LOANS: '/lo-loan/auto-invested-loans/',
    GET_LOAN_OPTIONS_SETTING: '/lo-loan/loan-options/',
    IMPORT_FILE_LOAN_PAYMENT: '/lo-loan/loan-payment-file-import/',
  },
  LO_INVESTMENT: {
    CREATE_AUTO_INVEST: '/lo-investment/investment-settings/create-package/',
    UPDATE_AUTO_INVEST: '/lo-investment/investment-settings/{id}/update-package/',
    GET_AUTO_INVEST_PLANS: '/lo-investment/investment-packages/',
    GET_LOAN_HISTORY_SETTING: '/lo-investment/investment-settings/',
    GET_LOAN_HISTORIES: '/lo-investment/investments/',
    GET_LO_INVEST_SUMMARY: '/lo-investment/investment-account-summary/',
    VIEW_LO_ASSIGNMENT_AGREEMENT: '/lo-investment/assignment-agreement/{id}/',
    VIEW_LO_INVEST_PAYMENT_SCHEDULE: '/lo-investment/payment-schedule/',
    GET_AUTO_INVEST_ASSIGNMENT_AGREEMENTS: '/lo-investment/auto-invest-lo/{id}/',
    GET_LO_ASSIGNMENT_AGREEMENT: '/lo-investment/assignment-agreement-lo/{id}/',
    GET_LOAN_ASSIGNMENT_AGREEMENT: '/lo-investment/assignment-agreement-loan/{id}/',
    GET_LOAN_ASSIGNMENT_AGREEMENT_SECONDARY_MARKET: '/lo-investment/assignment-agreement-2nd/{id}/',
    GET_INVESTMENT_OVERVIEW: '/lo-investment/investment-overview/',
    SELL_LO_INVESTMENT: '/lo-investment/investments/{id}/void/',
    CANCEL_SELL_LO_INVESTMENT: '/lo-investment/investments/{id}/cancel-void/',
    CANCEL_PENDING_BUY_LO_INVESTMENT: 'lo-investment/investments/{id}/cancel-purchase-void/',
    GET_LO_SECONDARY_INVESTMENTS: '/lo-investment/voiding-investments/',
    BUY_LO_SECONDARY_INVESTMENT: '/lo-investment/investments/{id}/purchase-void/',
  },
  LO_CASHFLOW: {
    GET_LOAN_FUNDS: '/lo-cashflow/loan-funds/',
    GET_LOAN_FUND_DETAILS: '/lo-cashflow/loan-fund-details/',
    EXPORT_LOAN_FUND_DETAILS: '/lo-cashflow/export-loan-fund-details/',
    GET_LOAN_PAYMENTS: '/lo-cashflow/loan-payments/',
    GET_LOAN_PAYMENT_DETAILS: '/lo-cashflow/investment-payments/',
    EXPORT_LOAN_PAYMENT_DETAILS: '/lo-cashflow/export-investment-payments/',
    GET_SETTLEMENTS: '/lo-cashflow/settlements/',
    GET_SETTLEMENT_DETAIL: '/lo-cashflow/settlements/{id}/transaction/',
    APPROVE_LOAN_FUND: '/lo-cashflow/loan-funds/{id}/receive/',
  },
  BADGE: {
    USER_BADGES: '/auth/badges',
    GET_BADGE_ACTIONS: '/auth/badge-last-action',
    SUBMIT_BADGE_PROOF: '/auth/badge',
    UPDATE_BADGE_ACTION: '/auth/badge-action',
    CHECK_BADGE: '/auth/check-badge',
    GET_BADGE_HISTORY: '/auth/badge-history',
    GET_BADGE_SUMMARY: '/auth/badge-summary',
    BADGE_REMINDER: '/auth/badge-reminder',
  },
  SPENDING: {
    CATEGORIES: '/spending-api/spending/categories/',
    PRODUCTS: '/spending-api/spending/products/',
    SIMPLE_PRODUCTS: '/spending-api/spending/simple-products/',
    CREATE_ORDER: '/spending-api/spending/create-order/',
    REVIEWS: '/spending-api/spending/reviews/',
    GET_REVIEWS: '/spending-api/spending/review-items/',
    ORDERS: '/spending-api/spending/orders/',
    //Debit card
    GET_DEBIT_CARDS: '/spending-api/debit/cards/',
    GET_ADD_DEBIT_CARD_STEP: '/spending-api/debit/cards/step/',
    DEBIT_CARD_VERIFY: '/spending-api/debit/cards/verify/',
    DEBIT_CARD_REGISTER: '/spending-api/debit/cards/register/',
    DEBIT_CARD_ISSUE: '/spending-api/debit/cards/issue/',
    DEBIT_CARD_INFO: '/spending-api/debit/cards/{id}/info/',
    DEBIT_CARD_BALANCE: '/spending-api/debit/cards/{id}/balance/',
    ADD_FUND: '/spending-api/debit/cards/{id}/add-fund/',
    DEBIT_CARD_FUND_REQUESTS: '/spending-api/debit/fund-requests/',
    DEBIT_CARD_TRANSACTIONS: '/spending-api/debit/cards/{id}/transactions/',
    GET_CREDIT_BALANCE: '/spending-api/credit/available-credit/',
    GET_CREDITS: '/spending-api/credit/credits/',
    GET_CREDIT_PAYMENTS: '/spending-api/credit/payments/',
    GET_CREDIT_PAYMENT_DETAIL: '/spending-api/credit/payments/{id}/details/',
    GET_PAID_CREDITS: '/spending-api/credit/pays/',
    CREDIT_SUMMARY: '/spending-api/credit/credits/summary/',
    PAY_ALL_CREDITS: '/spending-api/credit/credits/pay/',
    PAY_ITEM_CREDIT: '/spending-api/credit/credits/{id}/pay-item/',
    DEBIT_STATEMENT_PERIOD: '/spending-api/debit/cards/statement-period/',
    DEBIT_STATEMENT: '/spending-api/debit/cards/statement/',
    DEBIT_CARD_ACTIVATE_PHYSICAL: '/spending-api/debit/cards/{id}/activate-physical/',
    DEBIT_CARD_ORDER_PHYSICAL: '/spending-api/debit/cards/{id}/order-physical/',
    DEBIT_CARD_VERIFY_PHONE: '/spending-api/debit/cards/{id}/verify-phone/',
    DEBIT_AVAILABLE_WITHDRAW: '/spending-api/debit/available-balance/',
    DEBIT_VALIDATE_REQUIREMENT: '/spending-api/debit/validate/',
    //Rewards,
    REWARD_SUMMARY: '/spending-api/reward/rewards/summary/',
    REWARD_CLAIM_ALL: '/spending-api/reward/rewards/claim-all/',
    REWARD_CLAIM: '/spending-api/reward/rewards/{id}/claim/',
  },
  LIVE_CHAT: {
    PUBLIC_SUBSCRIBES: '/chat-api/topics/public/subscribes',
    LIST_SUBSCRIBES: '/chat-api/topics/users/subscribes',
    CHAT_HISTORY: '/chat-api/topics/users/messages',
    CHAT_HISTORY_PUBLIC: '/chat-api/topics/public/messages',
    UNREAD_MESSAGES: '/chat-api/topics/users/unread',
    UNREAD_THREAD_MESSAGES: '/chat-api/topics/users/thread/unread',
    UPDATE_SEEN_THREAD: '/chat-api/topics/users/thread/update-seen',
    UPLOAD_FILES: '/api/auth/upload-files',
    SUPPORT_TOPIC: '/chat-api/topics/users/support-topic',
    UPDATE_SEEN: '/chat-api/topics/users/update-seen',
    DELETE_MESSAGE: '/chat-api/topics/users/:id/delete',
    GET_MESSAGE_INFO: '/chat-api/topics/users/messages',
    REACTION: '/chat-api/topics/users/react',
    BLOCK: '/chat-api/topics/users/block',
    PIN: '/chat-api/topics/users/pin',
    UNPIN: '/chat-api/topics/users/unpin',
    LIVE_CHAT_QUIZ: '/saving-api/content/live-chat-quiz/',
    STATISTIC: '/chat-api/topics/public/statistic',
    SEARCH_USER: '/chat-api/user/search',
    LIVE_CHAT_BADGES: '/saving-api/content/live-chat-badges/',
    MARK_UNREAD: '/chat-api/topics/users/mark-unread',
  },
  COLLATERAL_PAIRS: {
    GET_PAIRS: '/collateral-pairs/pairs',
    CONVERT_FIAT_TO_CRYPTO: '/collateral-loan/convert-fiat2crypto',
    CONVERT_CRYPTO_TO_FIAT: '/collateral-loan/convert-crypto2fiat',
    CONVERT_FIAT_TO_SHORT: '/collateral-pairs/convert-fiat2crypto-and-transfer',
    CONVERT_SHORT_TO_FIAT: '/collateral-pairs/recall-and-convert-crypto2fiat',
    BORROW_PAIR: '/collateral-pairs/borrow',
    SELL_PAIR: '/collateral-pairs/sell',
    GET_PAIRS_BALANCE: '/collateral-pairs/users',
    GET_SHORT_BALANCE_TRIAL: '/collateral-pairs/trials',
    TRANSFER_TO_PAIRS_BALANCE: '/collateral-pairs/transfer',
    RECALL_FROM_PAIRS_BALANCE: '/collateral-pairs/recall',
    GET_COLLATERAL_PRICE: '/collateral-loan/collateral-price',
    REPAY_BY_PAIR_BALANCE: '/collateral-pairs/sell',
    REPAY_BY_COIN_BALANCE: '/collateral-pairs/repay',
    UPDATE_TP_SL: '/collateral-pairs/price',
    USER_TRANSACTIONS: '/collateral-pairs/user-transactions',
    USER_TRANSACTIONS_BY_DATE: '/collateral-pairs/user-transactions-by-date',
    USER_PNL: '/collateral-pairs/user-pnls',
    CANCEL_ORDER: '/collateral-pairs/cancel',
  },
  CRYPTO_FUTURE: {
    GET_COINS: '/crypto-future-api/public/settings',
    ORDERS: '/crypto-future-api/orders/',
    END_ORDER: '/crypto-future-api/orders/{id}/end',
    TRIAL_BALANCE: '/crypto-future-api/trial/users',
    STABLE_COLLATERAL: '/collateral-loan/future-stable-collaterals'
  },
  SHORT_SIMPLE: {
    CREATE_ORDER: '/collateral-pairs/orders/create',
    GET_ORDERS: '/collateral-pairs/orders/list',
    REPAY: '/collateral-pairs/orders/sell',
    TOP_UP: '/collateral-pairs/orders/top-up',
    RECALL: '/collateral-pairs/orders/recall',
    TP_SL: '/collateral-pairs/orders/price',
  },
  LOTTERY_SAVING: {
    GET_TERM: '/saving-lottery-api/public/term-settings',
    SAVING_LOTTERY_TERM: '/saving-lottery-api/auth/terms',
    GET_SAVING_LOTTERY_TERM_DETAIL: '/saving-lottery-api/auth/pending-tickets-by-ref',
    GET_PICKING_PERIOD: '/saving-lottery-api/public/picking-period',
    PICK_PENDING_TICKETS_BY_REF: '/saving-lottery-api/auth/pick-pending-tickets-by-ref',
    GET_LATEST_REWARD: '/saving-lottery-api/public/latest-rewards',
    GET_LAST_WEEK_REWARD: '/saving-lottery-api/public/last-reward-periods',
    GET_PICKED_PERIOD: 'saving-lottery-api/public/picked-period',
    GET_CLOSED_PERIODS: 'saving-lottery-api/public/closed-periods',
    GET_PERIOD_REWARD_SUMMARY: 'saving-lottery-api/public/period-reward-summary',
    GET_INVESTMENT_SUMMARY: 'saving-lottery-api/auth/terms-summary',
    CANCEL_INVESTMENT: '/saving-lottery-api/auth/terms-void',
    AUTO_REINVEST: '/saving-lottery-api/auth/terms-reinvest-enabled',
    EXPORT_INVESTMENTS: '/saving-lottery-api/auth/terms-export',
    GET_LOTTERY_REWARDS: '/saving-lottery-api/auth/rewards',
    GET_LOTTERY_TICKETS: '/saving-lottery-api/auth/tickets',
    GET_LENDING_LOTTERY_SUMMARY: '/saving-lottery-api/auth/summary',
    GET_REWARD_RULES: '/saving-lottery-api/public/reward-rules',
    GET_DRAW_TIME: '/saving-lottery-api/public/next-draw-time',
    GET_BALANCE_INVEST_LOTTERY: '/saving-lottery-api/auth/balance',
    GET_LOTTERY_TICKETS_BY_PERIOD: '/saving-lottery-api/auth/period-tickets',
    GET_PENDING_TICKETS: '/saving-lottery-api/auth/pending-tickets',
    PICKED_PERIOD_VALID: '/saving-lottery-api/auth/picked-period-valid',
    GET_USER_INFO: '/saving-lottery-api/auth/users',
    TRACKING_VIEW_LOTTERY_DRAW: '/saving-lottery-api/auth/picked-period-view-draw',
    GET_LENDING_LOTTERY_OVERVIEW: '/saving-lottery-api/public/overview',
    GET_USER_ACTIVE_TICKETS: '/saving-lottery-api/auth/picked-period-tickets',
    GET_USER_TICKETS: '/saving-lottery-api/auth/closed-periods',
    LOTTERY_REFERRAL_INVITATION: '/saving-api/saving/referral-invitation/lottery/',
    GET_REFERRAL_LOTTERY_USER: '/saving-api/reward/referral-lottery-users/',
    CHECK_REWARD_AFFILIATE_VALID: '/saving-lottery-api/auth/check-reward-affiliate-valid',
    GET_RPOMOTION_XMAS_2021: '/saving-lottery-api/public/promotions/xmas-2021-detail',
    CHECK_HAVE_TICKET_XMAS_2021: '/saving-lottery-api/auth/promotions/xmas-2021-check',
  },
  NFT_REWARDS: {
    GET_USER_NFTS_REWARDED: '/nft-api/users/info',
    GET_USER_NFTS_CAN_GET: '/nft-api/users/reward',
    REDEEM_NFT_REWARD: '/nft-api/users/reward/redeem',
    TRANSFER_NFT: '/nft-api/users/transfer',
  },
  CRYPTO_SWAP: {
    GET_SWAP_COLLATERALS: '/collateral-loan/swap-collaterals',
    CREATE_SWAP_ORDER: '/collateral-loan/collateral-swap/create',
    SWAP_ORDERS: '/api/collateral-loan/collateral-swap/orders',
    GET_SWAP_DISCOUNT_INFO: '/api/collateral-loan/collateral-swap/discount-fee-rate',
  },
  PRIZE_WHEEL: {
    GET_GIFT_CHAT: '/gift/chats',
    GET_RESULTS: '/gift/results',
    GET_NUMBER_TICKET: '/gift/ticket',
    GET_LIST_REWARD: '/gift/list-reward',
    REDEEM_TICKET: '/gift/redeem-ticket',
    REWARD_HISTORY: '/api/gift/reward-history',
    GET_MISSIONS: '/gift/report-earn-rules',
    JOIN_DISCORD: '/gift/join-discord',
    SHARE_SOCIAL: '/gift/share-public-social-media',
    SHARE_LINK: '/gift/share-link',
    EXCHANGE_MEMBERSHIP_TICKET: '/gift/exchange-membership-point-to-ticket',
    CLAIM_FREE_TICKETS: '/gift/claim-free-ticket',
  },
  AIRDROP: {
    GET_NUMBER_TICKET: '/collateral-loan/mct-airdrop/users',
    LIST_REWARD: '/collateral-loan/mct-airdrop/list-reward',
    REDEEM_CODE: '/collateral-loan/mct-airdrop/redeem-code',
    REDEEM_TICKET: '/collateral-loan/mct-airdrop/redeem-ticket',
    REWARD_HISTORY: '/collateral-loan/mct-airdrop/reward-history',
    GET_CHATS: '/collateral-loan/mct-airdrop/chats',
  },
  CONSTANT_TOKEN: {
    GET_MCT_AIRDROP_HISTORY: '/api/collateral-loan/collateral-user/history?type=83',
    GET_MCT_AIRDROP_SUMMARY: '/api/collateral-loan/mct-airdrop/summary',
    CLAIM_AIRDROP_REWARDS: '/api/collateral-loan/mct-airdrop/claim/{id}',
    GET_LOYALTY_BENEFIT: '/collateral-loan/mct-token/loyalty-benefits',
    GET_MCT_LOYALTY_PROGRAM_INFO: '/collateral-loan/mct-token/loyalty-programs',
    GET_BUY_MCT_PRICE: '/api/collateral-loan/collateral-swap/mct-price',
  },
  MCT_STAKING: {
    TERM_SETTING: '/collateral-saving/settings',
    TERM_STAKING: '/collateral-saving/terms',
    OVERVIEW: '/collateral-saving/overview',
  },
  REDEEM_MCT: {
    REDEEM_REWARD: '/collateral-loan/mct-airdrop/redeem-reward-code'
  },
  MCT_INTERNAL_BONUS: {
    GET_INTERNAL_BALANCE: '/api/collateral-loan/mct-token/internal-lock-balance',
    GET_INTERNAL_LOCK_INTEREST: '/api/collateral-loan/mct-token/internal-lock-interest',
    GET_INTERNAL_LOCK_BALANCE_HISTORY: '/api/collateral-loan/collateral-user/history?type=64,66&symbol=MCT',
    GET_INTERNAL_BACK_BALANCE_HISTORY: '/api/collateral-loan/collateral-user/history?type=67,69&symbol=MCT',
  },
  MC_NFT: {
    GET_LIST_MASCOTS: '/nft-api/mascot/all-mascot',
    GET_LIST_MASCOTS_GEN1: '/nft-api/mascot/gen1',
    GET_LIST_OPEN_ORDERS: '/nft-api/mascot/open-orders',
    ADD_BID_TO_ORDER: '/nft-api/mascot/bid',
    UPDATE_BID_TO_ORDER: '/nft-api/mascot/edit-bid',
    CANCEL_BID_ORDER: '/nft-api/mascot/cancel-bid',
    GET_LIST_BID_BY_ORDER: '/nft-api/mascot/bids',
    GET_MY_BID_BY_ORDER: '/nft-api/mascot/my-bid',
    GET_LIST_ORDER_BY_NFT: '/nft-api/mascot/history',
    GET_MASCOT_DETAIL: '/nft-api/mascot/mascot',
    GET_MASCOT_DEPOSIT_ORDER: '/nft-api/mascot/deposit',
    WITHDRAW_MASCOT: '/nft-api/mascot/withdraw',
    REDEEM_MASCOT: '/nft-api/mascot/redeem',
    GET_LIST_MY_MASCOT_NFT: '/nft-api/mascot/my-mascot',
    CANCEL_BID: '/nft-api/mascot/cancel-bid',
    GET_MY_WITHDRAW_HISTORY: '/nft-api/mascot/my-history',
    GET_MY_AUCTION_HISTORY: '/nft-api/mascot/my-bid-history',
    GET_MASCOT_STORY: '/nft-api/mascot/story',
    GET_MY_BID_MASCOT: '/nft-api/mascot/my-pending-bids',
    GET_GEN1: '/nft-api/mascot/gen1',
    GET_GEN1_RARITY: '/nft-api/mascot/gen1/rarity',
  }
};