const TAG = 'SMS_AUTHENTICATION';
export const REQUEST_VERIFY_PHONE_NUMBER = `${TAG}_REQUEST_VERIFY_PHONE_NUMBER`;
export const CHECK_VERIFY_PHONE_NUMBER = `${TAG}_CHECK_VERIFY_PHONE_NUMBER`;
export const REQUEST_OFF_VERIFY_PHONE_NUMBER = `${TAG}_REQUEST_OFF_VERIFY_PHONE_NUMBER`;
export const CHECK_OFF_VERIFY_PHONE_NUMBER = `${TAG}_CHECK_OFF_VERIFY_PHONE_NUMBER`;
export const REQUEST_OTP_TOKEN_PHONE_NUMBER = `${TAG}_REQUEST_OTP_TOKEN_PHONE_NUMBER`;
export const REQUEST_OTP_PHONE_PHONE_NUMBER = `${TAG}_REQUEST_OTP_PHONE_PHONE_NUMBER`;

export const SHOW_INPUT_SMS_DIALOG = 'SHOW_INPUT_SMS_DIALOG';
export const HIDE_INPUT_SMS_DIALOG = 'HIDE_INPUT_SMS_DIALOG';
export const AUTHORIZED_INPUT_SMS_DIALOG = 'AUTHORIZED_INPUT_SMS_DIALOG';
