import { APP } from 'src/resources/constants/app';
import { API_URL } from 'src/resources/constants/url';
import LocalStore from 'src/services/localStore';
import authentication from 'src/utils/authentication';

const requestHeaderInterceptor = config => {
  const ipAdress = LocalStore.get(APP.IP_INFO)?.ip_address;
  config.headers.ip = ipAdress;
  config.headers.platform = 'web';
  config.headers.os = window && window.navigator.platform;

  const countryCode = LocalStore.get(APP.IP_INFO)?.country_code;
  if (countryCode) {
    config.headers.Country = countryCode;
  }
  return config;
};

const responseSuccessInterceptor = response => {
  try {
    const { data } = response;
    return data;
  } catch (e) {
    return null;
  }
};

const responseErrorInterceptor = error => {
  try {
    if (error && error.response) {
      const {
        response: { status, data, statusText }
      } = error;
      const errorData = {
        error: true,
        status: status, // status code
        data: data,
        code: data?.code, // error code
        message: data?.message, // error msg
        statusText: statusText
      };
      if (status === 401 && error?.config?.url !== API_URL.USER.USER_SIGN_IN) {
        authentication.removeAccessToken();
        return Promise.reject({
          sessionExpired: true,
          ...errorData
        });
      }
      console.warn('Response error', error);
      return Promise.reject(errorData);
    }
    if (error?.request) {
      return Promise.reject({
        error: true,
        message: error?.message,
        code: 'request_failed'
      });
    }
  } catch (e) {
    return Promise.reject(e);
  }
}; 

export { requestHeaderInterceptor, responseSuccessInterceptor, responseErrorInterceptor };