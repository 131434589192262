import { HIDE_TOP_BANNER, SHOW_TOP_BANNER } from './type';

const initState = {
  isShow: false,
  type: null,
};
export default (state = initState, { type, bannerType }) => {
  switch (type) {
    case SHOW_TOP_BANNER:
      return {
        ...state,
        isShow: true,
        type: bannerType,
      };
    case HIDE_TOP_BANNER:
      return { ...initState };
    default:
      return state;
  }
};
