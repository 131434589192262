import AmountFormater from 'src/utils/format/amount';
import { camelizeKeys } from 'src/utils/object';

class User {
  static userAuth(data = {}) {
    return {
      token: data.Token,
      expired: data.Expired,
    };
  }

  static userProfile(data = {}) {
    return {
      id: data.ID,
      userName: data.UserName,
      fullName: data.FullName,
      firstName: data.FirstName,
      lastName: data.LastName,
      middleName: data.MiddleName,
      email: data.Email,
      roleID: data.RoleID,
      constantBalance: AmountFormater.fromServer(data.ConstantBalance) || 0,
      constantBalanceHolding: AmountFormater.fromServer(data.ConstantBalanceHolding) || 0,
      verifiedLevel: data.VerifiedLevel,
      isStaked: data.IsStaked,
      gender: data.Gender,
      dob: data.DOB,
      agentUser: data.AgentUser,
      taxCountry: data.TaxCountry,
      taxImage: data.TaxImage,
      taxIDNumber: data.TaxIDNumber,
      taxIDState: data.TaxIDState,
      addressStreet1: data.AddressStreet1,
      addressStreet2: data.AddressStreet2,
      addressRegion: data.AddressRegion,
      addressCity: data.AddressCity,
      addressPostalCode: data.AddressPostalCode,
      addressCountry: data.AddressCountry,
      phoneNumber: data.PhoneNumber,
      phoneCountryCode: data.PhoneCountryCode,
      documents: data.Documents,
      primetrustContactID: data.PrimetrustContactID,
      referralCode: data.ReferralCode,
      proSavingUser: data.ProSavingUser,
      wallets: data.Wallets,
      _country: data.TaxCountry || data.AddressCountry,
      TwoFAOn: data.TwoFAOn,
      AuthPhoneNumber: data.AuthPhoneNumber,
      DiscardNote: data.DiscardNote,
      IsSocial: data.IsSocial,
      SocialType: data.SocialType,
      Membership: data.Membership,
      flexOrPrimetrust: data.FlexOrPrimetrust,
      bankAccountBalance: AmountFormater.fromServer(data.BankAccountBalance) || 0,
      primetrustAccountStatus: data.PrimetrustAccountStatus,
      bankAccountNumber: data.BankAccountNumber,
      affiliateUser: data.AffiliateUser,
      withdrawConfirmedEmailOn: data.WithdrawConfirmedEmailOn,
      suspendWithdrawalTo: data.SuspendWithdrawalTo,
      loUser: data.LoUser,
      createdAt: data.CreatedAt,
      userChatPermission: camelizeKeys(data.UserChatPermission),
      avatarUrl: data.AvatarURL,
      extraInfo: camelizeKeys(data.ExtraInfo),
      trialAmount: AmountFormater.fromServer(data.TrialAmount) || 0,
      accountType: data.AccountType,
      relatedContact: data.RelatedContact,
      regionOfFormation: data.RegionOfFormation,
    };
  }

  static userBank(data = {}) {
    return {
      id: data.ID,
      createdAt: data.CreatedAt,
      updatedAt: data.UpdatedAt,
      deletedAt: data.DeletedAt,
      user: data.User,
      userID: data.UserID,
      achCheckType: data.AchCheckType,
      routingNumber: data.RoutingNumber,
      swiftCode: data.SwiftCode,
      bankName: data.BankName,
      bankAccountName: data.BankAccountName,
      bankAccountCurrency: data.BankAccountCurrency,
      bankAccountNumber: data.BankAccountNumber,
      bankAccountType: data.BankAccountType,
      isDefault: data.IsDefault,
      bankAnotherInfo: data.BankAnotherInfo,
      bankCountry: data.BankCountry,
      verified: data.Verified,
      BeneficiaryAddressStreet1: data.BeneficiaryAddressStreet1,
      BeneficiaryAddressStreet2: data.BeneficiaryAddressStreet2,
      BeneficiaryAddressCity: data.BeneficiaryAddressCity,
      BeneficiaryAddressCountry: data.BeneficiaryAddressCountry,
      BeneficiaryAddressRegion: data.BeneficiaryAddressRegion,
      BeneficiaryAddressPostalCode: data.BeneficiaryAddressPostalCode,
      iban: data.Iban,
    };
  }
}

export default User;
