import {
  GET_REFERRAL_HISTORY_ACTION,
  GET_REFERRAL_PROMOTION_HISTORY_ACTION,
  GET_REFERRAL_USERS_ACTION,
  SAVE_REFERRAL_REWARD_ACTION,
} from './action';

const initState = {
  referralUsers: [],
  referralHistory: [],
  referralUsersCount: 0,
  referralHistoryCount: 0,
  isReloadReferralUsers: false,
  referralReward: 0,
  referralFlexReward: 0,
};
export default (state = initState, params) => {
  switch (params.type) {
    case `${GET_REFERRAL_USERS_ACTION}_SUCCESS`:
      return {
        ...state,
        referralUsersCount: params.data.count,
        referralUsers: [...params.data.results],
      };
    case `${GET_REFERRAL_HISTORY_ACTION}_SUCCESS`:
      return {
        ...state,
        referralHistoryCount: params.data.count,
        referralHistory: [...params.data.results],
      };
    case `${GET_REFERRAL_PROMOTION_HISTORY_ACTION}_SUCCESS`:
      return {
        ...state,
        referralPromotionHistoryCount: params.data.count,
        referralPromotionHistory: [...params.data.results],
      };
    case 'RELOAD_REFERRAL_USERS' : {
      return {
        ...state,
        isReloadReferralUsers: params.value,
      };
    }
    case SAVE_REFERRAL_REWARD_ACTION : {
      const { referralReward, referralFlexReward } = params.data;
      return {
        ...state,
        referralReward,
        referralFlexReward,
      };
    }
    default:
      return state;
  }
};
