import React from 'react';
import LabelLang from 'src/lang/components/LabelLang';

export const RESERVE_TYPE = {
  BUY: 0,
  SELL: 1, //withdraw fiat (redeem)
  TRANSFER: 2,
  RESERVE_TYPE_TERM_DEPOSIT: 3,
  RESERVE_TYPE_INTEREST: 4,
  RESERVE_TYPE_FEE: 5,
  RESERVE_TYPE_IMPORT_CONSTANT: 6,
  RESERVE_TYPE_DEPOSIT: 7,
  RESERVE_TYPE_WITHDRAW: 8, //withdraw fiat (sell-order)
  RESERVE_TYPE_RISK: 9,
  RESERVE_TYPE_BORROW: 10,
  RESERVE_TYPE_PAY: 11,
  RESERVE_TYPE_REWARD: 12,
  RESERVE_TYPE_INTEREST_BALANCE: 13,
  RESERVE_TYPE_EARNED_FROM_REFERRAL: 14,
  RESERVE_TYPE_MEMBERSHIP_REWARD: 15,
  RESERVE_TYPE_REFERRAL_TERM_DEPOSIT: 16,
  RESERVE_TYPE_REFERRAL_REWARD: 17,
  RESERVE_TYPE_SERVICE_TIME_INTEREST: 18,
  RESERVE_TYPE_AJUST_INCREASE: 19,
  RESERVE_TYPE_AJUST_DECREASE: 20,
  RESERVE_TYPE_REFERRAL_BONUS: 21,
  RESERVE_TYPE_BORROW_EXCHANGE: 22,
  RESERVE_TYPE_BORROW_EXCHANGE_BACK: 23,
  LO_FUND: 24,
  LO_PAYMENT: 25,
  RESERVE_TYPE_LO_INVESTMENT: 26,
  RESERVE_TYPE_LO_INVESTMENT_INTERES: 27,
  BADGE_REWARD: 33,
  RESERVE_TYPE_BONUS: 101,
  RESERVE_TYPE_COUPON_SIGNUP: 102,
  INSTANT_CREDIT_INTEREST: 105,
};

export const ORDER_STATUS = {
  PENDING: 0,
  PURCHASE: 1,
  MINTING: 2,
  BURNING: 3,
  TRANSFER: 4,
  REDEEMING: 5,
  CANCELLED: 6,
  DONE: 7,
  HOLDING: 8,
  TRANSFER_FAILED: 11,
  APPROVING: 12,
  WAITING_APPROVING: 13,
  TRANSFERING: 14,
  CONFIRMING: 15,
  ORDER_TIMED_OUT: 16,
};

export const ACH_CHECK_TYPE = {
  business: {
    name: 'Business',
    value: 'business'
  },
  personal: {
    name: 'Personal',
    value: 'personal'
  }
};

export const BANK_ACCOUNT_TYPE = {
  checking: {
    name: 'Checking',
    value: 'checking'
  },
  savings: {
    name: 'Savings',
    value: 'savings'
  }
};

export const AMOUNT_RATE = Math.pow(10, 2);
export const CURRENCY_RATE = Math.pow(10, 8);

const MIN_DEPOSIT_AMOUNT_INPUT = 10;
const MIN_INVEST_AMOUNT_INPUT = 50;

const FIAT_RATE = {
  USD: 1,
  VND: 23000,
  HKD: 7.84,
  CNY: 7.15
};

export const MIN_INVEST_AMOUNT = {
  USD: FIAT_RATE.USD * MIN_INVEST_AMOUNT_INPUT,
  VND: FIAT_RATE.VND * MIN_INVEST_AMOUNT_INPUT,
  HKD: FIAT_RATE.HKD * MIN_INVEST_AMOUNT_INPUT,
  CNY: FIAT_RATE.CNY * MIN_INVEST_AMOUNT_INPUT,
};

export const MIN_DEPOSIT_AMOUNT = {
  USD: FIAT_RATE.USD * MIN_DEPOSIT_AMOUNT_INPUT,
  VND: FIAT_RATE.VND * MIN_DEPOSIT_AMOUNT_INPUT,
  HKD: FIAT_RATE.HKD * MIN_DEPOSIT_AMOUNT_INPUT,
  CNY: FIAT_RATE.CNY * MIN_DEPOSIT_AMOUNT_INPUT,
};

export const MIN_BORROW_AMOUNT = {
  USD: 50,
  PRV: 250,
  VND: 1000000
};
export const RESERVE_TRANSFER_TYPE = {
  email: 0,
  eth: 1,
  eos: 1,
  tomo: 2,
  usdt: 3,
  usds: 4,
  tusd: 5,
  usdc: 6,
  stableCoin: 7
};

export const WITHDRAW_BANK_TYPE = {
  Zelle: 'email',
  Direct: 'fiat',
  Plaid: 'plaid',
  Paypal: 'paypal',
  Google: 'google_pay',
  Venmo: 'venmo',
  AutoBank: 'auto_bank',
  GiftCard: 'gift_card',
};

export const WITHDRAW_PAYMENT_NAME = {
  email: 'Zelle',
  fiat: 'Bank transfer',
  paypal: 'Paypal',
  google_pay: 'Google Pay',
  venmo: 'Venmo',
  auto_bank: 'Checkbook (ACH)',
};

export const WITHDRAW_PAYMENT_TYPE = {
  email: 'email',
  fiat: 'fiat',
  paypal: 'paypal',
  google_pay: 'google_pay',
  venmo: 'venmo',
  auto_bank: 'auto_bank',
  primetrust: 'primetrust',
  plaid: 'plaid',
};

export const WITHDRAW_MIN_AMOUNT = {
  email: 15,
  plaid: 1,
  fiat: 15,
  paypal: 1,
  venmo: 1,
  google_pay: 1,
  auto_bank: 1,
};

export const WITHDRAW_TRANSFER_TYPE = {
  ACH:  1,
  WIRE: 2,
  PLAID: 3,
  NORMAL: 4,
};

export const WITHDRAW_ERROR_CODE = {
  over_limit: 'over_limit',
  invalid_bank_account_name: 'invalid_bank_account_name',
};