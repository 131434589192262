const TAG = 'SCREENS/AUTH';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_INTEREST = 'GET_PROFILE_INTEREST';
export const UPDATE_AUTH_STATE = 'UPDATE_AUTH_STATE';
export const SESSION_EXPIRED = `${TAG}/SESSION_EXPIRED`;
export const UPDATE_PROFILE_INFO = `${TAG}/UPDATE_PROFILE_INFO`;
export const LOGOUT = `${TAG}/LOGOUT`;
export const SET_TOKEN = `${TAG}/SET_TOKEN`;
export const SET_REQUIRE_OTP = `${TAG}/SET_REQUIRE_OTP`;
export const SET_RETRY_OTP = `${TAG}/SET_RETRY_OTP`;
export const CHECK_TRIAL_USER_AVAIABLE = `${TAG}/CHECK_TRIAL_USER_AVAIABLE`;
export const GET_SYSTEM_INTEREST = `${TAG}/GET_SYSTEM_INTEREST`;
export const SECONDARY_MARKET_NOTIFICATION_SETTINGS = `${TAG}/SECONDARY_MARKET_NOTIFICATION_SETTINGS`;
export const WITHDRAW_EMAIL_SETTING = `${TAG}/WITHDRAW_EMAIL_SETTING`;
export const UPDATE_REFERRAL_CODE = 'UPDATE_REFERRAL_CODE';
export const GET_USER_ATTRIBUTES = 'GET_USER_ATTRIBUTES';
export const GET_USER_LOYALTY_BENEFITS = 'GET_USER_LOYALTY_BENEFITS';
export const GET_MCT_LOYALTY_PROGRAM_INFO = 'GET_MCT_LOYALTY_PROGRAM_INFO';
export const GET_BALANCE = `${TAG}/GET_BALANCE`;
export const ADD_NEW_PLAID_BANK = `${TAG}/ADD_NEW_PLAID_BANK`;
export const UPDATE_PLAID_BANK = `${TAG}/UPDATE_PLAID_BANK`;