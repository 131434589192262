import injectScript from 'src/utils/injectScript';

function init() {
  if (__CLIENT__) {
    // const div = document.createElement('div');
    // div.setAttribute('class', 'fb-customerchat');
    // div.setAttribute('attribution', 'setup_tool');
    // div.setAttribute('greeting_dialog_display', 'hide');
    // div.setAttribute('page_id', APP_ENV.FACEBOOK_WEBPAGE_ID);
    // document.body.appendChild(div);

    const externalUrl = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';

    injectScript({
      fromSrc: externalUrl,
      attrs: { id: 'facebook-jssdk', async: true, defer: true } }
    ).then(() => {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId            : APP_ENV.social.facebookAppId,
          xfbml            : true,
          version          : 'v6.0',
          cookie     : true,
        });
      };
    }).catch(console.error);
  }
}

export default {
  init
};
