import axios from 'axios';
import { REQUEST_TIMEOUT } from 'src/resources/constants/app';
import { requestHeaderInterceptor, responseErrorInterceptor, responseSuccessInterceptor } from './utils';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const instance = axios.create({
  baseURL: 'https://www.myconstant.com',
  timeout: REQUEST_TIMEOUT,
  crossDomain: true,
  headers
});

// Add a request interceptor
instance.interceptors.request.use(requestHeaderInterceptor, error => Promise.reject(error));

// Add a response interceptor
instance.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor,
);

export default instance;
