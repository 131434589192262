import { APP } from 'src/resources/constants/app';
import { FAIL_DEFAULT_LANGUAGE } from 'src/resources/constants/languages';
import localStore from 'src/services/localStore';

const CHANGE_LANG = 'CHANGE_LANG';
const DEFAULT_LANG = 'DEFAULT_LANG';
const initState = {
  lang: localStore.get(APP.LOCALE) || FAIL_DEFAULT_LANGUAGE,
};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case CHANGE_LANG:
      localStore.save(APP.LOCALE, payload);
      return {
        lang: payload,
      };
    case DEFAULT_LANG:
      return {
        lang: payload,
      };
    default:
      return state;
  }
};
