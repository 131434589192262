const initalState = {
  show: false,
  callback: f=>f
}

const SHOW_SECURITY_DIALOG = 'SHOW_SECURITY_DIALOG';
const HIDE_SECURITY_DIALOG = 'HIDE_SECURITY_DIALOG';
const AUTHORIED_DIALOG = 'AUTHORIED_DIALOG';

export default (state = initalState, { type, payload }) => {
  switch(type) {
    case SHOW_SECURITY_DIALOG: {
      return {
        show: true
      }
    };
    case HIDE_SECURITY_DIALOG: {
      return {
        show: false
      }
    }
    case AUTHORIED_DIALOG: {
      return {
        show: true,
        ...payload
      }
    }
    default: return initalState;
  }
}