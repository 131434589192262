import { REFRESH_SECONDARY_MARKET, SAVE_PRV_INTEREST } from './type';

const initialState = {
  isRefresh2nd: false,
  prvInterestValue: 0,
  collaterals: [],
};

export default (state = initialState, { type, payload, data }) => {
  switch (type) {
    case REFRESH_SECONDARY_MARKET: {
      return {
        ...state,
        isRefresh2nd: payload
      };
    }
    case SAVE_PRV_INTEREST: {
      return {
        ...state,
        prvInterestValue: payload,
      };
    }
    case `GET_BALANCE_COLLATERALS_SUCCESS`: {
      return {
        ...state,
        collaterals: data,
      }
    }
    default: return state;
  }
};
