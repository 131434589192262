const initalState = {
  title: 'Confirmation',
  desc: 'Are you sure ?',
  btnYesTitle: 'Yes',
  btnNoTitle: 'No',
  show: false,
  onClickYes: f=>f,
  onClickNo: f=>f
};

const SHOW_POPUP_CONFIRM = 'SHOW_POPUP_CONFIRM';
const HIDE_POPUP_CONFIRM = 'HIDE_POPUP_CONFIRM';
const SHOW_POPUP_CB = 'SHOW_POPUP_CB';
export default (state = initalState, { type, payload }) => {
  switch(type) {
    case SHOW_POPUP_CONFIRM: {
      return {
        show: true
      };
    }
    case HIDE_POPUP_CONFIRM: {
      return {
        show: false
      };
    }
    case SHOW_POPUP_CB: {
      return {
        show: true,
        ...payload
      };
    }
    default: return state;
  }
}
