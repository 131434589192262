import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';

class ModalComponent extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    classNames: PropTypes.string,
    actions: PropTypes.object,
    modalProps: PropTypes.object,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    title: '',
    classNames: '',
    actions: undefined,
    onClose: undefined,
    modalProps: {},
  };

  handleClose = () => {
    const { actions, id, onClose } = this.props;
    actions.closeModal({ id });
    if (actions.onClose) {
      onClose(this.props);
    }
  };

  renderHeader = props => {
    return props.title ? (
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
    ) : null;
  };

  renderComponent = props => {
    const { modalProps, classNames } = props; 
    const cls = cx('ReactModal', { [classNames]: !!classNames });
    return (
      <Modal
        show
        keyboard={false}
        onHide={this.handleClose}
        bsclass={cls}
        dialogClassName={modalProps.dialogClassName}
        {...modalProps}
      >
        {this.renderHeader(props)}
        <Modal.Body className={cx({ 'no-padding': modalProps.padding === 0 })}>
          {props.render(props.actions)}
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    return this.renderComponent(this.props);
  }
}

export default ModalComponent;
