const COUNTRIES = {
  US: {
    code: 'US',
    name: 'United States',
    regions: {
      AL: 'Alabama',
      AK: 'Alaska',
      AS: 'American Samoa',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DE: 'Delaware',
      DC: 'District Of Columbia',
      FM: 'Federated States Of Micronesia',
      FL: 'Florida',
      GA: 'Georgia',
      GU: 'Guam',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MH: 'Marshall Islands',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      MP: 'Northern Mariana Islands',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PW: 'Palau',
      PA: 'Pennsylvania',
      PR: 'Puerto Rico',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VI: 'Virgin Islands',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming',
    },
  },
  AF: {
    code: 'AF',
    name: 'Afghanistan',
  },
  AX: {
    code: 'AX',
    name: 'Åland Islands',
  },
  AL: {
    code: 'AL',
    name: 'Albania',
  },
  DZ: {
    code: 'DZ',
    name: 'Algeria',
  },
  AS: {
    code: 'AS',
    name: 'American Samoa',
  },
  AD: {
    code: 'AD',
    name: 'Andorra',
  },
  AO: {
    code: 'AO',
    name: 'Angola',
  },
  AI: {
    code: 'AI',
    name: 'Anguilla',
  },
  AQ: {
    code: 'AQ',
    name: 'Antarctica',
  },
  AG: {
    code: 'AG',
    name: 'Antigua and Barbuda',
  },
  AR: {
    code: 'AR',
    name: 'Argentina',
  },
  AM: {
    code: 'AM',
    name: 'Armenia',
  },
  AW: {
    code: 'AW',
    name: 'Aruba',
  },
  AU: {
    code: 'AU',
    name: 'Australia',
  },
  AT: {
    code: 'AT',
    name: 'Austria',
  },
  AZ: {
    code: 'AZ',
    name: 'Azerbaijan',
  },
  BS: {
    code: 'BS',
    name: 'Bahamas',
  },
  BH: {
    code: 'BH',
    name: 'Bahrain',
  },
  BD: {
    code: 'BD',
    name: 'Bangladesh',
  },
  BB: {
    code: 'BB',
    name: 'Barbados',
  },
  BY: {
    code: 'BY',
    name: 'Belarus',
  },
  BE: {
    code: 'BE',
    name: 'Belgium',
  },
  BZ: {
    code: 'BZ',
    name: 'Belize',
  },
  BJ: {
    code: 'BJ',
    name: 'Benin',
  },
  BM: {
    code: 'BM',
    name: 'Bermuda',
  },
  BT: {
    code: 'BT',
    name: 'Bhutan',
  },
  BO: {
    code: 'BO',
    name: 'Bolivia',
  },
  BQ: {
    code: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
  },
  BA: {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  BW: {
    code: 'BW',
    name: 'Botswana',
  },
  BV: {
    code: 'BV',
    name: 'Bouvet Island',
  },
  BR: {
    code: 'BR',
    name: 'Brazil',
  },
  IO: {
    code: 'IO',
    name: 'British Indian Ocean Territory',
  },
  BN: {
    code: 'BN',
    name: 'Brunei Darussalam',
  },
  BG: {
    code: 'BG',
    name: 'Bulgaria',
  },
  BF: {
    code: 'BF',
    name: 'Burkina Faso',
  },
  BI: {
    code: 'BI',
    name: 'Burundi',
  },
  KH: {
    code: 'KH',
    name: 'Cambodia',
  },
  CM: {
    code: 'CM',
    name: 'Cameroon',
  },
  CA: {
    code: 'CA',
    name: 'Canada',
  },
  CV: {
    code: 'CV',
    name: 'Cape Verde',
  },
  KY: {
    code: 'KY',
    name: 'Cayman Islands',
  },
  CF: {
    code: 'CF',
    name: 'Central African Republic',
  },
  TD: {
    code: 'TD',
    name: 'Chad',
  },
  CL: {
    code: 'CL',
    name: 'Chile',
  },
  CN: {
    code: 'CN',
    name: 'China',
  },
  CX: {
    code: 'CX',
    name: 'Christmas Island',
  },
  CC: {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
  },
  CO: {
    code: 'CO',
    name: 'Colombia',
  },
  KM: {
    code: 'KM',
    name: 'Comoros',
  },
  CG: {
    code: 'CG',
    name: 'Congo, Republic of the (Brazzaville)',
  },
  CD: {
    code: 'CD',
    name: 'Congo, the Democratic Republic of the (Kinshasa)',
  },
  CK: {
    code: 'CK',
    name: 'Cook Islands',
  },
  CR: {
    code: 'CR',
    name: 'Costa Rica',
  },
  CI: {
    code: 'CI',
    name: "Côte d'Ivoire, Republic of",
  },
  HR: {
    code: 'HR',
    name: 'Croatia',
  },
  CU: {
    code: 'CU',
    name: 'Cuba',
  },
  CW: {
    code: 'CW',
    name: 'Curaçao',
  },
  CY: {
    code: 'CY',
    name: 'Cyprus',
  },
  CZ: {
    code: 'CZ',
    name: 'Czech Republic',
  },
  DK: {
    code: 'DK',
    name: 'Denmark',
  },
  DJ: {
    code: 'DJ',
    name: 'Djibouti',
  },
  DM: {
    code: 'DM',
    name: 'Dominica',
  },
  DO: {
    code: 'DO',
    name: 'Dominican Republic',
  },
  EC: {
    code: 'EC',
    name: 'Ecuador',
  },
  EG: {
    code: 'EG',
    name: 'Egypt',
  },
  SV: {
    code: 'SV',
    name: 'El Salvador',
  },
  GQ: {
    code: 'GQ',
    name: 'Equatorial Guinea',
  },
  ER: {
    code: 'ER',
    name: 'Eritrea',
  },
  EE: {
    code: 'EE',
    name: 'Estonia',
  },
  ET: {
    code: 'ET',
    name: 'Ethiopia',
  },
  FK: {
    code: 'FK',
    name: 'Falkland Islands (Islas Malvinas)',
  },
  FO: {
    code: 'FO',
    name: 'Faroe Islands',
  },
  FJ: {
    code: 'FJ',
    name: 'Fiji',
  },
  FI: {
    code: 'FI',
    name: 'Finland',
  },
  FR: {
    code: 'FR',
    name: 'France',
  },
  GF: {
    code: 'GF',
    name: 'French Guiana',
  },
  PF: {
    code: 'PF',
    name: 'French Polynesia',
  },
  TF: {
    code: 'TF',
    name: 'French Southern and Antarctic Lands',
  },
  GA: {
    code: 'GA',
    name: 'Gabon',
  },
  GM: {
    code: 'GM',
    name: 'Gambia, The',
  },
  GE: {
    code: 'GE',
    name: 'Georgia',
  },
  DE: {
    code: 'DE',
    name: 'Germany',
  },
  GH: {
    code: 'GH',
    name: 'Ghana',
  },
  GI: {
    code: 'GI',
    name: 'Gibraltar',
  },
  GR: {
    code: 'GR',
    name: 'Greece',
  },
  GL: {
    code: 'GL',
    name: 'Greenland',
  },
  GD: {
    code: 'GD',
    name: 'Grenada',
  },
  GP: {
    code: 'GP',
    name: 'Guadeloupe',
  },
  GU: {
    code: 'GU',
    name: 'Guam',
  },
  GT: {
    code: 'GT',
    name: 'Guatemala',
  },
  GG: {
    code: 'GG',
    name: 'Guernsey',
  },
  GN: {
    code: 'GN',
    name: 'Guinea',
  },
  GW: {
    code: 'GW',
    name: 'Guinea-Bissau',
  },
  GY: {
    code: 'GY',
    name: 'Guyana',
  },
  HT: {
    code: 'HT',
    name: 'Haiti',
  },
  HM: {
    code: 'HM',
    name: 'Heard Island and McDonald Islands',
  },
  VA: {
    code: 'VA',
    name: 'Holy See (Vatican City)',
  },
  HN: {
    code: 'HN',
    name: 'Honduras',
  },
  HK: {
    code: 'HK',
    name: 'Hong Kong',
  },
  HU: {
    code: 'HU',
    name: 'Hungary',
  },
  IS: {
    code: 'IS',
    name: 'Iceland',
  },
  IN: {
    code: 'IN',
    name: 'India',
  },
  ID: {
    code: 'ID',
    name: 'Indonesia',
  },
  IR: {
    code: 'IR',
    name: 'Iran, Islamic Republic of',
  },
  IQ: {
    code: 'IQ',
    name: 'Iraq',
  },
  IE: {
    code: 'IE',
    name: 'Ireland',
  },
  IM: {
    code: 'IM',
    name: 'Isle of Man',
  },
  IL: {
    code: 'IL',
    name: 'Israel',
  },
  IT: {
    code: 'IT',
    name: 'Italy',
  },
  JM: {
    code: 'JM',
    name: 'Jamaica',
  },
  JP: {
    code: 'JP',
    name: 'Japan',
  },
  JE: {
    code: 'JE',
    name: 'Jersey',
  },
  JO: {
    code: 'JO',
    name: 'Jordan',
  },
  KZ: {
    code: 'KZ',
    name: 'Kazakhstan',
  },
  KE: {
    code: 'KE',
    name: 'Kenya',
  },
  KI: {
    code: 'KI',
    name: 'Kiribati',
  },
  KP: {
    code: 'KP',
    name: "Korea, Democratic People's Republic of",
  },
  KR: {
    code: 'KR',
    name: 'Korea, Republic of',
  },
  KW: {
    code: 'KW',
    name: 'Kuwait',
  },
  KG: {
    code: 'KG',
    name: 'Kyrgyzstan',
  },
  LA: {
    code: 'LA',
    name: 'Laos',
  },
  LV: {
    code: 'LV',
    name: 'Latvia',
  },
  LB: {
    code: 'LB',
    name: 'Lebanon',
  },
  LS: {
    code: 'LS',
    name: 'Lesotho',
  },
  LR: {
    code: 'LR',
    name: 'Liberia',
  },
  LY: {
    code: 'LY',
    name: 'Libya',
  },
  LI: {
    code: 'LI',
    name: 'Liechtenstein',
  },
  LT: {
    code: 'LT',
    name: 'Lithuania',
  },
  LU: {
    code: 'LU',
    name: 'Luxembourg',
  },
  MO: {
    code: 'MO',
    name: 'Macao',
  },
  MK: {
    code: 'MK',
    name: 'Macedonia, Republic of',
  },
  MG: {
    code: 'MG',
    name: 'Madagascar',
  },
  MW: {
    code: 'MW',
    name: 'Malawi',
  },
  MY: {
    code: 'MY',
    name: 'Malaysia',
  },
  MV: {
    code: 'MV',
    name: 'Maldives',
  },
  ML: {
    code: 'ML',
    name: 'Mali',
  },
  MT: {
    code: 'MT',
    name: 'Malta',
  },
  MH: {
    code: 'MH',
    name: 'Marshall Islands',
  },
  MQ: {
    code: 'MQ',
    name: 'Martinique',
  },
  MR: {
    code: 'MR',
    name: 'Mauritania',
  },
  MU: {
    code: 'MU',
    name: 'Mauritius',
  },
  YT: {
    code: 'YT',
    name: 'Mayotte',
  },
  MX: {
    code: 'MX',
    name: 'Mexico',
  },
  FM: {
    code: 'FM',
    name: 'Micronesia, Federated States of',
  },
  MD: {
    code: 'MD',
    name: 'Moldova',
  },
  MC: {
    code: 'MC',
    name: 'Monaco',
  },
  MN: {
    code: 'MN',
    name: 'Mongolia',
  },
  ME: {
    code: 'ME',
    name: 'Montenegro',
  },
  MS: {
    code: 'MS',
    name: 'Montserrat',
  },
  MA: {
    code: 'MA',
    name: 'Morocco',
  },
  MZ: {
    code: 'MZ',
    name: 'Mozambique',
  },
  MM: {
    code: 'MM',
    name: 'Myanmar',
  },
  NA: {
    code: 'NA',
    name: 'Namibia',
  },
  NR: {
    code: 'NR',
    name: 'Nauru',
  },
  NP: {
    code: 'NP',
    name: 'Nepal',
  },
  NL: {
    code: 'NL',
    name: 'Netherlands',
  },
  NC: {
    code: 'NC',
    name: 'New Caledonia',
  },
  NZ: {
    code: 'NZ',
    name: 'New Zealand',
  },
  NI: {
    code: 'NI',
    name: 'Nicaragua',
  },
  NE: {
    code: 'NE',
    name: 'Niger',
  },
  NG: {
    code: 'NG',
    name: 'Nigeria',
  },
  NU: {
    code: 'NU',
    name: 'Niue',
  },
  NF: {
    code: 'NF',
    name: 'Norfolk Island',
  },
  MP: {
    code: 'MP',
    name: 'Northern Mariana Islands',
  },
  NO: {
    code: 'NO',
    name: 'Norway',
  },
  OM: {
    code: 'OM',
    name: 'Oman',
  },
  PK: {
    code: 'PK',
    name: 'Pakistan',
  },
  PW: {
    code: 'PW',
    name: 'Palau',
  },
  PS: {
    code: 'PS',
    name: 'Palestine, State of',
  },
  PA: {
    code: 'PA',
    name: 'Panama',
  },
  PG: {
    code: 'PG',
    name: 'Papua New Guinea',
  },
  PY: {
    code: 'PY',
    name: 'Paraguay',
  },
  PE: {
    code: 'PE',
    name: 'Peru',
  },
  PH: {
    code: 'PH',
    name: 'Philippines',
  },
  PN: {
    code: 'PN',
    name: 'Pitcairn',
  },
  PL: {
    code: 'PL',
    name: 'Poland',
  },
  PT: {
    code: 'PT',
    name: 'Portugal',
  },
  PR: {
    code: 'PR',
    name: 'Puerto Rico',
  },
  QA: {
    code: 'QA',
    name: 'Qatar',
  },
  RE: {
    code: 'RE',
    name: 'Réunion',
  },
  RO: {
    code: 'RO',
    name: 'Romania',
  },
  RU: {
    code: 'RU',
    name: 'Russian Federation',
  },
  RW: {
    code: 'RW',
    name: 'Rwanda',
  },
  BL: {
    code: 'BL',
    name: 'Saint Barthélemy',
  },
  SH: {
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  KN: {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  LC: {
    code: 'LC',
    name: 'Saint Lucia',
  },
  MF: {
    code: 'MF',
    name: 'Saint Martin',
  },
  PM: {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  VC: {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  WS: {
    code: 'WS',
    name: 'Samoa',
  },
  SM: {
    code: 'SM',
    name: 'San Marino',
  },
  ST: {
    code: 'ST',
    name: 'Sao Tome and Principe',
  },
  SA: {
    code: 'SA',
    name: 'Saudi Arabia',
  },
  SN: {
    code: 'SN',
    name: 'Senegal',
  },
  RS: {
    code: 'RS',
    name: 'Serbia',
  },
  SC: {
    code: 'SC',
    name: 'Seychelles',
  },
  SL: {
    code: 'SL',
    name: 'Sierra Leone',
  },
  SG: {
    code: 'SG',
    name: 'Singapore',
  },
  SX: {
    code: 'SX',
    name: 'Sint Maarten (Dutch part)',
  },
  SK: {
    code: 'SK',
    name: 'Slovakia',
  },
  SI: {
    code: 'SI',
    name: 'Slovenia',
  },
  SB: {
    code: 'SB',
    name: 'Solomon Islands',
  },
  SO: {
    code: 'SO',
    name: 'Somalia',
  },
  ZA: {
    code: 'ZA',
    name: 'South Africa',
  },
  GS: {
    code: 'GS',
    name: 'South Georgia and South Sandwich Islands',
  },
  SS: {
    code: 'SS',
    name: 'South Sudan',
  },
  ES: {
    code: 'ES',
    name: 'Spain',
  },
  LK: {
    code: 'LK',
    name: 'Sri Lanka',
  },
  SD: {
    code: 'SD',
    name: 'Sudan',
  },
  SR: {
    code: 'SR',
    name: 'Suriname',
  },
  SZ: {
    code: 'SZ',
    name: 'Swaziland',
  },
  SE: {
    code: 'SE',
    name: 'Sweden',
  },
  CH: {
    code: 'CH',
    name: 'Switzerland',
  },
  SY: {
    code: 'SY',
    name: 'Syrian Arab Republic',
  },
  TW: {
    code: 'TW',
    name: 'Taiwan',
  },
  TJ: {
    code: 'TJ',
    name: 'Tajikistan',
  },
  TZ: {
    code: 'TZ',
    name: 'Tanzania, United Republic of',
  },
  TH: {
    code: 'TH',
    name: 'Thailand',
  },
  TL: {
    code: 'TL',
    name: 'Timor-Leste',
  },
  TG: {
    code: 'TG',
    name: 'Togo',
  },
  TK: {
    code: 'TK',
    name: 'Tokelau',
  },
  TO: {
    code: 'TO',
    name: 'Tonga',
  },
  TT: {
    code: 'TT',
    name: 'Trinidad and Tobago',
  },
  TN: {
    code: 'TN',
    name: 'Tunisia',
  },
  TR: {
    code: 'TR',
    name: 'Turkey',
  },
  TM: {
    code: 'TM',
    name: 'Turkmenistan',
  },
  TC: {
    code: 'TC',
    name: 'Turks and Caicos Islands',
  },
  TV: {
    code: 'TV',
    name: 'Tuvalu',
  },
  UG: {
    code: 'UG',
    name: 'Uganda',
  },
  UA: {
    code: 'UA',
    name: 'Ukraine',
  },
  AE: {
    code: 'AE',
    name: 'United Arab Emirates',
  },
  GB: {
    code: 'GB',
    name: 'United Kingdom',
  },
  UM: {
    code: 'UM',
    name: 'United States Minor Outlying Islands',
  },
  UY: {
    code: 'UY',
    name: 'Uruguay',
  },
  UZ: {
    code: 'UZ',
    name: 'Uzbekistan',
  },
  VU: {
    code: 'VU',
    name: 'Vanuatu',
  },
  VE: {
    code: 'VE',
    name: 'Venezuela, Bolivarian Republic of',
  },
  VN: {
    code: 'VN',
    name: 'Vietnam',
    regions: [
      'An Giang',
      'Ba Ria - Vung Tau',
      'Bac Giang',
      'Bac Kan',
      'Bac Lieu',
      'Bac Ninh',
      'Ben Tre',
      'Binh Dinh',
      'Binh Duong',
      'Binh Phuoc',
      'Binh Thuan',
      'Ca Mau',
      'Cao Bang',
      'Dak Lak',
      'Dak Nong',
      'Dien Bien',
      'Dong Nai',
      'Dong Thap',
      'Gia Lai',
      'Ha Giang',
      'Ha Nam',
      'Ha Tinh',
      'Hai Duong',
      'Hau Giang',
      'Hoa Binh',
      'Hung Yen',
      'Khanh Hoa',
      'Kien Giang',
      'Kon Tum',
      'Lai Chau',
      'Lam Dong',
      'Lang Son',
      'Lao Cai',
      'Long An',
      'Nam Dinh',
      'Nghe An',
      'Ninh Binh',
      'Ninh Thuan',
      'Phu Tho',
      'Quang Binh',
      'Quang Nam',
      'Quang Ngai',
      'Quang Ninh',
      'Quang Tri',
      'Soc Trang',
      'Son La',
      'Tay Ninh',
      'Thai Binh',
      'Thai Nguyen',
      'Thanh Hoa',
      'Thua Thien Hue',
      'Tien Giang',
      'Tra Vinh',
      'Tuyen Quang',
      'Vinh Long',
      'Vinh Phuc',
      'Yen Bai',
      'Phu Yen',
      'Can Tho',
      'Da Nang',
      'Hai Phong',
      'Ha Noi',
      'TP Ho Chi Minh',
    ],
  },
  VG: {
    code: 'VG',
    name: 'Virgin Islands, British',
  },
  VI: {
    code: 'VI',
    name: 'Virgin Islands, U.S.',
  },
  WF: {
    code: 'WF',
    name: 'Wallis and Futuna',
  },
  EH: {
    code: 'EH',
    name: 'Western Sahara',
  },
  YE: {
    code: 'YE',
    name: 'Yemen',
  },
  ZM: {
    code: 'ZM',
    name: 'Zambia',
  },
  ZW: {
    code: 'ZW',
    name: 'Zimbabwe',
  },
};

export default COUNTRIES;