import React, {PureComponent} from 'react';
import loadable from '@loadable/component';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { logUserActivity } from 'src/components/logUserActivity';
import LocalStore from 'src/services/localStore';
import { APP } from 'src/resources/constants/app';
import { sendFbViewEvent } from 'src/services/facebookConversion';

import styles from './styles.scss';

const LoadableAlert = loadable(() => import('src/components/core/presentation/Alert'));
const LoadableSecurity = loadable(() => import('src/screens/security-dialog'));
const LoadableAlertDialog = loadable(() => import('src/components/alertDialog'));
const LoadableProgressDialog = loadable(() => import('src/components/progressDialog'));
const LoadableRequire2FaDialog = loadable(() => import('src/components/require2FaDialog'));
const LoadableConfirmDialog = loadable(() => import('src/components/confirmDialog'));
const LoadableVerify2FaModel = loadable(() => import('src/screens/me/pages/Security/TwoFactor/Verify2FaModal'));
const LoadableSmsVerifyModal = loadable(() => import('src/screens/me/pages/Security/smsAuthentication/smsVerifyModal'));
const LoadableBadgeAchieveModal = loadable(() => import('src/components/badgeAchieveModal'));
const LoadableLoadingOverlay = loadable(() => import('src/components/loadingOverlay'));
const LoadableContactButtons = loadable(() => import('src/components/contactSupport'));
// const LoadableTranslateButtons = loadable(() => import('src/components/translateSupport'));

class Layout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openContactSupport: false,
      openTranslateSupport: false,
    };
  }

  componentDidMount() {
    this.logUserActivity();

    this.unlisten = this.props.history.listen((location, action) => {
      if (this.props.location.pathname !== location.pathname) {
        this.setState({ openContactSupport: false, openTranslateSupport: false });
        this.logUserActivity();
        LocalStore.remove(APP.VISIT_ONLY_1);

        if (APP_ENV.isLive) sendFbViewEvent();
      }
    });
  }

  logUserActivity = () => {
    const { logUserActivity, isAuth } = this.props;

    try {
      isAuth && logUserActivity();
    } catch (e) {
      console.log('e', e);
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleChangeContactSupport = (isOpen) => {
    this.setState({openContactSupport: isOpen});
  }

  handleChangeTranslateSupport = (isOpen) => {
    this.setState({openTranslateSupport: isOpen});
  }

  render() {
    // eslint-disable-next-line
    const { children, isShowSupportButton, isShowTranslateButton } = this.props;
    const { openContactSupport, openTranslateSupport } = this.state;

    return (
      <React.Fragment>
        <div id="layout" className={styles.content}>
          {children}
        </div>
        <LoadableAlert />
        <LoadableSecurity />
        <LoadableVerify2FaModel />
        <LoadableSmsVerifyModal />
        <LoadableAlertDialog />
        <LoadableProgressDialog />
        <LoadableRequire2FaDialog />
        <LoadableConfirmDialog />
        <LoadableBadgeAchieveModal />
        <LoadableLoadingOverlay />
        {/* {isShowTranslateButton && <LoadableTranslateButtons openTranslateSupport={openTranslateSupport} onChangeOpen={this.handleChangeTranslateSupport} />} */}
        {isShowSupportButton && <LoadableContactButtons openContactSupport={openContactSupport} onChangeOpen={this.handleChangeContactSupport} />}
      </React.Fragment>
    );
  }
}

const mapState = state => ({
  isAuth: !!state.auth?.token,
  isShowSupportButton: state.supportButton.isShow,
  isShowTranslateButton: state.tranlsateButton.isShow,
});

const mapDispatch = {
  logUserActivity,
};

export default withRouter(connect(mapState, mapDispatch)(Layout));
