export const OPEN_PROGRESS_DIALOG = 'OPEN_PROGRESS_DIALOG';
export const HIDE_PROGRESS_DIALOG = 'HIDE_PROGRESS_DIALOG';
export const SET_PROGRESS_DIALOG_VALUE = 'SET_PROGRESS_DIALOG_VALUE';

export const openProgressDialog = (params) => dispatch => dispatch({
  type: OPEN_PROGRESS_DIALOG,
  payload: params,
});
export const hideProgressDialog = () => dispatch => dispatch({type: HIDE_PROGRESS_DIALOG});

export const setProgressDialogValue = progress => dispatch => dispatch({
  type: SET_PROGRESS_DIALOG_VALUE,
  payload: {progress}
});
