import { makeRequest } from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';

export const resendEmail = () => {
  return makeRequest({
    type: 'RESEND_EMAIL_VERIFICATION',
    url: `${API_URL.USER.EMAIL_VERIFICATION}`,
    method: 'POST'
  });
};
