import {OPEN_ALERT_DIALOG, HIDE_ALERT_DIALOG} from './action';

const initialState = {
  show: false,
  message: '',
  callback: f=> f,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_ALERT_DIALOG: {
      return {
        show: true,
        ...payload,
      };
    }
    case HIDE_ALERT_DIALOG: {
      return {
        show: false,
        message: '',
      };
    }
    default: return state;
  }
};
