export const APP = {
  LOCALE: 'app_locale',
  COUNTRY: 'app_country',
  SETTING: 'setting',
  IP_INFO: 'ip_info',
  isSupportedLanguages: ['en', 'vi'],
  SOURCE: 'source',
  FILTER_LOAN: 'filter_loan',
  FILTER_LOAN_ORIGINATOR: 'filter_loan_originator',
  FILTER_LO_SECONDARY_MARKET: 'filter_lo_secondary_market',
  FILTER_LOAN_C2C: 'filter_loan_c2c',
  FILTER_INVEST: 'filter_invest',
  FILTER_INVEST_SECONDARY: 'filter_invest_secondary',
  FILTER_LOAN_SETTINGS: 'filter_loan_settings',
  FILTER_SHORT_SELLING: 'filter_short_selling',
  FILTER_SHORT_SIMPLE: 'filter_short_simple',
  FILTER_LENDING_LOTTERY: 'filter_lending_lottery',
  SELECTED_TAB: 'selected_tab',
  HIDE_SMALL_COLLATERAL: 'hide_small_collateral',
  HIDE_SMALL_STAKE: 'hide_small_stake',
  DEPOSIT_HISTORY_TAB: 'deposit_history_tab',
  WITHDRAW_HISTORY_TAB: 'withdraw_history_tab',
  SHORT_SELLING_HISTORY_TAB: 'short_selling_history_tab',
  LENDING_LOTTERY_TAB: 'lending_lottery_tab',
  INTERNAL_BONUS_TAB: 'internal_bonus_tab',
  HIDE_TOP_BANNER: 'hide_top_banner',
  HIDE_DOWNLOAD_HEADER: 'hide_download_header',
  SESSION_ID: 'session_id',
  HIDE_SUPPORT_BUTTON: 'hide_support_button',
  HIDE_TRANSLATE_BUTTON: 'hide_translate_button',
  RECENTLY_PATH: 'recently_path',
  FUTURE_PREVIOUS_COIN: 'future_previous_coin',
  FUTURE_PREVIOUS_COIN_BALANCE: 'future_previous_coin_balance',
  FUTURE_PREVIOUS_AMOUUNT: 'future_previous_amount',
  BUILD_VERSION: 'build_version',
  LOTTERY_PAGE_TIME_SPEND: 'lottery_page_time_spend',
  SIGN_UP_COMPLETE: 'sign_up_complete',
  VISIT_ONLY_1: 'visit_only_1',
  HAVE_DEBIT_CARD: 'have_debit_card',
  LOAN_TYPE: 'loan_type',
  TOKEN_ID: 'token_id',
  OPEN_URL: 'open_url',
};

export const REQUEST_TIMEOUT = 20000;

export const MAX_AMOUNT_REQUIRE_2FA = 1000;
export const MAX_AMOUNT_INVEST = 1000000000;
export const MAX_SIZE_UPLOAD = 10485760;

export const OTP_RETRY = 3;
export const OTP_NUM_INPUTS = 6;
export const ERROR_CODE_OTP = -1045;
// export const ERROR_CODE_OTP_SMS = -1085;
export const ERROR_CODE_INVALID_COUPON = -1077;
export const ERROR_CODE_USED_COUPON = -1078;
export const COOKIE_REFERRAL = 'cookie_referral';
export const COOKIE_SRC = 'cookie_src';
export const COOKIE_CLICK_ID = 'click_id';
export const FB_CLICK_ID = '_fbc';
export const COOKIE_QUERY_STRING = 'cookie_query_string';
export const COOKIE_SIGNUP_BORROW = 'cookie_signup_borrow';
export const COOKIE_BLACKFRIDAY_SALE = 'blackfridaysales';
export const COOKIE_NEWYEAR_SALE = 'happynewyear';
export const COOKIE_IP_INFO = 'cookie_ip_info';
export const REGEX_SSN = /^([0-9]{3}-[0-9]{2}-[0-9]{4}|[0-9]{9})$/;
export const REGEX_URL = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const ONE_YEAR_SECONDS = 31104000;

export const SOCIAL_TYPE = {
  FACEBOOK: 0,
  GOOGLE: 1,
  DISCORD: 4
};

export const SYSTEM_CONFIGS = {
  API_RESERVE_STABLECOIN_APPROVE_MIN_AGENT_AMOUNT: 10000,
  API_RESERVE_STABLECOIN_APPROVE_MIN_SUPER_AMOUNT: 30000,
  API_RESERVE_INTERNAL_APPROVE_MIN_AGENT_AMOUNT: 20000,
  RESERVE_REDEEM_PRIMETRUST_MIN_AMOUNT: 2000,
  API_PLAID_PUBLIC_KEY: '',
  API_BORROW_MATCHING_FEE_PERCENT: 1,
  USER_KYC_US_TRIAL_AMOUNT: 0,
  USER_KYC_US_TRIAL_PERIOD: 0,
  USER_MAX_INSTANT_CREDIT: 25000,
  API_BORROW_MAX_USD_LOAN: 50000,
  API_BORROW_MIN_USD_LOAN: 50,

  API_RESERVE_WIRE_TRANSFER_RULE_TIME: null,
  API_RESERVE_WIRE_TRANSFER_RULE_AMOUNT: 2000,
  API_RESERVE_WIRE_TRANSFER_REFUND_AMOUNT: 15,
  API_USER_NONUS_KYC_INQUIRY_ENABLED: false,

  API_COLLATERAL_SWAP_FEE_RATE: 0.1,
  API_COLLATERAL_SWAP_BUFFER_PRICE_RATE: 1,
  API_COLLATERAL_SWAP_MIN_USD_AMOUNT: 50,
  API_COLLATERAL_SWAP_MAX_USD_AMOUNT: 10000,
};

export const DAYS_PER_MONTH = 30;
export const PICKER_SHOW_MONTH = 3;
// export const MONTH_TERMS = [1, 2, 3, 6, 9];
export const DEFAULT_MIN_INVESTMENT_RATE = 6.0;
export const DEFAULT_INVESTMENT_RATE = 8.0;
// export const DEFAULT_INVESTMENT_BOND_RATE = 8.5;
// export const DEFAULT_BORROW_RATE = 6.0;
// export const DEFAULT_PRV_BORROW_RATE = 6.0;
export const DEFAULT_FLEX_RATE = 4.0;
// export const DEFAULT_CYPTO_LEND_RATE = 6.0;

// export const MIN_FLEX_BALANCE_TOPUP = 0.0001;
export const MIN_AMOUNT_RECALL_EXCESS = 0.0001;

export const MAX_SHORT_VALUE = 50000;
export const MIN_SHORT_VALUE = 50;

export const TERM_TYPE = {
  CRYPTO_BOND: 'crypto_bond',
  BANK_BOND: 'bank_bond',
  SECONDARY_MARKET: 'void',
  LOAN_ORIGINATOR: 'loan_originator',
  LO_SECONDARY_MARKET: 'lo_secondary_market'
};

export const INVESTMENT_TYPE = {
  INVESTMENT: 'investment',
  SECONDARY_MARKET: 'secondary_investment'
};

export const WITHDRAW_TYPE = {
  RESERVE: 'reserve',
  ORDER: 'order',
};

export const DEFAULT_SUMMARY_INVESTMENT = {
  [INVESTMENT_TYPE.INVESTMENT]:
    {
      pending: 0,
      matching: 0,
      matched: 0,
      done: 0,
      closed: 0,
    },
  [INVESTMENT_TYPE.SECONDARY_MARKET]:
    {
      voiding: 0,
      voided: 0,
      matched: 0,
      done: 0,
    },
};

export const ACCOUNT_HISTORY_TABS = {
  INVESTMENT: 'investment',
  LOAN: 'loan',
  LOAN_ORIGINATOR: 'loan_originator',
  SECONDARY_MARKET: 'secondary_investment',
  LOAN_SETTINGS: 'loan_settings',
  LO_SECONDARY_MARKET: 'lo_secondary_market',
  SPENDING: 'spending',
  SHORT_SELLING: 'short_selling',
  SHORT_SIMPLE: 'short_simple',
  LENDING_LOTTERY: 'lending_lottery',
};

export const PAYMENT_TYPE = {
  FIAT: 'fiat',
  CRYPTO: 'crypto',
  EMAIL: 'email',
  PRIMETRUST: 'primetrust',
  PLAID: 'plaid',
};

export const PAYMENT_METHOD_MAX_TRANSFER = {
  EMAIL: 3000,
  PLAID: 10000,
};

export const HEADER_HEIGHT = 66;
export const APP_PATH_HEIGHT = 85;

export const CRYPTO_CHAIN_NAME = {
  deposit_erc20: 'ERC20',
  deposit_incognito: 'INCOGNITO',
  deposit_primetrust: 'ERC20',
  deposit_trc20: 'TRC20',
};
