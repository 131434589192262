import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { URL } from 'src/resources/constants/url';
import { HtmlLang } from 'src/lang/components';
import { hideRequire2FaDialog } from './redux/action';
import DialogForm from './styled';

const getIntlKey = key => 'components.dialog.require2Fa.' + key;

const Require2FaDialog = (props) => {
  const { show, hideRequire2FaDialog, history } = props;
  const handleEnable = () => {
    hideRequire2FaDialog();
    history.push(URL.ME_SECURITIES_GOOGLE_AUTH);
    window.scroll({top: 0, behavior: 'smooth'});
  };

  return show && (
    <Modal
      show
      centered
      keyboard={false}
      backdrop="static"
    >
      <Modal.Body>
        <DialogForm>
          <p className="content">
            <HtmlLang id={getIntlKey('message')} />
          </p>
          <div className="actions">
            <Button variant="primary" onClick={handleEnable}>
              <HtmlLang id={getIntlKey('enable')} />
            </Button>
            <Button variant="secondary" onClick={hideRequire2FaDialog}>
              <HtmlLang id={getIntlKey('cancel')} />
            </Button>
          </div>
        </DialogForm>
      </Modal.Body>
    </Modal>
  );
};

const mapState = state => ({
  ...state.require2FaDialog,
});

const mapDispatch = {
  hideRequire2FaDialog,
};

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
)(Require2FaDialog);
