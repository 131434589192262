import { OPEN_DIALOG, HIDE_DIALOG } from './action';

const initialState = {
  show: false,
  message: '',
  callback: f => f,
};

export default (state = initialState, { type }) => {
  switch (type) {
    case OPEN_DIALOG: {
      return {
        show: true,
      };
    }
    case HIDE_DIALOG: {
      return {
        show: false,
      };
    }
    default: return state;
  }
};
