import moment from 'moment-timezone';
import { API_ROOT, URL } from 'src/resources/constants/url';
import BigNumber from 'bignumber.js';

const REGEX_URL = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const isKyc = verifyLevel => [6,7].includes(verifyLevel);

export const isNoNeedKyc = createdAt => moment(createdAt).isBefore(moment('2021/03/17', 'YYYY/MM/DD')) && moment().isBefore(moment('2021/04/01', 'YYYY/MM/DD'));

export const getNameFromEmail = email => email?.substr(0, email?.indexOf('@') || 0);

export const shortCryptoAddress = (address = '', toLength) => {
  if (toLength) {
    if (address.length <= toLength) return address;
    const x = Math.floor(toLength / 2);
    return `${address?.substr(0, x)}...${address?.substr(address?.length - x)}`;
  }
  return `${address?.substr(0, 8)}...${address?.substr(address?.length - 8)}`;
};

export const shortStringToLength = (str = '', toLength) => {
  if (toLength) {
    if (str.length <= toLength) return str;
    return `${str?.substr(0, toLength)}...`;
  }
  return str;
};

export const getRandomColor = () => {
  return Math.floor(Math.random() * 16777215).toString(16);
};

export const toPstMoment = date => {
  return moment(date).tz('America/Los_Angeles');
};

export const formatPstTime = date => {
  return moment(date).tz('America/Los_Angeles').format('YYYY-MM-DDTHH:00:00-08:00');
};

export const getLocalDateFromPst = (year, month, day) => {
  return moment().tz('America/Los_Angeles').set({ date: day, month, year }).local();
};

export const getLocalDateTimeFromPst = (year, month, day, hour, minute, second) => {
  return moment().tz('America/Los_Angeles').set({ date: day, month, year, hour, minute, second }).local();
};

export const getLocalHourFromPst = hour => {
  return moment().tz('America/Los_Angeles').set({ hour }).local().hour();
};

export const getReferralLink = (code, url = `${API_ROOT}${URL.USER_SIGN_UP}?r=`) => {
  if (__CLIENT__) {
    return `${url}${code}`;
  }
};

export const getSignUpUrl = (affiliate_type) => {
  switch (affiliate_type) {
    case 'borrow': {
      return `${API_ROOT}${URL.USER_SIGN_UP_BORROW}?r=`;
    }
    case 'cryptolend': {
      return `${API_ROOT}${URL.CRYPTO_LEND_TO_EARN}?v=c&r=`;
    }
    default: {
      return `${API_ROOT}${URL.USER_SIGN_UP}?r=`;
    }
  }
};

export const getAvatarName = name => {
  let words = '';
  if (name && name.split(' ').length > 0) {
    name.split(' ').length = 2;
    const arrName = name.split(' ');
    words = arrName[0].charAt(0);
    if (arrName[1]) {
      words += arrName[1].charAt(0);
    } else if (arrName[0].charAt(1)) {
      words += arrName[0].charAt(1);
    }
    words = words.toUpperCase();
  }
  return words;
};

export const checkIsUSIp = (state) => {
  if (!APP_ENV.isLive) {
    return state.app?.ipInfo?.country_code === 'US' || state.debug.isUSIp;
  }
  return state.app?.ipInfo?.country_code === 'US';
};

export const checkIsUSUser = (state) => {
  if (!state.auth?.profile?.taxCountry) return checkIsUSIp(state);
  return state.auth?.profile?.taxCountry === 'US';
};

export const checkIsVNUser = (state) => {
  if (!state.auth?.profile?.taxCountry) return state.app?.ipInfo?.country_code === 'VN';
  return state.auth?.profile?.taxCountry === 'VN';
};

export const checkIsInternalUser = (state) => {
  return state.auth?.profile?.accountType === 2;
};

export const stripHtml = (html) => {
  return html.replace(/<[^>]*>?/gm, '');
};

export const insertLinkElement = str => {
  return str.replace(new RegExp(REGEX_URL, 'g'), text => {
    return `<a target="_blank" rel="nofollow" href="${text}">${text}</a>`;
  });
};

export const getLinksInText = text => {
  return text.match(new RegExp(REGEX_URL, 'g'));
};

export const getTextTimeAgo = (date) => {
  if (!moment(date).isValid()) return '';
  const time  = moment(date);
  if (moment().diff(time, 'm') < 1) {
    return 'now';
  }
  if (moment().diff(time, 'h') < 1) {
    const diff = moment().diff(time, 'm');
    return `${diff}m`;
  }
  if (moment().diff(time, 'd') < 1) {
    const diff = moment().diff(time, 'h');
    return `${diff}h`;
  }
  if (moment().diff(time, 'w') < 1) {
    const diff = moment().diff(time, 'd');
    return `${diff}d`;
  }
  const diff = moment().diff(time, 'w');
  return `${diff}w`;
};

export const roundNumCollateral = (num, havePlus = true) => {
  return Math.floor(num / 10) * 10 + (havePlus ? '+' : '');
};

export const calculateInterestMembership = (interest, increaseRate) => {
  return new BigNumber(interest).plus(new BigNumber(interest).multipliedBy(increaseRate).dividedBy(100)).toNumber();
};

export const getLinkNearExplorer = (address, type = 'tx') => {
  const domain = APP_ENV.isLive ? `https://explorer.near.org` : `https://explorer.testnet.near.org`;
  const path = type === 'tx' ? 'transactions' : 'accounts';
  return `${domain}/${path}/${address}`;
}