import { URL } from 'src/resources/constants/url';

export const data = [
  // {
  //   url: URL.HOME,
  //   title: 'Home',
  //   action: {
  //     view: 'view'
  //   }
  // },
  {
    url: URL.USER_SIGN_IN,
    title: 'Login',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.USER_SIGN_UP,
    title: 'SignUp',
    action: {
      view: 'view',
    }
  },
  {
    url: URL.USER_FORGET_PASSWORD,
    title: 'ForgetPassword',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.USER_FORGET_PASSWORD_FINISH,
    title: 'ForgetPasswordFinish',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ACTIVATE_ACCOUNT,
    title: 'ActivateAccount',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ABOUT_US,
    title: 'AboutUs',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.HELP_AND_SUPPORT,
    title: 'CallUs',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_PROFILE,
    title: 'MeProfile',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_CHANGE_PASSWORD,
    title: 'MeChangePassword',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_MYINFORMATION,
    title: 'MeInformation',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_SECURITIES,
    title: 'MeSecurities',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_LOGIN_HISTORY,
    title: 'MeLoginHistory',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_SECURITIES_GOOGLE_AUTH,
    title: 'MeSecuritiesGoogleAuth',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_NOTIFICATION_SETTINGS,
    title: 'MeNotificationSetting',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_ACCOUNT_LEVEL,
    title: 'MeAccountLevel',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_HISTORY_OVERVIEW,
    title: 'MeHistoryOverview',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_HISTORY_DEPOSIT,
    title: 'MeHistoryDeposit',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_HISTORY_WITHDRAW,
    title: 'MeHistoryWithdraw',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_LOCAL_HISTORY,
    title: 'MeHistoryTransfer',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_BANK_INFO,
    title: 'MeBankInfo',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.REFERRAL,
    title: 'Referral',
    action: {
      view: 'view',
    }
  },
  {
    url: URL.TRANSFER,
    title: 'Transfer',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.TRANSFER_CRYPTO,
    title: 'TransferCrypto',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.CRYPTO_LOAN,
    title: 'Borrow',
    action: {
      view: 'view',
      borrow: 'initiate_borrow',
    }
  },
  {
    url: URL.ME_OPEN_ORDER,
    title: 'MeOpenOrder',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME_INTEREST_HISTORY,
    title: 'MeInterestHistory',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.LOAN_DISBURSEMENT_DETAIL,
    title: 'LoanDisbursement',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.LOAN_PAYMENT_DETAIL,
    title: 'LoanPayment',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.DEPOSIT,
    title: 'Deposit',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.DEPOSIT_FIAT,
    title: 'DepositFiat',
    action: {
      view: 'view',
    }
  },
  {
    url: URL.DEPOSIT_CRYPTO,
    title: 'DepositCrypto',
    action: {
      view: 'view',
    }
  },
  {
    url: URL.FLEX,
    title: 'Flex',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.FLEX_CRYPTO,
    title: 'CryptoLend',
    action: {
      view: 'view',
    }
  },
  {
    url: URL.DEPOSIT_CONFIRMATION,
    title: 'DepositConfirmation',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.UPDATE_PASSWORD,
    title: 'UpdatePassword',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.INVEST,
    title: 'Invest',
    action: {
      view: 'view',
      invest: 'initiate_invest',
    }
  },
  {
    url: URL.INVEST,
    title: 'Invest Require Deposit',
    action: {
      view: 'view',
      invest: 'invest_require_deposit',
    },
  },
  {
    url: URL.WITHDRAW,
    title: 'Withdraw',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.MEMBERSHIP,
    title: 'Membership',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.PRO_LENDING,
    title: 'ProLending',
    action: {
      view: 'view',
      proInvest: 'pro_invest',
      proBorrow: 'pro_borrow',
    }
  },
  {
    url: URL.INVEST_CONFIRMATION,
    title: 'Confirmation',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.FEE_AND_SERVICES_TIMES,
    title: 'Pricing',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.AFFILIATES,
    title: 'Affiliates',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.COLLATERAL_BALANCE,
    title: 'CollateralBalance',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.TESTIMONIALS,
    title: 'WhyPeopleLoveUs',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.AGREEMENTS,
    title: 'Agreements',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.EMAIL_CONFIRM_RESERVE,
    title: 'EmailConfirmReserve',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.EMAIL_CONFIRM_CRYPTO_WITHDRAWAL,
    title: 'EmailConfirmCryptoWithdrawal',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ABOUT_US,
    title: 'OurStory',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.NOTIFICATIONS,
    title: 'Notifications',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.GROW,
    title: 'Grow',
    action: {
      view: 'view'
    }
  },
  // {
  //   url: URL.LANDING_PAGES_TRIAL_BONUS,
  //   title: 'TrialBonus',
  //   action: {
  //     view: 'view'
  //   }
  // },
  // {
  //   url: URL.LANDING_PAGES_PROMOTION_GENERAL_3K,
  //   title: '3kPromoGeneral',
  //   action: {
  //     view: 'view'
  //   }
  // },
  {
    url: URL.LANDING_PAGES_PROMOTION_GENERAL_2K,
    title: '2kPromoGeneral',
    action: {
      view: 'view'
    }
  },
  // {
  //   url: URL.LANDING_PAGES_PROMOTION_GENERAL_2K_2,
  //   title: '2kPromoGeneral2',
  //   action: {
  //     view: 'view'
  //   }
  // },
  // {
  //   url: URL.LANDING_PAGES_PROMOTION_CRYPTO_BACKED_3K,
  //   title: '3kPromoCryptoBacked',
  //   action: {
  //     view: 'view'
  //   }
  // },
  // {
  //   url: URL.LANDING_PAGES_PROMOTION_FLEX_3K,
  //   title: '3kPromoFlex',
  //   action: {
  //     view: 'view'
  //   }
  // },
  // {
  //   url: URL.LANDING_PAGES_PROMOTION_GENERAL,
  //   title: 'PromoGeneral',
  //   action: {
  //     view: 'view'
  //   }
  // },
  // {
  //   url: URL.LANDING_PAGES_PROMOTION_FLEX,
  //   title: 'PromoFlex',
  //   action: {
  //     view: 'view'
  //   }
  // },
  {
    url: URL.HELP_AND_SUPPORT,
    title: 'HelpAndSupport',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.LO_AUTO_INVEST,
    title: 'LoanOrigininatorsAutoInvest',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.LO_HOW_IT_WORK,
    title: 'LoanOrigininatorsHowItWorks',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.LO_MANUAL_INVEST,
    title: 'LoanOrigininatorsManualInvest',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.LO_MANUAL_INVEST_CONFIRM,
    title: 'LoanOrigininatorsManualInvestConfirm',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.LO_CONSTANT_RATING,
    title: 'LoanOrigininatorsConstantRating',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.LO_LOAN_ORIGINATOR,
    title: 'LoanOrigininatorsLoanOriginator',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.LO_SECURITY_RISK,
    title: 'LoanOrigininatorsSecurityRisk',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.LO_ONLINE_SECURITY,
    title: 'LoanOrigininatorsOnlineSecurity',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.BADGES,
    title: 'Badges',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.WITHDRAW_FEE,
    title: 'WithdrawFees',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.GIFT_CARDS,
    title: 'Gift cards',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.CRYPTO_SHORT,
    title: 'Short selling',
    action: {
      view: 'view'
    }
  },

  //fix url
  {
    url: URL.ACCOUNTS,
    title: 'Accounts',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.ME,
    title: 'Me',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.LOAN_ORIGINATORS,
    title: 'LoanOrigininators',
    action: {
      view: 'view'
    }
  },
  {
    url: URL.THANK_YOU,
    title: 'SignUp',
    action: {
      view: 'complete'
    }
  },
];
