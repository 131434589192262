import axios from 'axios';
import Cookies from 'js-cookie';

import { APP, SYSTEM_CONFIGS, COOKIE_IP_INFO } from 'src/resources/constants/app';
import { API_URL, URL } from 'src/resources/constants/url';
import COUNTRIES from 'src/resources/constants/countries';
import localStore from 'src/services/localStore';
import makeRequest, { SERVICES } from 'src/redux/action';
import { FAIL_DEFAULT_LANGUAGE } from 'src/resources/constants/languages';
import { changeLang } from 'src/lang/action';
import IpInfo from 'src/models/IpInfo';
import { camelizeKeys } from 'src/utils/object';
import APP_ACTION, { CONTENT_VARIABLES } from './type';

export const updateModal = payload => ({
  type: APP_ACTION.UPDATE_MODAL,
  payload,
});

// Alert
export const showAlert = config => ({
  type: APP_ACTION.UPDATE_APP_STATE,
  payload: { configAlert: { isShow: true, ...config } },
});
export const hideAlert = config => ({
  type: APP_ACTION.UPDATE_APP_STATE,
  payload: { configAlert: { isShow: false, ...config } },
});

// confirm passcode:
export const newPasscode = config => ({
  type: APP_ACTION.SHOW_CONFIRM,
  payload: { isShow: true, type: 1, ...config },
});
export const updatePasscode = config => ({
  type: APP_ACTION.HIDE_CONFIRM,
  payload: { isShow: true, type: 3, ...config },
});
export const hidePasscode = config => ({
  type: APP_ACTION.HIDE_CONFIRM,
  payload: { isShow: false, type: 4, ...config },
});
// qrcode content
export const showQRCodeContent = config => ({
  type: APP_ACTION.SHOW_QRCODE_CONTENT,
  payload: { isShow: true, ...config },
});
export const hideQRCodeContent = config => ({
  type: APP_ACTION.HIDE_QRCODE_CONTENT,
  payload: { isShow: false, ...config },
});

// show require password
export const showRequirePassword = config => ({
  type: APP_ACTION.SHOW_REQUIRE_PASSWORD,
  payload: { isShow: true, ...config },
});
export const hideRequirePassword = config => ({
  type: APP_ACTION.HIDE_REQUIRE_PASSWORD,
  payload: { isShow: false, ...config },
});

// Loading
export const showLoading = config => ({
  type: APP_ACTION.LOADING,
  payload: { ...config },
});
export const hideLoading = () => ({ type: APP_ACTION.LOADED });

// Header
export const hideHeader = () => ({ type: APP_ACTION.HEADER_HIDE });
export const setHeaderTheme = (isLight) => ({type: APP_ACTION.HEADER_THEME, payload: { headerTheme: isLight }});

// Not Found
export const setNotFound = () => ({ type: APP_ACTION.NOT_FOUND_SET });
export const clearNotFound = () => ({ type: APP_ACTION.NOT_FOUND_REMOVE });

// IP
export const setIpInfo = ipInfo => {
  localStore.save(APP.IP_INFO, ipInfo);
  const isBannedIp = [COUNTRIES.US.code].indexOf(ipInfo.country_code) >= 0;
  const payload = {
    ipInfo,
    isBannedIp,
  };
  return {
    type: APP_ACTION.UPDATE_APP_STATE,
    payload,
  };
};

// App
// |-- languagex
export const setLanguage = (data, autoDetect = true) => ({
  type: APP_ACTION.SET_LANGUAGE,
  payload: data,
  autoDetect,
});
// |-- loading
export const setRootLoading = rootLoading => ({
  type: APP_ACTION.UPDATE_APP_STATE,
  payload: { rootLoading },
});

export const initApp = () => dispatch => {
  if (Cookies.get(COOKIE_IP_INFO)) {
    try {
      dispatch(setIpInfo(JSON.parse(Cookies.get(COOKIE_IP_INFO))));
      return;
    } catch (e) {
    }
  }
    axios
      .get(`${URL.IP_DOMAIN}?auth=${APP_ENV.ipfindKey}`)
      .then(res => {
        // const defaultLanguage = res.data?.languages[0];
        // dispatch(setDefaultLang(defaultLanguage));
        const ipInfo = IpInfo.ipFind(res.data);
        dispatch(setIpInfo(ipInfo));

        const now = new Date();
        now.setTime(now.getTime() + (3 * 24 * 60 * 60 * 1000)); // 3 days
        Cookies.set(COOKIE_IP_INFO, JSON.stringify(ipInfo), { expires: now });
      })
      .catch(e => {
        console.error(e);
        const ipInfo = IpInfo.ipFind({});
        dispatch(setIpInfo(ipInfo));
      });

  // $http({
  //   url: URL.IP_DOMAIN,
  //   params: { auth: APP_ENV.ipfindKey },
  // })
  //   .then(res => {
  //     const ipInfo = IpInfo.ipFind(res);
  //     dispatch(setIpInfo(ipInfo));
  //     continueAfterInitApp(language, ref, dispatch, res, getState);
  //   })
  //   .catch(e => {
  //     dispatch(setRootLoading(false));
  //     // continueAfterInitApp(language, ref, dispatch, {});
  //   });
};

// QR Code
export const openQrScanner = () => dispatch => {
  dispatch({
    type: APP_ACTION.SHOW_SCAN_QRCODE,
  });
};

export const closeQrScanner = () => dispatch => {
  dispatch({
    type: APP_ACTION.HIDE_SCAN_QRCODE,
  });
};

export const changeHistoryTab = tab => ({
  type: APP_ACTION.CHANGE_HISTORY_TAB,
  payload: tab
});

export const getSystemConfigs = () => dispatch => {
  const req = makeRequest(
    {
      url: `${API_URL.SYSTEM.GET_SYSTEM_CONFIGS}?keys=${Object.keys(SYSTEM_CONFIGS).join(',')}`,
      type: APP_ACTION.GET_SYSTEM_CONFIGS,
      withAuth: false,
    },
    dispatch
  );
  return req().then(res => res || {});
};

export const getSystemBadges = () => dispatch => {
  const req = makeRequest({
    url: `${API_URL.SYSTEM.GET_SYSTEM_BADGES}`,
    type: APP_ACTION.GET_SYSTEM_BADGES,
  }, dispatch);
  return req().then(res => {
    const mapBadges = {};
    camelizeKeys(res).forEach(item => {
      mapBadges[item.type] = item;
    });
    return mapBadges || {};
  });
};

export const getUnreadNotifications = () => makeRequest({
  url: `${API_URL.USER.GET_UNREAD_NOTIFICATIONS}`,
  type: APP_ACTION.GET_UNREAD_NOTIFICATIONS,
  normalize: res => camelizeKeys(res)
});

export const getKYCTrailAmount = () => dispatch => {
  const req = makeRequest(
    {
      url: `${API_URL.SYSTEM.GET_SYSTEM_CONFIG}?key=USER_KYC_US_TRIAL_AMOUNT`,
      type: APP_ACTION.USER_KYC_US_TRIAL_AMOUNT,
    },
    dispatch
  );

  return req().then(res => camelizeKeys(res) || []);
};

export const getSystemNotifications = (params = '') => makeRequest({
  url: `${API_URL.USER.USER_NOTIFICATIONS}${params}`,
  type: APP_ACTION.USER_NOTIFICATIONS,
  service: SERVICES.ROOT,
  normalize: res => camelizeKeys(res)
});

export const setNotificationRead = (params = '') => dispatch => {
  const req = makeRequest(
    {
      url: `${API_URL.USER.USER_NOTIFICATIONS}${params}`,
      type: APP_ACTION.SET_NOTIFICATION_READ,
      service: SERVICES.ROOT,
      method: 'PUT'
    },
    dispatch
  );

  return req().then(res => res || {});
};

export const clearAllNotifications = () => makeRequest({
  url: `${API_URL.USER.USER_NOTIFICATIONS}`,
  type: APP_ACTION.CLEAR_ALL_NOTIFICATIONS,
  service: SERVICES.ROOT,
  method: 'DELETE'
});

export const getNotificationDetail = (params) => makeRequest({
  url: `${API_URL.USER.GET_NOTIFICATION_DETAIL}`,
  type: APP_ACTION.GET_NOTIFICATION_DETAIL,
  params,
  normalize: res => camelizeKeys(res)
});

export const updateDebugMode = (isDebug) => ({
  type: APP_ACTION.UPDATE_DEBUG_MODE,
  payload: isDebug,
});

export const updateLockRedirectMode = (isLockRedirect) => ({
  type: APP_ACTION.UPDATE_LOCK_REDIRECT_MODE,
  payload: isLockRedirect,
});

export const getContentVariables = () => makeRequest({
  url: `${API_URL.SYSTEM.GET_CONTENT_VARIABLE}?keys=${Object.keys(CONTENT_VARIABLES).join(',')}`,
  type: APP_ACTION.GET_CONTENT_VARIABLE,
});
