const TAG = 'SCREENS/APP';

const APP_ACTION = {

  SHOW_CONFIRM: 'SHOW_CONFIRM',
  HIDE_CONFIRM: 'SHOW_CONFIRM',

  SHOW_SCAN_QRCODE: 'SHOW_SCAN_QRCODE',
  HIDE_SCAN_QRCODE: 'HIDE_SCAN_QRCODE',

  SHOW_QRCODE_CONTENT: 'SHOW_QRCODE_CONTENT',
  HIDE_QRCODE_CONTENT: 'HIDE_QRCODE_CONTENT',

  SHOW_REQUIRE_PASSWORD: 'SHOW_REQUIRE_PASSWORD',
  HIDE_REQUIRE_PASSWORD: 'HIDE_REQUIRE_PASSWORD',

  NETWORK_ERROR: 'NETWORK_ERROR',

  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_ROOT_LOADING: 'SET_ROOT_LOADING',

  CALLING: 'CALLING',
  CALLED: 'CALLED',

  LOADING: 'LOADING',
  LOADED: 'LOADED',

  ALERT: 'ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT',
  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',

  MODAL: 'MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  UPDATE_MODAL: 'UPDATE_MODAL',

  NOT_FOUND_SET: 'NOT_FOUND_SET',
  NOT_FOUND_REMOVE: 'NOT_FOUND_REMOVE',

  HEADER_TITLE_SET: 'HEADER_TITLE_SET',
  HEADER_BACK_SET: 'HEADER_BACK_SET',
  HEADER_BACK_CLICK: 'HEADER_BACK_CLICK',
  HEADER_RIGHT_SET: 'HEADER_RIGHT_SET',
  HEADER_RIGHT_REMOVE: 'HEADER_RIGHT_REMOVE',
  HEADER_LEFT_SET: 'HEADER_LEFT_SET',
  HEADER_LEFT_REMOVE: 'HEADER_LEFT_REMOVE',
  HEADER_HIDE: 'HEADER_HIDE',
  HEADER_SHOW: 'HEADER_SHOW',
  HEADER_THEME: 'HEADER_THEME',

  IP_INFO: 'IP_INFO',

  BAN_CASH: 'BAN_CASH',
  BAN_PREDICTION: 'BAN_PREDICTION',
  BAN_CHECKED: 'BAN_CHECKED',

  SET_FIREBASE_USER: 'SET_FIREBASE_USER',
  UPDATE_APP_STATE: 'UPDATE_APP_STATE',

  GET_SYSTEM_CONFIGS: 'GET_SYSTEM_CONFIGS',
  GET_SYSTEM_BADGES: 'GET_SYSTEM_BADGES',

  UPDATE_CURRENCY_RATE: 'UPDATE_CURRENCY_RATE',
  CONVERT_USD_TO_VND: 'CONVERT_USD_TO_VND',

  CHANGE_HISTORY_TAB: 'CHANGE_HISTORY_TAB',
  USER_NOTIFICATIONS: 'USER_NOTIFICATIONS',
  GET_UNREAD_NOTIFICATIONS: 'GET_UNREAD_NOTIFICATIONS',
  SET_NOTIFICATION_READ: 'SET_NOTIFICATION_READ',
  CLEAR_ALL_NOTIFICATIONS: 'CLEAR_ALL_NOTIFICATIONS',
  GET_NOTIFICATION_DETAIL: 'GET_NOTIFICATION_DETAIL',
  UPDATE_DEBUG_MODE: 'UPDATE_DEBUG_MODE',
  UPDATE_LOCK_REDIRECT_MODE: 'UPDATE_LOCK_REDIRECT_MODE',
  USER_KYC_US_TRIAL_AMOUNT: 'USER_KYC_US_TRIAL_AMOUNT',
  USER_MAX_INSTANT_CREDIT: 'USER_MAX_INSTANT_CREDIT',
  GET_CONTENT_VARIABLE: 'GET_CONTENT_VARIABLE'
};

export default APP_ACTION;

export const CONTENT_VARIABLES = {
  cryptos_as_collateral_number: 0,
  crypto_short_rate: 6,
  crypto_lend_rate: 4,
  crypto_lend_stablecoin_rate: 12.5,
  crypto_lend_prv_rate: 7,
  crypto_lend_stablecoin_prv_rate: 12.5,
  crypto_future_multiple_rate: 50,
  crypto_future_rate: 2,
  crypto_future_sl_rate: 2,
  crypto_future_tp_rate: 3,
  crypto_future_min_amount: 1,
  crypto_future_max_amount: 100,
  lending_lottery_amount_per_ticket: 0,
  lending_lottery_rate: 0,
  mct_airdrop_start_date: '',
  mct_airdrop_end_date: '',
  prize_wheel_time: '',
  mct_staking_max_rate: 12,
  min_term_rate: 6,
  max_term_rate: 8
};
