import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { HtmlLang } from 'src/lang/components';
import { hideAlertDialog } from './redux/action';
import AlertForm from './styled';

const AlertDialog = (props) => {
  const { show, languageKey, hideAlertDialog, values, callback } = props;
  const handleClick = () => {
    hideAlertDialog();

    callback && callback();
  };
  return show && (
    <Modal
      show
      centered
      keyboard={false}
      backdrop="static"
    >
      <Modal.Body>
        <AlertForm>
          <p className="content">
            <HtmlLang id={languageKey} values={values} />
          </p>
          <div className="actions">
            <Button variant="primary" onClick={handleClick}>
              OK
            </Button>
          </div>
        </AlertForm>
      </Modal.Body>
    </Modal>
  );
};

const mapState = state => ({
  ...state.alertDialog,
});

const mapDispatch = {
  hideAlertDialog,
};

export default connect(mapState, mapDispatch)(AlertDialog);
