import { APP } from 'src/resources/constants/app';
import LocalStore from 'src/services/localStore';
import { HIDE_TRANSLATE_BUTTON } from './type';

const initState = {
  isShow: LocalStore.get(APP.HIDE_TRANSLATE_BUTTON) === true ? false : true,
};
export default (state = initState, { type }) => {
  switch(type) {
    case HIDE_TRANSLATE_BUTTON:
      return {
        ...state,
        isShow: false,
      };
    default:
      return state;
  }
};