import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { URL } from 'src/resources/constants/url';
import primetrustStatus from 'src/utils/primetrustStatus';
import { formatMoneyByLocale } from 'src/utils/format/currency';
import { getKYCTrailAmount } from 'src/screens/app/redux/action';
import BrowserDetect from 'src/services/browser-detect';
import { getInfoMembership } from 'src/screens/memberShip/action';
import { getLinkedBanksDeposit } from 'src/screens/me/pages/BankInfo/action';
import { LabelLang } from 'src/lang/components';
import { checkIsUSIp } from 'src/utils/common';

import { formatNumber } from '../exchangeMethods/loan/utils';
import { resendEmail } from './actions';
import styles from './styles.scss';

const BLACK_LIST_URL = [
  URL.ME_PROFILE,
  URL.ACCOUNT_HISTORY,
  URL.USER_SIGN_IN,
  URL.USER_SIGN_UP,
  // URL.PERSONAL_BUDGET_DASHBOARD,
  // URL.PERSONAL_BUDGET_LANDING
];

class VerificationHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      clickedEmail: false,
      kYCTrialAmount: formatMoneyByLocale(0),
      banks: [],
      isDisplay: true,
    };
  }

  componentDidMount() {
    const { isAuth, getKYCTrailAmount, getLinkedBanksDeposit, history, location } = this.props;
    getKYCTrailAmount().then(data => this.setState({kYCTrialAmount: formatMoneyByLocale(formatNumber(data))}));
    if (isAuth) {
      getLinkedBanksDeposit().then(res => {
        this.setState({banks: res});
      });
    }
    const isDisplay = !BLACK_LIST_URL.some(url => location.pathname.includes(url));
    this.setState({ isDisplay: isDisplay || !BrowserDetect.isMobile });

    history.listen((location, action) => {
      const isDisplay = !BLACK_LIST_URL.some(url => location.pathname.includes(url));
      this.setState({ isDisplay: isDisplay || !BrowserDetect.isMobile });
    });
  }

  componentDidUpdate(prevProps) {
    const { isAuth, getLinkedBanksDeposit } = this.props;
    if (prevProps.isAuth !== isAuth && isAuth) {
      getLinkedBanksDeposit().then(res => {
        this.setState({banks: res});
      });
    }
  }

  onResendEmail = () => {
    this.props.resendEmail().then(res => {
      if (res) {
        this.setState({
          clickedEmail: true,
        });
      }
    });
  };

  onUpdateEmail = () => {
    this.props.history.push(URL.ME_MYINFORMATION);
  };

  renderUpdateEmail = () => {
    return (
      <div className={styles.verificationHeader}>
        <LabelLang id="me.emailVerification.pleaseUpdateEmail" />
        <span
          role="presentation"
          onClick={() => this.onUpdateEmail()}
          className={styles.link}
        >
          <LabelLang id="me.emailVerification.updateEmail" />
        </span>
      </div>
    );
  };

  renderEmailVerification = () => {
    return (
      <div className={styles.verificationHeader}>
        <LabelLang id="me.emailVerification.pleaseVerify" />
        <span
          role="presentation"
          onClick={() => this.onResendEmail()}
          className={this.state.clickedEmail ? styles.sent : styles.link}
        >
          {BrowserDetect.isMobile && <br />}
          {this.state.clickedEmail
            ? <LabelLang id="me.emailVerification.sent" />
            : <LabelLang id="me.emailVerification.resent" />
          }
        </span>
      </div>
    );
  };

  renderKYCVerification = () => {
    return (
      <div className={styles.verificationHeader}>
        <LabelLang id="me.kycVerification.statusUnverified" />
        <Link to={URL.ME_PROFILE} className={styles.link}>
          <span><LabelLang id="me.kycVerification.complete" /></span>
        </Link>
      </div>
    );
  };

  renderKYCVerificationUS = () => {
    const { kYCTrialAmount } = this.state;
    return (
      <div className={styles.verificationHeader}>
        <span>
          <LabelLang id="me.kycVerification.statusUnverifiedUS" />
          <span style={{color:'#FF9F00'}}>${kYCTrialAmount} bonus</span>
          <Link to={URL.ME_PROFILE} className={styles.link}>
            <span><LabelLang id="me.kycVerification.complete" /></span>
          </Link>
        </span>
      </div>
    );
  };

  renderKYCPendding = () => {
    return (
      <div className={styles.verificationHeader}>
        <LabelLang id="me.personalProfile.statusPendding" />
        <Link to={URL.ME_PROFILE} className={styles.link}>
          <span>
            <LabelLang id="me.kycVerification.complete" />
          </span>
        </Link>
      </div>
    );
  };

  renderKYCProcessing = () => {
    return (
      <div className={styles.verificationHeader}>
        <LabelLang id="me.personalProfile.statusProcessing" />
      </div>
    );
  }

  // renderReferFriends = () => {
  //   const { isUSIp, email } = this.props;
  //   const { referralReward, referralFlexReward } = this.state;
  //   const message = `Refer friends and get ${ isUSIp ? `a free \$${referralReward} bonus + ` : '' }${referralFlexReward}% of their Instant-access investment earnings.`;
  //   return (
  //     <PromotionHeaderStyled>
  //       <span>{message}</span>
  //       <Link
  //         to={URL.REFERRAL}
  //         onClick={() => {
  //           ga(events.category.referralClick, events.action.referralClickMiniBanner, email);
  //         }}
  //       > Refer now <i className="far fa-long-arrow-right" />
  //       </Link>
  //     </PromotionHeaderStyled>
  //   );
  // }

  renderLinkBanks = () => {
    return (
      <div className={styles.verificationHeader}>
        <span>
          <LabelLang id="me.kycVerification.statusLinkBanks" />
        </span>
        <Link to={URL.ME_PROFILE} className={styles.link}>
          <span>Link banks</span>
        </Link>
      </div>
    );
  }

  render() {
    const { email, isAuth, profile, isUSIp, verifiedLevel } = this.props;
    const { banks, isDisplay } = this.state;
    const { status } = primetrustStatus.getStatusInfo(verifiedLevel);

    if (!isDisplay) return null;
    const getProfile = !(Object.entries(profile).length === 0 && profile.constructor === Object);

    if (isAuth && getProfile && !email) {
      return this.renderUpdateEmail();
    }

    if (status === 'statusNeedVerifyEmail') {
      return this.renderEmailVerification();
    } else if (status === 'statusProcessing') {
      // return this.renderKYCProcessing();
    } else if (status === 'statusUnverified') {
      if(isUSIp) {
        return this.renderKYCVerificationUS();
      } else {
        return this.renderKYCVerification();
      }
    } else if (status === 'statusPendding') {
      // return this.renderKYCPendding();
    } else if (banks.length <= 0 && isUSIp) {
      if(isAuth) {
        return this.renderLinkBanks();
      }
    }
    return null;
  }
}

const mapState = state => {
  return {
    verifiedLevel: state.auth.profile?.verifiedLevel,
    primetrustContactID: state.auth.profile?.primetrustContactID,
    profile: state.auth.profile,
    email: state.auth.profile?.email,
    isAuth: !!state.auth?.token,
    isUSIp: checkIsUSIp(state),
    membershipLevel: state.auth.profile?.Membership || 0,
  };
};

const mapDispatch = {
  resendEmail,
  getKYCTrailAmount,
  getInfoMembership,
  getLinkedBanksDeposit
};

export default compose(
  connect(mapState, mapDispatch),
  withRouter,
)(VerificationHeader);
