import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FAIL_DEFAULT_LANGUAGE } from 'src/resources/constants/languages';

const HtmlLang = props => {
  const { id, values, intl, className } = props;
  const html = intl.formatMessage({ id }, values);
  return <div className={className} dangerouslySetInnerHTML={{__html: html }} />;
};

const mapState = state => ({
  lang: FAIL_DEFAULT_LANGUAGE
});

export default compose(
  injectIntl,
  connect(mapState, null)
)(HtmlLang);
