const initState = {
  loading: true,
  submitting: false,
  status: 1,
  items: [],
  item: null
};
export default (state = initState, { type, payload }) => {
  switch(type) {
    case 'UPDATE_STATE': {
      return {
        ...state,
        ...payload
      }
    }
    case 'RESET_STATE': {
      return initState;
    }
    default: return state;
  }
}