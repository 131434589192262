import makeRequest from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';
import browserDetect from 'src/services/browser-detect';
import { data } from './data';

export const USER_ACTIONS = {
  USER_SIGN_UP: {
    signUp: 'sign_up',
    signUpInvest: 'invest',
    signUpBorrow: 'borrow',
    signUpFacebook: 'sign_up_facebook',
    signUpTwitter: 'sign_up_twitter',
    signUpSocialFacebook: 'sign_up_social_facebook',
    signUpSocialGoogle: 'sign_up_social_google',
    signUpSocialFacebookNotNew: 'sign_up_social_facebook_not_new',
    signUpSocialGoogleNotNew: 'sign_up_social_google_not_new',
    signUpNotNew: 'sign_up_not_new',
    signUpComplete: 'sign_up_complete',
  },
  ME_PROFILE: {
    signUpFacebookUpdateEmail: 'sign_up_facebook_update_email',
    kycUpdateProfileInfo: 'kyc_update_profile_info',
    kycUpdateProfileInfoAddress: 'kyc_update_profile_info_address',
    kycUpdateProfileInfoBasic: 'kyc_update_profile_info_basic',
    kycAddLinkedBank: 'kyc_add_linked_bank',
    kycVerifyLinkedBank: 'kyc_verify_linked_bank',
    kycAddBank: 'kyc_add_bank',
    kycEndInfoInput: 'kyc_end_info_input',
    kycEndAddressInput: 'kyc_end_address_input',
    kycOcrSubmitProfile: 'kyc_ocr_submit_profile',
    kycOcrSubmitAddress: 'kyc_ocr_submit_address',
    kycOcrUploadImage: 'kyc_ocr_upload_image',
  },
  INVEST: {
    invest: 'initiate_invest',
    secondary_invest: 'secondary_invest',
    proInvest: 'pro_invest',
    invest_require_deposit: 'invest_require_deposit',
    depositFiat: 'initiate_deposit_fiat',
    failedDepositFiat: 'failed_deposit_fiat_invest',
  },
  BORROW: {
    borrow: 'initiate_borrow',
    proBorrow: 'pro_borrow',
  },
  CRYPTO_SHORT: {
    short_crypto: 'short_crypto',
    cancel_confirm_crypto_short: 'cancel_confirm_crypto_short',
    submit_confirm_crypto_short: 'submit_confirm_crypto_short',
  },
  CRYPTO_FUTURE: {
    order_crypto_future: 'order_crypto_future',
  },
  DEPOSIT: {
    deposit: 'initiate_deposit',
    depositCryptoLend: 'initiate_crypto_lend',
    depositFiat: 'initiate_deposit_fiat',
    depositCrypto: 'initiate_deposit_crypto',
    failedDepositFiat: 'failed_deposit_fiat',
  },
  REFERRAL: {
    referralInviteTemplate: 'invite_email_template',
    referralInvite: 'invite_email',
    referralFacebook: 'facebook',
    referralTwitter: 'twitter',
    referralGoogle: 'google',
    affiliateInvite: 'affiliate_invite_email',
  },
  GIFT_CARDS: {
    actionButNoPurchase: 'ActionButNoPurchase',
    visit: 'Visit',
  },
};

const getLogData = (action) => {
  const url = window.location.pathname;
  const d = data.find(d => url.includes(d.url)) ||
  {
    url: url,
    title: 'Home',
    action: {
      view: 'view'
    }
  };
  return {
    Category: d.title,
    Url: d.url,
    Action: action || d.action.view,
    Platform: browserDetect.isMobile ? 'mobile-web' : 'web',
  };
};

export const logUserActivity = (action, extendData) => makeRequest({
  type: 'LOG_USER_ACTIVITY',
  url: API_URL.ME.LOG_USER_ACTIVITY,
  method: 'POST',
  data: { ...getLogData(action), Data: extendData },
});
