import React from 'react';
import ContentLoader from 'react-content-loader';

const LoadingBadgeSkeleton = () => {
  const offsetX = 25;
  const offsetY = 0;
  const spaceBetween = 160;
  return (
    <ContentLoader
      speed={1}
      width={440}
      height={130}
      viewBox="0 0 440 130"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      {/* Left part */}
      <rect x={offsetX} y={offsetY} rx="8" ry="8" width="120" height="94" />
      {/* Right part */}
      <rect x={offsetX+spaceBetween} y={offsetY+22} rx="4" ry="4" width="140" height="12" />
      <rect x={offsetX+spaceBetween} y={offsetY+36} rx="4" ry="4" width="120" height="12" />
      <rect x={offsetX+spaceBetween} y={offsetY+61} rx="1" ry="1" width="120" height="3" />
      <rect x={offsetX+spaceBetween} y={offsetY+65} rx="1" ry="1" width="120" height="3" />
      <rect x={offsetX+spaceBetween} y={offsetY+69} rx="1" ry="1" width="120" height="3" />
      <rect x={offsetX+spaceBetween} y={offsetY+89} rx="1" ry="1" width="120" height="3" />
    </ContentLoader>
  );
};

export default LoadingBadgeSkeleton;