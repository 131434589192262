import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import rootReducer, { createReducer } from './reducer';

const isProd = APP_ENV.isProduction;
let clientStore;

function initStore() {
  console.log('Redux store was created!');

  let __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ =
    __CLIENT__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = isProd
    ? compose
    : __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middlewares = [
    reduxThunk,
  ];
  
  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
}

export default function configureStore() {
  let store;
  if (__CLIENT__) {
    // reuse store on client
    if (!clientStore) {
      clientStore = initStore();
    }
    store = clientStore;

    if (process.env.NODE_ENV !== 'production' && typeof(module) !== 'undefined' && module.hot) {
      module.hot.accept('./reducer', () => store.replaceReducer(rootReducer));
    }
  } else {
    // create new store on server
    store = initStore();
  }
  store.asyncReducers = {};
  store.injectReducer = (key, reducer) => {
    store.asyncReducers[key] = reducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };
  
  return store;
}
