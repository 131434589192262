import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { withRouter } from 'react-router-dom';
import smartlookClient from 'smartlook-client';
// import axios from 'axios';

import routes from 'src/routes';
import RenderRoutes from 'src/routes/renderRoutes';
import facebookChat from 'src/services/facebookChat';
// import dataDog from 'src/services/dataDog';
import Root from 'src/screens/app/components/App/Root';
import configureStore from 'src/redux/store';

import LocalStore from './services/localStore';
import browserDetect from './services/browser-detect';

import 'src/assets/styles/_app.scss';
import { APP } from './resources/constants/app';
import ga, { events } from 'src/services/ga';
import { URL } from './resources/constants/url';
// Sentry.init({
//   environment: APP_ENV.isLive ? 'production' : 'staging',
//   dsn: APP_ENV.SENTRY_DSN,
//   allowUrls: [API_ROOT],
//   denyUrls: [
//     /graph\.facebook\.com/i,
//     /connect\.facebook\.net\/en_US\/all\.js/i,
//     /extensions\//i,
//     /^chrome:\/\//i,
//     /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
//   ]
// });

const store = configureStore();

class App extends Component {
  componentDidMount() {
    // dataDog.init();
    setTimeout(() => {
      smartlookClient.init(APP_ENV.SMART_LOOK_KEY);
    }, 1000);
    setTimeout(() => {
      facebookChat.init();
    }, 3000);

    // axios.get('https://www.myconstant.com/blog-api/glossaries').then(response => {
    //   if (response.data?.length > 0) {
    //     const termMap = response.data.reduce((final, current) => ({...final, [current.keyword.toLowerCase()]: current.description}), {});
    //     LocalStore.save('glossary', JSON.stringify(termMap));
    //   }
    // });

    // hidden overload init loading
    const overlayEl = window.document.getElementById('init-overlay');
    overlayEl && overlayEl.remove();

    // Tracking bounce rate
    LocalStore.save(APP.VISIT_ONLY_1, Date.now());
    window.addEventListener('beforeunload', () => {
      const time = LocalStore.get(APP.VISIT_ONLY_1);
      if (time) {
        LocalStore.remove(APP.VISIT_ONLY_1);
        ga(events.category.tracking, events.action.tracking_bounce_rate, navigator.userAgent, (Date.now() - time) / 1000);
        if (this.props.location.pathname.includes(URL.USER_SIGN_UP)) {
          ga(events.category.tracking, events.action.tracking_bounce_rate_signup, navigator.userAgent, (Date.now() - time) / 1000);
        }
      }
    });
  }

  componentDidCatch(error, errorInfo) {
    console.log('componentDidCatch start',);
    console.log('error', error);
    console.log('errorInfo', errorInfo);
    console.log('componentDidCatch end',);
    if(error.name === 'ChunkLoadError') {
      window.location.reload();
    }
  }

  render() {
    const { staticContext } = this.props;
    if (__SERVER__) {
      browserDetect.setSsrIsMobile(!!staticContext?.isMobile);
    }

    return (
      <Provider store={store}>
        <Root>
          <RenderRoutes routes={routes} staticContext={staticContext} />
        </Root>
      </Provider>
    );
  }
}

export default withRouter(App);
