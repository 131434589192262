import { makeRequest, SERVICES } from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';
import { camelizeKeys } from 'src/utils/object';
import { HISTORY, INFO, REVIEW_HISTORY, POINTS_HISTORY } from './type';

export const getInfoMembership = () => makeRequest({
  url: API_URL.MEMBERSHIP.INFO,
  type: INFO,
  service: SERVICES.SAVING
});

export const getHistory = () => makeRequest({
  type: HISTORY,
  url: `${API_URL.MEMBERSHIP.HISTORY}`,
  service: SERVICES.SAVING
});

export const reviewMembershipHistory = (id, data) => makeRequest({
  type: REVIEW_HISTORY,
  url: API_URL.MEMBERSHIP.REVIEW_HISTORY.replace('{id}', id),
  service: SERVICES.SAVING,
  method: 'POST',
  data
});

export const getPointsHistory = (page, limit) => makeRequest({
  type: POINTS_HISTORY,
  url: `${API_URL.MEMBERSHIP.POINTS_HISTORY}?page=${page}&page_size=${limit}`,
  service: SERVICES.SAVING,
  normalize: res => camelizeKeys(res),
});