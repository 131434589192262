import { makeRequest } from 'src/redux/action';
import { APP } from 'src/resources/constants/app';
import { API_URL } from 'src/resources/constants/url';
import LocalStore from 'src/services/localStore';
import { camelizeKeys } from 'src/utils/object';
import { TOP_BANNER_TYPE } from './index';
import { GET_VIEWING_BANNER, SKIP_BANNER, CLICK_BANNER, HIDE_TOP_BANNER, SHOW_TOP_BANNER } from './type';

export const getViewingBanner = () => dispatch => {
  const req = makeRequest({
    type: GET_VIEWING_BANNER,
    url: API_URL.USER.GET_VIEWING_BANNER,
  }, dispatch);
  return req().then(res => camelizeKeys(res));
};

export const skipBanner = id => dispatch => {
  const req = makeRequest({
    type: SKIP_BANNER,
    url: `${API_URL.USER.SKIP_BANNER}?ID=${id}`,
    method: 'POST',
  }, dispatch);
  return req().then(res => camelizeKeys(res));
};

export const clickBanner = id => dispatch => {
  const req = makeRequest({
    type: CLICK_BANNER,
    url: `${API_URL.USER.CLICK_BANNER}?ID=${id}`,
    method: 'POST',
  }, dispatch);
  return req().then(res => camelizeKeys(res));
};

export const showTopBanner = bannerType => dispatch => {
  if (!LocalStore.get(APP.HIDE_TOP_BANNER)) {
    return dispatch({ type: SHOW_TOP_BANNER, bannerType: bannerType || TOP_BANNER_TYPE.SUGGEST });
  }
};

export const hideTopBanner = () => dispatch => {
  return dispatch({ type: HIDE_TOP_BANNER });
};