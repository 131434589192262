import React, { useEffect, useState } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { hideProgressDialog } from './redux/action';
import ProgressForm from './styled';
import BigNumber from 'bignumber.js';

const ProgressDialog = (props) => {
  const { show, message = 'Please waiting', hideProgressDialog, progress, timeOut, callback } = props;
  const [currentProgress, setCurrentProgress] = useState(progress);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    setCurrentProgress(progress);

    if(progress >= 100) {
      timer && clearInterval(timer);
      setTimeout(() => {
        hideProgressDialog();
        callback && callback();
      }, 500);
    }
  }, [progress]);

  useEffect(() => {
    if(timeOut) {
      let duration = 0;
      const durationOnePercent = timeOut / 100;
      const timer = setInterval(() => {
        duration += durationOnePercent;
        let p = new BigNumber(duration).dividedBy(timeOut).multipliedBy(100).toNumber();
        if (p >= 100) p = 100;
        setCurrentProgress(p);

        if(p >= 100) {
          timer && clearInterval(timer);
          setTimeout(() => {
            hideProgressDialog();
            callback && callback();
          }, 500);
        }
      }, durationOnePercent);
      setTimer(timer);
    }
  }, [timeOut]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);

  // background-color: rgba(0,0,0, 0.9);
  return show && (
    <Modal
      show
      centered
      keyboard={false}
      backdrop="static"
      backdropClassName="progressBackdrop"
    >
      <Modal.Body>
        <ProgressForm>
          <p className="content">
            {message}
          </p>
          <ProgressBar animated now={currentProgress} label={`${currentProgress}%`} />
        </ProgressForm>
      </Modal.Body>
    </Modal>
  );
};

const mapState = state => ({
  ...state.progressDialog,
});

const mapDispatch = {
  hideProgressDialog,
};

export default connect(mapState, mapDispatch)(ProgressDialog);
