export const VERIFIED_LEVEL = {
  UserVerifiedLevelNoEmail: 0,
  UserVerifiedLevelEmail: 1,
  UserVerifiedLevelSubmitted: 2,
  UserVerifiedLevelRejected: 3,
  UserVerifiedLevelVerifying: 4,
  UserVerifiedLevelPrimetrustRejected: 5,
  UserVerifiedLevelPrimetrust: 6,
  UserVerifiedLevelApprovedByConstant: 7
};
