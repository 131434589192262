import React from 'react';
import ContentLoader from 'react-content-loader';

import LoadingHomeSkeleton from './home';
import LoadingBadgeSkeleton from './badge';

const LoadingSkeleton = () => {
  const offsetX = 25;
  const offsetY = 30;
  const spaceBetween = 100;
  return (
    <ContentLoader 
      speed={1}
      width={500}
      height={220}
      viewBox="0 0 500 220"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      {/* Right part */}
      <circle cx={offsetX+184+spaceBetween} cy={offsetY+8} r="8" /> 
      <rect x={offsetX+143+spaceBetween} y={offsetY+26} rx="8" ry="8" width="162" height="122" /> 
      <rect x={offsetX+195+spaceBetween} y={offsetY+1} rx="4" ry="4" width="54" height="16" /> 
      <rect x={offsetX+251+spaceBetween} y={offsetY+1} rx="4" ry="4" width="54" height="16" /> 
      {/* Left part */}
      <rect x={offsetX} y={offsetY+27} rx="4" ry="4" width="140" height="16" /> 
      <rect x={offsetX} y={offsetY+45} rx="4" ry="4" width="140" height="16" /> 
      <rect x={offsetX} y={offsetY+63} rx="4" ry="4" width="120" height="16" /> 
      <rect x={offsetX} y={offsetY+94} rx="1" ry="1" width="120" height="3" /> 
      <rect x={offsetX} y={offsetY+99} rx="1" ry="1" width="120" height="3" /> 
      <rect x={offsetX} y={offsetY+104} rx="1" ry="1" width="120" height="3" /> 
      <rect x={offsetX} y={offsetY+123} rx="1" ry="1" width="120" height="3" /> 
      <rect x={offsetX} y={offsetY+118} rx="1" ry="1" width="120" height="3" /> 
      <rect x={offsetX} y={offsetY+128} rx="1" ry="1" width="45" height="3" /> 
      <rect x={offsetX+12} y={offsetY+154} rx="4" ry="4" width="80" height="16" /> 
      <circle cx={offsetX} cy={offsetY+162} r="8" />
    </ContentLoader>
  );
};

export default LoadingSkeleton;
export { LoadingHomeSkeleton,LoadingBadgeSkeleton };
