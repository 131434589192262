import Cookies from 'js-cookie';

class IpInfo {
  static ipInfo(data) {
    return {
      city: data?.city || '',
      region: data?.region || '',
      regionCode: data?.region_code || '',
      country: data?.country || '',
      countryName: data?.country_name || '',
      continentCode: data?.continent_code || '',
      inEu: data?.in_eu || '',
      postal: data?.postal || '',
      latitude: data?.latitude || '',
      longitude: data?.longitude || '',
      timezone: data?.timezone || '',
      utcOffset: data?.utc_offset || '',
      countryCallingCode: data?.country_calling_code || '',
      currency: data?.currency || '',
      languages: data?.languages || '',
      asn: data?.asn || '',
      org: data?.org || '',
      addressDefault: '',

    };
  }

  static ipFind(data={}) {
    return {
      ip_address: data?.ip_address || '',
      country: data?.country || 'United States',
      country_code: Cookies.get('set_country') === 'us' ? 'US' : data?.country_code || 'US',
      continent: data?.continent || 'America',
      continent_code: data?.continent_code || 'America',
      city: data?.city || 'New York',
      county: data?.county || '',
      region: data?.region || 'New York',
      region_code: data?.region_code || '',
      timezone: data?.timezone || '',
      owner: data?.owner || '',
      longitude: data?.longitude || '',
      latitude: data?.latitude || '',
      currency: data?.currency || 'USD',
      languages: data?.languages || [],
      is_default: data?.country_code === undefined
    };
  }
}

export default IpInfo;
