import styled from 'styled-components';

const DialogForm = styled.div`
  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  div.vertical {
    flex-direction: row;
    margin-bottom: 10px;
    > div {
      justify-content: center;
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-bottom: 0px;
      &:first-child label {
        margin-bottom: 5px;
        margin-top: 5px;
      }
    }
    input {
      height: 35px;
      font-size: 14px;
    }
  }
  > span {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid #cccccc24;
    margin-bottom: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 2px 2px #f1f1f18f;
    -moz-box-shadow: 0px 2px 2px 2px #f1f1f18f;
    box-shadow: 0px 2px 2px 2px #f1f1f18f;
    line-height: 28px;
    label {
      min-width: 40%;
      margin-bottom: 0px;
    }
  }
  > div {
  display: flex;
    label {
    margin-bottom: 10px;
    line-height: 23px;
    padding-bottom: 0px;
    }
    .switch-component {
      padding-left: 5px;
    }
  }
  button, button span {
    font-size: 14px;
  }
`;

export default DialogForm;