import { HIDE_LOADING_OVERLAY, SHOW_LOADING_OVERLAY } from './action';

const initialState = {
  show: false,
  callback: f=> f,
  message: '',
  timeOut: 0
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_LOADING_OVERLAY: {
      return {
        show: true,
        ...payload
      };
    }
    case HIDE_LOADING_OVERLAY: {
      return {
        show: false,
        ...payload
      };
    }
    default: return state;
  }
};
