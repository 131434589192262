import makeRequest, { SERVICES } from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';
import { camelizeKeys } from 'src/utils/object';

export const SAVE_DEPOSIT_ORDER_INFO = 'SAVE_DEPOSIT_ORDER_INFO';
export const GET_ESTIMATE_PLAID_SPLIT = 'GET_ESTIMATE_PLAID_SPLIT';
export const GET_REMAIN_ACH = 'GET_REMAIN_ACH';
export const GET_CREDIT_SCORE = 'GET_CREDIT_SCORE';
export const GET_PLAID_BANK_INFO = 'GET_PLAID_BANK_INFO';

export const saveDepositOrderInfo = order => ({
  type: SAVE_DEPOSIT_ORDER_INFO,
  data: order,
});

export const getEstimatePlaidSplit = (amount, term) => makeRequest({
  type: GET_ESTIMATE_PLAID_SPLIT,
  url: `${API_URL.EXCHANGE.PLAID_SPLIT}/?amount=${amount}&term=${term}`,
  service: SERVICES.EXCHANGE,
  normalize: res => camelizeKeys(res),
});

export const getRemainAch = () => makeRequest({
  type: GET_REMAIN_ACH,
  url: API_URL.DIRECT.GET_REMAIN_ACH,
});

export const getCreditScore = () => makeRequest({
  type: GET_CREDIT_SCORE,
  url: API_URL.DIRECT.GET_CREDIT_SCORE,
  normalize: res => camelizeKeys(res),
});

export const makeDepositFiatOrder = (data, captcha) => makeRequest({
  service: SERVICES.EXCHANGE,
  type: 'MAKE_DEPOSIT_FIAT',
  method: 'POST',
  url: `${API_URL.LOCAL.MAKE_BUY_ORDER}`,
  data,
  timeout: 60000,
  normalize: res => camelizeKeys(res),
  captcha,
});

export const getPlaidBankInfo = (bankId) => makeRequest({
  type: GET_PLAID_BANK_INFO,
  url: `${API_URL.EXCHANGE.GET_PLAID_BANK_INFO}/${bankId}`,
  service: SERVICES.ROOT
});
