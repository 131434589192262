import { combineReducers } from 'redux';
import {reducer as form} from 'redux-form';

import app from 'src/screens/app/redux/reducer';
import auth from 'src/screens/auth/redux/reducer';
import langReducer from 'src/lang/reducer';
import {modalReducer as modals} from 'src/components/modal';
// import InvestPro from 'src/screens/prolending/invest/reducer';
// import constantLoan from 'src/screens/prolending/reducer';
import securityDialog from 'src/screens/security-dialog/redux/reducer';
import twoFADialog from 'src/screens/me/pages/Security/TwoFactor/redux/reducer';
import smsAuthenticationReducer from 'src/screens/me/pages/Security/smsAuthentication/redux/reducer';
import {alertDialogReducer as alertDialog} from 'src/components/alertDialog';
import {progressDialogReducer as progressDialog} from 'src/components/progressDialog';
import {require2FaDialogReducer as require2FaDialog} from 'src/components/require2FaDialog';
import confirmDialog from 'src/components/confirmDialog/reducer';
// import LOLoanFilterForm from 'src/screens/loanOriginators/manual/filter/redux/reducer';
import topBannerReducer from 'src/components/topBanner/reducer';
import referralReducer from 'src/screens/referral/reducer';
import depositReducer from 'src/screens/depositFiat/reducer';
import supportButtonReducer from 'src/components/contactSupport/reducer';
import translateButtonReducer from 'src/components/translateSupport/reducer';
import loadingOverlayReducer from 'src/components/loadingOverlay/redux/reducer';
import registerReducer from 'src/screens/register/reducer';
import accountReducer from 'src/screens/yourAccounts/redux/reducer';
import commonList from './common/list/reducer';
import debug from './debug/reducer';
import forumChat from 'src/screens/forumChat/reducer';

const reducers = {
  app,
  auth,
  langReducer,
  form: form.plugin({ /*constantLoan, InvestPro,*/ /* LOLoanFilterForm */ }),
  modals,
  commonList,
  securityDialog,
  twoFADialog,
  smsAuthenticationReducer,
  confirmDialog,
  alertDialog,
  progressDialog,
  require2FaDialog,
  topBanner: topBannerReducer,
  supportButton: supportButtonReducer,
  tranlsateButton: translateButtonReducer,
  referral: referralReducer,
  deposit: depositReducer,
  register: registerReducer,
  account: accountReducer,
  loadingOverlayReducer,
  debug,
  forumChat
};


export const createReducer = (asyncReducers = {}) => combineReducers({
  ...reducers,
  ...asyncReducers,
});

export default combineReducers(reducers);
