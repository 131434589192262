import styled from 'styled-components';

const ProgressForm = styled.div`
  .progress {
    height: 10px;
  }
  
  .title {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    text-align: center;
  }
  
  .content {
    font-size: 13px !important;
    text-align: left !important;
  }
`;

export default ProgressForm;