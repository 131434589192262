import React from 'react';
import { Link } from 'react-router-dom';

import { URL } from 'src/resources/constants/url';
import BrowserDetect from 'src/services/browser-detect';

import Bonus2Percent from './images/bonus_2_percent.png';
import styles from './styles.scss';
import browserDetect from 'src/services/browser-detect';

const BannerAllTerm = (props) => {
  const { onClose } = props;

  const handleShare = () => {
    if(browserDetect.isMobile) {
      window.scroll({top: 1000, behavior: 'smooth'});
    } else {
      window.scroll({top: 500, behavior: 'smooth'});
    }
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.content}>
        <div className={styles.label}>
          <div>MyConstant is unable to continue to operate as usual. As such, we're pausing customer withdrawals as allowed under our Terms of Service. No deposit or investment request will be processed at this time.</div>
          <div>We understand this will be a worrying time. For further questions, please feel free to <a href="mailto:hello@myconstant.com" className={"bold"}>contact our team</a> or keep an eye on our updates on <a href="/announcement" className={"bold"}>this page</a>.</div>
        </div>
        {/* <button className={styles.link} to={`${URL.MCT_PRIZE_WHEEL}`} onClick={handleShare}>
          {BrowserDetect.isMobile ? 'Now!' : <>Share now! <i className="far fa-long-arrow-right" /></>}
        </button> */}
      </div>
      {/* <button className={styles.closeButton} type="button" onClick={onClose}>{!BrowserDetect.isMobile && 'Close'} <i className="fal fa-times" /></button> */}
    </div>
  );
};

export default BannerAllTerm;
