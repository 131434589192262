import React from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import Cookies from 'js-cookie';
import loadable from '@loadable/component';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone/moment-timezone';
import {URL} from 'src/resources/constants/url';

import {
  getContentVariables,
  getSystemBadges,
  getSystemConfigs,
  initApp,
  updateDebugMode,
  updateLockRedirectMode,
} from 'src/screens/app/redux/action';
import {
  checkUserTrialAvaiable,
  getMCTLoyaltyProgramInfo,
  getProfileAction,
  getUserAttributes,
  getUserLoyaltyBenefits,
  setToken,
} from 'src/screens/auth/redux/action';
import currentUser from 'src/utils/authentication';
import { ModalManager } from 'src/components/modal';
import Layout from 'src/screens/app/components/Layout';
import {
  APP,
  COOKIE_BLACKFRIDAY_SALE,
  COOKIE_CLICK_ID, COOKIE_NEWYEAR_SALE,
  COOKIE_QUERY_STRING,
  COOKIE_REFERRAL,
  COOKIE_SRC,
} from 'src/resources/constants/app';
import TopBanner from 'src/components/topBanner';
import LocalStore from 'src/services/localStore';
import { API_ROOT } from 'src/resources/constants/url';
import { getAllFlexCollaterals } from 'src/screens/cryptoLend/action';

import ScrollToTop from './ScrollToTop';

const LoadableBarcodeScanner = loadable(() => import('src/components/barcodeScanner'));
const IntlCustomProvider = loadable(() => import('src/lang'));

class Root extends React.Component {
  componentDidMount() {
    this.checkNewBuild();
    const querystring = window.location.search.replace('?', '');
    const querystringParsed = qs.parse(querystring);
    const { r: ref, src, open_url} = querystringParsed;
    const { cjevent, pid='', PID='', Pid='', aid='', AID='', Aid='', sid='', SID='', Sid=''} = querystringParsed;
    const { partner='', affiliate_id = '', click_id='' } = querystringParsed;
    const { happynewyear} = querystringParsed;

    if(open_url) {
      LocalStore.save(APP.OPEN_URL, open_url);
    }

    const { history } = this.props;
    if(LocalStore.get(APP.OPEN_URL) !== 'true') {
      history.replace(URL.ANNOUNCEMENT);
    }

    if(querystring) {
      const now = new Date();
      now.setTime(now.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));
      Cookies.set(COOKIE_QUERY_STRING, querystring, { expires: now });
      LocalStore.save(COOKIE_QUERY_STRING, querystring);
    }

    if (ref) {
      const now = new Date();
      now.setTime(now.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));
      Cookies.set(COOKIE_REFERRAL, ref, { expires: now });
      LocalStore.save(COOKIE_REFERRAL, ref);
      Cookies.set(COOKIE_SRC, src, { expires: now });
    }

    if(cjevent) {
      const now = new Date();
      now.setTime(now.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));
      Cookies.set(COOKIE_CLICK_ID, `cj__${cjevent}_${pid || PID || Pid}_${aid || AID || Aid}_${sid || SID || Sid}`, { expires: now });
    }

    if(partner) {
      const now = new Date();
      now.setTime(now.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));
      Cookies.set(COOKIE_CLICK_ID, `${partner}___${affiliate_id}___${click_id}`, { expires: now });
    }

    if(querystring.includes('isDebug')) {
      this.props.updateDebugMode(true);
    } else {
      this.props.updateDebugMode(false);
    }

    if(querystring.includes('isLockRedirect')) {
      this.props.updateLockRedirectMode(true);
    } else {
      this.props.updateLockRedirectMode(false);
    }

    Cookies.remove(COOKIE_BLACKFRIDAY_SALE);
    LocalStore.remove(COOKIE_BLACKFRIDAY_SALE);

    if(happynewyear && moment(happynewyear, 'DD.MM.YYYY').isAfter(moment())) {
      const now = new Date();
      now.setTime(now.getTime() + (30 * 24 * 60 * 60 * 1000));
      Cookies.set(COOKIE_NEWYEAR_SALE, happynewyear, { expires: now });
      LocalStore.save(COOKIE_NEWYEAR_SALE, happynewyear);
    }

    const { getProfileAction, setToken, getSystemConfigs, getSystemBadges, getContentVariables, getAllFlexCollaterals, getMCTLoyaltyProgramInfo } = this.props;
    Promise.any([
      getSystemConfigs(),
      getSystemBadges(),
      getContentVariables(),
      getAllFlexCollaterals('flexEnabled=true'),
      getMCTLoyaltyProgramInfo()
    ]);

    if (currentUser.isLogin()) {
      // restored saved token
      setToken(currentUser.getToken());
      getProfileAction().then(res => {
        setToken(currentUser.getToken());
      });
    }

    this.props.initApp();
  }

  checkBuildVersionAndRefresh = () => {
    axios.get(`${API_ROOT}/version`).then(res => {
      const version = res?.data;
      const oldVersion = LocalStore.get(APP.BUILD_VERSION);

      if(version !== oldVersion) {
        LocalStore.save(APP.BUILD_VERSION, version);
        if (oldVersion) {
          window.location.reload();
        }
      }
    });
  }

  checkNewBuild = async () => {
    this.checkBuildVersionAndRefresh();

    setInterval(async () => {
      this.checkBuildVersionAndRefresh();
    }, 30 * 60 * 1000);

    const { history } = this.props;

    history.listen(async (location, action) => {
      if(LocalStore.get(APP.OPEN_URL) !== 'true') {
        history.replace(URL.ANNOUNCEMENT);
      }
      if(this.props.location.pathname !== location.pathname && action === 'PUSH') {
        this.checkBuildVersionAndRefresh();
      }
    });
  }


  componentDidUpdate(prevProps) {
    const { isAuth, getUserAttributes, getUserLoyaltyBenefits } = this.props;

    if (isAuth && prevProps.isAuth !== isAuth) {
      getUserAttributes();
      getUserLoyaltyBenefits();
    }
  }

  render() {
    const { children, isAuth, topBannerType, location, ...props } = this.props;

    return (
      <IntlCustomProvider>
        <Layout {...props}>
          <TopBanner />
          <ScrollToTop>{children}</ScrollToTop>
          <LoadableBarcodeScanner />
          <ModalManager />
        </Layout>
      </IntlCustomProvider>
    );
  }
}

const mapState = state => ({
  app: state.app,
  ipInfo: state.app.ipInfo || {},
  router: state.router,
  userToken: state?.auth?.token,
  isAuth: !!state?.auth?.token,
  topBannerType: state.topBanner.type,
});

const mapDispatch = {
  initApp,
  getProfileAction,
  setToken,
  checkUserTrialAvaiable,
  getSystemConfigs,
  getSystemBadges,
  updateDebugMode,
  updateLockRedirectMode,
  getContentVariables,
  getAllFlexCollaterals,
  getUserAttributes,
  getUserLoyaltyBenefits,
  getMCTLoyaltyProgramInfo
};

export default withRouter(connect(mapState, mapDispatch)(Root));
