import MobileDetect from 'mobile-detect';

let instance = null;
let ssr_isMobile = false;

class BrowserDetect {
  constructor() {
    if (!instance) {
      if (__CLIENT__) {
        this.BrowserDetect = new MobileDetect(window.navigator.userAgent);
      }
      instance = this;
    }
    return instance;
  }

  setSsrIsMobile(check) {
    ssr_isMobile = check;
  }

  /**
   * check is mobile
   *
   * @readonly
   * @memberof BrowserDetect
   */
  get isMobile() {
    if (__SERVER__) {
      return ssr_isMobile;
    }
    return !!this.BrowserDetect?.phone();
  }

  get isIphone() {
    return this.BrowserDetect?.is('iphone');
  }

  get isChrome() {
    return this.BrowserDetect?.is('Chrome');
  }

  /**
   * check is tablet
   *
   * @readonly
   * @memberof BrowserDetect
   */
  get isTablet() {
    return !!this.BrowserDetect?.tablet();
  }

  /**
   * check is desktop
   *
   * @readonly
   * @memberof BrowserDetect
   */
  get isDesktop() {
    if (__SERVER__) {
      return !ssr_isMobile;
    }
    return (!this.isMobile && !this.isTablet);
  }

  // eslint-disable-next-line
  get isBot() {
    return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
  }

  get os() {
    return this.BrowserDetect.os();
  }
}
export default new BrowserDetect();
