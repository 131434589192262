import React from 'react';
import httpCore from 'src/utils/http';
import httpRoot from 'src/utils/http/httpRoot';
import httpRootLive from 'src/utils/http/httpRootLive';
import httpExchange from 'src/utils/http/httpExchange';
import httpSaving from 'src/utils/http/httpSaving';
import httpLoanOriginator from 'src/utils/http/httpLoanOriginator';
import httpDownload from 'src/utils/http/httpDownload';
import configureStore from 'src/redux/store';
import {URL} from 'src/resources/constants/url';
import {Link} from 'react-router-dom';
import {showAlert} from 'src/screens/app/redux/action';
import LabelLang from 'src/lang/components/LabelLang';
import {SESSION_EXPIRED} from 'src/screens/auth/redux/type';
import {logRuntimeError} from 'src/screens/auth/redux/api';
import {ERROR_CODE_OTP} from 'src/resources/constants/app';
import LocalStore from 'src/services/localStore';
import currentUser from 'src/utils/authentication';

const store = configureStore();

const DISPATCH_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  CALLING: 'CALLING',
};

export const SERVICES = {
  ROOT: 'ROOT',
  EXCHANGE: 'EXCHANGE',
  SAVING: 'SAVING',
  CORE: 'CORE',
  DOWNLOAD: 'DOWNLOAD',
  LOAN_ORIGINATOR: 'LOAN_ORIGINATOR',
  ROOT_LIVE: 'ROOT_LIVE',
};

const makeAction = ({ type, data, more, dispatchType }) => {
  const _type = dispatchType ? `${type}_${dispatchType}` : type;
  return {
    type: _type,
    data,
    more,
  };
};

export const makeRequest = (config = {}, _dispatch) => {
  const {
    type,
    url,
    //baseURL,
    method,
    data,
    onSuccess,
    onError,
    onFinal,
    params = [],
    withAuth = true,
    more = {},
    service = SERVICES.CORE,
    normalize,
    headers = {},
    otp,
    otpToken,
    otpPhone,
    auth,
    captcha,
    captchaV2,
    timeout,
    tokenId
  } = config;
  const METHOD = method ? String(method).toLowerCase() : 'get';
  let http = httpCore;
  const state = store.getState();
  const userToken = state?.auth?.token || currentUser.getToken();
  const isAuth = !!userToken;

  if (service === SERVICES.EXCHANGE) {
    http = httpExchange;
  } else if (service === SERVICES.CORE) {
    http = httpCore;
  } else if (service === SERVICES.SAVING) {
    http = httpSaving;
  } else if (service === SERVICES.LOAN_ORIGINATOR) {
    http = httpLoanOriginator;
  } else if (service === SERVICES.DOWNLOAD) {
    http = httpDownload;
  } else if (service === SERVICES.ROOT_LIVE) {
    http = httpRootLive;
  } else if (service === SERVICES.ROOT) {
    http = httpRoot;
  }

  return async d => {
    let dispatch = d;
    if (typeof d !== 'function') {
      dispatch = _dispatch || store.dispatch;
    }

    if (typeof dispatch !== 'function') {
      throw new Error('Redux makeRequest func required dispatch!');
    }
    dispatch(
      makeAction({
        type,
        dispatchType: DISPATCH_TYPE.CALLING,
        more,
        data: { payload: data, url, method: METHOD },
      }),
    );
    try {
      if (!APP_ENV.isProduction) {
        params['noneCacheUrl'] = new Date().getTime();
      }
      const options = {
        url,
        //baseURL,
        method: METHOD,
        data,
        params,
      };

      options.headers = {...headers};
      if (isAuth && withAuth) {
        options.headers.Authorization = 'Bearer ' + userToken;
      }

      if (auth) {
        options.headers.Authorization = 'Bearer ' + auth;
      }

      if (otp) {
        options.headers.OTP = otp;
      }

      if (otpToken) {
        options.headers.OTPToken = otpToken;
      }

      if (otpPhone) {
        options.headers.OTPPhone = otpPhone;
      }

      if (captcha) {
        options.headers.recaptcha = captcha;
      }

      if (captchaV2) {
        options.headers.recaptchav2 = captchaV2;
      }

      if(tokenId) {
        options.headers['x-token'] = tokenId;
      }

      if(timeout) {
        options.timeout = timeout;
      }

      const _res = await http(options);
      const res = typeof normalize === 'function' ? normalize(_res) : _res;

      if (_res) {
        dispatch(
          makeAction({
            type,
            data: res,
            more,
            dispatchType: DISPATCH_TYPE.SUCCESS,
          }),
        );
        if (typeof onSuccess === 'function') {
          console.warn('Should use Promise instead of callback!');
          onSuccess(res);
        }
        if (typeof onFinal === 'function') {
          console.warn('Should use Promise instead of callback!');
          onFinal(res);
        }
      }
      return Promise.resolve(res);
    } catch (e) {
      e.url = url;
      logRuntimeError(`url: ${url};error: ${e.message}`);
      // handler session expired, currently just show a notice
      if (e?.sessionExpired && e?.code !== ERROR_CODE_OTP && !onError) {
        dispatch({ type: SESSION_EXPIRED });
        dispatch(
          showAlert({
            message: <LabelLang id="error.expiredSession" />,
            type: 'info',
            timeOut: 1000,
            callBack: () => {
              window.location.reload();
            }
          }),
        );
      }

      dispatch(
        makeAction({ type, data: e, more, dispatchType: DISPATCH_TYPE.ERROR }),
      );
      if (typeof onError === 'function') {
        console.warn('Should use Promise instead of callback!');
        onError(e);
      }
      if (typeof onFinal === 'function') {
        console.warn('Should use Promise instead of callback!');
        onFinal(e);
      }
      return Promise.reject(e);
    }
  };
};

export default makeRequest;
